import React, { useState, useEffect } from 'react'
import { doc, updateDoc, getDoc } from 'firebase/firestore'
import { db } from '../firebaseConfig'
import { useUser } from '../context/UserContext'
import { collection, query, where, getDocs } from 'firebase/firestore'

import './style/EditCatalog.css'

const EditCatalog = ({ catalogId }) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [creator, setCreator] = useState('')
  const [price, setPrice] = useState(0)
  const [creatorUserName, setCreatorUsername] = useState('')
  const [isCreator, setIsCreator] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isPublic, setIsPublic] = useState(false)
  const [admins, setAdmins] = useState([])
  const [adminEmails, setAdminEmails] = useState([])
  const [newAdminEmail, setNewAdminEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(null) // New state to check if email exists

  const [changesMade, setChangesMade] = useState(false)
  const { user } = useUser()

  // Initial values to check against
  const [initialValues, setInitialValues] = useState({})

  useEffect(() => {
    const fetchCatalogData = async () => {
      try {
        const catalogRef = doc(db, 'questions', catalogId)
        const catalogSnapshot = await getDoc(catalogRef)

        if (catalogSnapshot.exists()) {
          const catalog = catalogSnapshot.data()
          setName(catalog.name)
          setDescription(catalog.description)
          setIsPublic(catalog.public)
          setAdmins(catalog.admin || [])
          setCreator(catalog.creator)
          setPrice(catalog.price)
          setInitialValues({
            name: catalog.name,
            description: catalog.description,
            public: catalog.public,
            price: price
          })
          if (catalog.creator === user.uid) {
            setIsCreator(true)
          }
        } else {
          alert('Katalog nicht gefunden.')
        }
      } catch (error) {
        console.error('Error fetching catalog data:', error)
        alert('Fehler beim Laden des Katalogs.')
      }
    }

    fetchCatalogData()
  }, [catalogId, user.uid, price])

  useEffect(() => {
    const fetchCreatorUsername = async () => {
      if (creator) {
        try {
          const creatorRef = doc(db, 'users', creator)
          const creatorDoc = await getDoc(creatorRef)

          if (creatorDoc.exists()) {
            const userData = creatorDoc.data()
            setCreatorUsername(userData.username || 'Unbekannter Benutzer') // Fallback if username is not defined
          } else {
            setCreatorUsername('Unbekannter Benutzer')
          }
        } catch (error) {
          console.error('Fehler beim Laden des Erstellernamens:', error)
          setCreatorUsername('Fehler beim Laden')
        }
      }
    }

    fetchCreatorUsername()
  }, [creator])

  useEffect(() => {
    const fetchAdminEmails = async () => {
      try {
        const emails = await Promise.all(
          admins.map(async adminId => {
            const adminRef = doc(db, 'users', adminId)
            const adminDoc = await getDoc(adminRef)
            return adminDoc.exists() ? adminDoc.data().email : null
          })
        )
        setAdminEmails(emails)
      } catch (error) {
        console.error('Error fetching admin emails:', error.message)
        alert(`Fehler beim Laden der Admin-E-Mails: ${error.message}`)
      }
    }

    if (admins.length > 0) {
      fetchAdminEmails()
    }
  }, [admins])

  useEffect(() => {
    const checkEmailExists = async () => {
      if (newAdminEmail) {
        const usersRef = collection(db, 'users')
        const q = query(usersRef, where('email', '==', newAdminEmail))
        const querySnapshot = await getDocs(q)
        setIsEmailValid(!querySnapshot.empty)
      } else {
        setIsEmailValid(null) // Reset if the field is empty
      }
    }

    checkEmailExists()
  }, [newAdminEmail])

  // Check if changes were made by comparing current values to initial values
  const handleChange = (field, value) => {
    let hasChanged = false

    switch (field) {
      case 'name':
        setName(value)
        hasChanged = value !== initialValues.name
        break
      case 'description':
        setDescription(value)
        hasChanged = value !== initialValues.description
        break
      case 'public':
        setIsPublic(value)
        hasChanged = value !== initialValues.public
        break
      case 'price':
        setPrice(value)
        hasChanged = value !== initialValues.price
        break
      default:
        break
    }

    setChangesMade(
      hasChanged ||
        name !== initialValues.name ||
        description !== initialValues.description ||
        isPublic !== initialValues.public
    )
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    try {
      const catalogRef = doc(db, 'questions', catalogId)
      await updateDoc(catalogRef, {
        name,
        description,
        public: isPublic,
        admin: admins,
        price: price
      })
      alert('Katalog erfolgreich aktualisiert!')
      setChangesMade(false)
    } catch (error) {
      console.error('Error updating catalog:', error.message)
      alert(`Fehler beim Aktualisieren des Katalogs: ${error.message}`)
    }

    setLoading(false)
  }

  const handleDeleteAdmin = async adminToDelete => {
    const updatedAdmins = admins.filter(admin => admin !== adminToDelete)
    setAdmins(updatedAdmins)
    setChangesMade(true)

    try {
      const catalogRef = doc(db, 'questions', catalogId)
      await updateDoc(catalogRef, {
        admin: updatedAdmins
      })
      alert('Admin erfolgreich entfernt!')
    } catch (error) {
      console.error('Error removing admin:', error.message)
      alert(`Fehler beim Entfernen des Admins: ${error.message}`)
    }
  }

  const handleAddAdmin = async () => {
    if (!newAdminEmail) {
      alert('Bitte eine E-Mail eingeben.')
      return
    }

    try {
      const usersRef = collection(db, 'users')
      const q = query(usersRef, where('email', '==', newAdminEmail))
      const querySnapshot = await getDocs(q)

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0]
        const userId = userDoc.id

        if (!admins.includes(userId)) {
          const updatedAdmins = [...admins, userId]
          setAdmins(updatedAdmins)
          setNewAdminEmail('')
          setChangesMade(true)

          try {
            const catalogRef = doc(db, 'questions', catalogId)
            await updateDoc(catalogRef, {
              admin: updatedAdmins
            })
            alert('Admin erfolgreich hinzugefügt!')
          } catch (error) {
            console.error('Error adding admin:', error.message)
            alert(`Fehler beim Hinzufügen des Admins: ${error.message}`)
          }
        } else {
          alert('Dieser Benutzer ist bereits ein Admin.')
        }
      } else {
        alert('Kein Benutzer mit dieser E-Mail gefunden.')
      }
    } catch (error) {
      console.error('Error checking user email:', error.message)
      alert(`Fehler beim Überprüfen der E-Mail: ${error.message}`)
    }
  }

  return (
    <div className='editCatalog' style={{ marginBottom: '100px' }}>
      <h2>Katalog bearbeiten</h2>
      <form onSubmit={handleSubmit} className='editCatalogForm'>
        <div className='form-group'>
          <label htmlFor='name'>Katalogname:</label>
          <input
            type='text'
            id='name'
            value={name}
            onChange={e => handleChange('name', e.target.value)}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='description'>Beschreibung:</label>
          <textarea
            id='description'
            value={description}
            style={{ minHeight: '100px' }}
            onChange={e => handleChange('description', e.target.value)}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='creator'>Ersteller:</label>
          <input
            type='text'
            id='creator'
            value={creatorUserName}
            required
            disabled
          />
        </div>
        <div className='form-group'>
          <label htmlFor='price'>Preis:</label>
          <input
            type='number'
            id='price'
            value={price}
            onChange={e => handleChange('price', e.target.value)}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='public'>Öffentlich:</label>
          <input
            type='checkbox'
            id='public'
            checked={isPublic}
            onChange={e => handleChange('public', e.target.checked)}
          />
        </div>
        <div className='form-group'>
          <label>Admins:</label>
          <ul>
            {adminEmails.map((email, index) => (
              <li
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '5px',
                  flexWrap: 'wrap',
                  listStyleType: 'disc'
                }}
              >
                {email}
                {isCreator && admins[index] !== user.uid && (
                  <button
                    type='button'
                    onClick={() => handleDeleteAdmin(admins[index])} // Verwende die Admin-ID
                    className='deleteAdminButton'
                    style={{ width: 'auto', margin: '5px' }}
                  >
                    X
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
        {isCreator && (
          <div className='form-group'>
            <label htmlFor='newAdminEmail'>Neuen Admin hinzufügen:</label>
            <input
              type='email'
              id='newAdminEmail'
              value={newAdminEmail}
              onChange={e => setNewAdminEmail(e.target.value)}
              placeholder='Admin E-Mail eingeben'
              className={
                isEmailValid === false
                  ? 'invalid-email'
                  : isEmailValid === true
                  ? 'valid-email'
                  : ''
              }
            />
            <button
              type='button'
              onClick={handleAddAdmin}
              className='addAdminButton'
              style={{ backgroundColor: '#333', marginTop: '5px' }}
            >
              Admin hinzufügen
            </button>
          </div>
        )}

        <button type='submit' disabled={loading || !changesMade}>
          {loading ? 'Speichern...' : 'Änderungen speichern'}
        </button>
      </form>
    </div>
  )
}

export default EditCatalog
