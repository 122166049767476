import React, { useState, useEffect } from 'react'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import InfoIcon from '@mui/icons-material/Info'
import CancelIcon from '@mui/icons-material/Cancel'
import './style/LapQuestion.css'

const LapQuestion = ({
  data,
  onAnswerSubmit,
  isWrong,
  isEvaluated,
  mode,
  handleNextQuestion
}) => {
  const [themeAnswers, setThemeAnswers] = useState(
    new Array(data.themes.length).fill('') // Initialize empty answers
  )
  const [lastThemeAnswers, setLastThemeAnswers] = useState(
    new Array(data.themes.length).fill('') // Initialize empty answers
  )
  const [selfEvaluations, setSelfEvaluations] = useState(
    new Array(data.themes.length).fill(null) // Track correct (true) or incorrect (false)
  )
  const [currentThemeIndex, setCurrentThemeIndex] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [evalutedCounter, setEvalutedCounter] = useState(0)

  const [infoActive, setInfoActive] = useState(false)

  const [isImageFullscreen, setIsImageFullscreen] = useState(false) // State for fullscreen image

  const handleImageClick = () => {
    setIsImageFullscreen(true)
  }

  const handleCloseFullscreen = () => {
    setIsImageFullscreen(false)
  }

  useEffect(() => {
    const handleEnterKey = event => {
      if (isWrong) {
        handleNextQuestion() // Go to the next question
      }

      if (isSubmitted) return

      if (event.key === 'Enter') {
        event.preventDefault() // Prevent default form submission or other actions

        // Submit if it's the last theme

        if (currentThemeIndex === data.themes.length - 1) {
          document.getElementById('submit-lap-button').click()
        } else if (currentThemeIndex < data.themes.length - 1 && !isSubmitted) {
          handleNextTheme()
        }
      }
    }

    document.addEventListener('keydown', handleEnterKey)

    return () => {
      document.removeEventListener('keydown', handleEnterKey)
    }
  }, [currentThemeIndex, data.themes.length]) // Dependencies ensure that changes in these values trigger the effect

  const handleThemeAnswerChange = e => {
    const updatedThemeAnswers = [...themeAnswers]
    updatedThemeAnswers[currentThemeIndex] = e.target.value
    setThemeAnswers(updatedThemeAnswers)
  }

  const handleNextTheme = () => {
    if (currentThemeIndex < data.themes.length - 1) {
      setCurrentThemeIndex(currentThemeIndex + 1)
    }
  }

  const handlePrevTheme = () => {
    if (currentThemeIndex > 0) {
      setCurrentThemeIndex(currentThemeIndex - 1)
    }
  }

  const handleSelfAnswerRight = () => {
    const updatedEvaluations = [...selfEvaluations]
    updatedEvaluations[currentThemeIndex] = true // Mark as correct
    setSelfEvaluations(updatedEvaluations)
    setEvalutedCounter(evalutedCounter + 1)

    // Trigger next theme only if it's not the last one
    if (currentThemeIndex < data.themes.length - 1) {
      setCurrentThemeIndex(currentThemeIndex + 1)
    }
  }

  const handleSelfAnswerWrong = () => {
    const updatedEvaluations = [...selfEvaluations]
    updatedEvaluations[currentThemeIndex] = false // Mark as incorrect
    setSelfEvaluations(updatedEvaluations)
    setEvalutedCounter(evalutedCounter + 1)

    // Trigger next theme only if it's not the last one
    if (currentThemeIndex < data.themes.length - 1) {
      setCurrentThemeIndex(currentThemeIndex + 1)
    }
  }

  useEffect(() => {
    if (evalutedCounter === data.themes.length) {
      setIsSubmitted(false)
      setEvalutedCounter(0)
      setCurrentThemeIndex(0)
      setLastThemeAnswers(themeAnswers) // Save last answers
      setThemeAnswers(new Array(data.themes.length).fill('')) // Reset answers
      setSelfEvaluations(new Array(data.themes.length).fill(null)) // Reset evaluations

      onAnswerSubmit({
        themeAnswers,
        selfEvaluations
      })
    }
  }, [evalutedCounter])

  const handleSubmit = e => {
    e.preventDefault()
    setIsSubmitted(true)
    setCurrentThemeIndex(0)
  }

  return (
    <div className='lapQuestion'>
      {isImageFullscreen && (
        <div className='fullscreenOverlay' onClick={handleCloseFullscreen}>
          <img
            src={data.imageUrl}
            alt='Lap question fullscreen'
            className='fullscreenImage'
          />
        </div>
      )}
      {isEvaluated && (
        <div className='lapHeader'>
          <p
            style={{
              fontSize: '1.3rem'
            }}
          >
            {data.heading}
          </p>
          <p>{data.task}</p>
        </div>
      )}
      {!isSubmitted && !isEvaluated && (
        <>
          {data.imageUrl && (
            <div className='lapImageContainer'>
              <img
                src={data.imageUrl}
                alt='Lap question related'
                className='lapImage'
                onClick={handleImageClick} // Enable fullscreen on click
              />
            </div>
          )}
          <div className='lapHeader'>
            <h4>{data.heading}</h4>
            <p>{data.task}</p>
          </div>
        </>
      )}
      <form onSubmit={handleSubmit} className='lapForm'>
        <div className='themesContainer'>
          <div className='themeAnswer'>
            {!isSubmitted ? (
              <>
                {!isEvaluated && (
                  <label htmlFor={`theme-${currentThemeIndex}`}>
                    {data.themes[currentThemeIndex]}:
                  </label>
                )}

                {!isEvaluated && (
                  <input
                    type='text'
                    id={`theme-${currentThemeIndex}`}
                    value={themeAnswers[currentThemeIndex] || ''}
                    onChange={handleThemeAnswerChange}
                    disabled={isSubmitted || isWrong}
                    required
                  />
                )}
              </>
            ) : (
              <>
                <label htmlFor={`theme-${currentThemeIndex}`}>
                  {data.themes[currentThemeIndex]}:
                </label>
                <div className='solutionTextLapQuestion'>
                  <h4>Deine Antwort:</h4>
                  <div className='yourTextLapQuestionInnerDiv'>
                    <p>{themeAnswers[currentThemeIndex]}</p>
                  </div>
                </div>
              </>
            )}

            {isSubmitted && (
              <div className='solutionTextLapQuestion'>
                <h4>Unsere Antwort:</h4>
                <div className='solutionTextLapQuestionInnerDiv'>
                  <p>{data.themeAnswers[currentThemeIndex]}</p>
                </div>
              </div>
            )}
          </div>

          {!isSubmitted ? (
            <div className='navigationButtons'>
              {!isEvaluated && (
                <>
                  {' '}
                  <button
                    type='button'
                    onClick={handlePrevTheme}
                    disabled={currentThemeIndex === 0}
                  >
                    Zurück
                  </button>
                  {!isSubmitted &&
                  currentThemeIndex === data.themes.length - 1 ? (
                    <button
                      style={{ maxWidth: '600px', marginTop: '0' }}
                      type='submit'
                      id='submit-lap-button'
                      className='submitButton'
                    >
                      Abschließen
                    </button>
                  ) : (
                    <button
                      type='button'
                      onClick={handleNextTheme}
                      disabled={currentThemeIndex === data.themes.length - 1}
                    >
                      Weiter
                    </button>
                  )}
                </>
              )}
            </div>
          ) : (
            <div className='selfAnswerDivOver'>
              {infoActive && (
                <div className='infoActiveDiv'>
                  <h4>
                    Hier können Sie Ihre Antworten mit den Lösungsvorschlägen
                    des Katalogs vergleichen und im Anschluss selbst bewerten!
                  </h4>
                </div>
              )}
              {!infoActive ? (
                <div
                  className='infoDiv'
                  onClick={() => setInfoActive(!infoActive)}
                >
                  <InfoIcon style={{ color: '#ff7e5f', fontSize: '2rem' }} />
                </div>
              ) : (
                <div
                  className='infoDiv'
                  onClick={() => setInfoActive(!infoActive)}
                >
                  <CancelIcon style={{ color: '#ff7e5f' }} />
                </div>
              )}

              <h4>Selbst bewerten:</h4>
              <div className='selfAnswerButtonDiv'>
                <button
                  type='button'
                  style={{ backgroundColor: 'red' }}
                  onClick={handleSelfAnswerWrong}
                >
                  <ThumbDownIcon />
                </button>
                <button
                  type='button'
                  style={{ backgroundColor: 'green' }}
                  onClick={handleSelfAnswerRight}
                >
                  <ThumbUpIcon />
                </button>
              </div>
            </div>
          )}
          {isEvaluated && (
            <>
              <h4>Falsche Antworten</h4>
              {isWrong.length > 0 ? (
                isWrong.map(index => (
                  <div key={index} className='incorrectTheme'>
                    <p className='incorrectThemeTheme'>
                      <strong>
                        Theme {index + 1}: {data.themes[index]}
                      </strong>
                    </p>
                    <p className='incorrectThemeUserAnswer'>
                      <strong> Deine Antwort: </strong>&nbsp;
                      {lastThemeAnswers[index]}
                    </p>
                    <p className='incorrectThemeAnswer'>
                      <strong> Richtige Antwort: </strong>&nbsp;
                      {data.themeAnswers[index]}
                    </p>
                  </div>
                ))
              ) : (
                <p>Alles richtig!</p>
              )}
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default LapQuestion
