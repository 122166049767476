import React from 'react'
import { useNavigate } from 'react-router-dom'
import './LandingPage.css'
import logo from '../assets/logoHighQuality.png'
import icon from '../assets/icon.png'
import learnFoto from '../assets/images/learnfoto.png'
import testFoto from '../assets/images/testergebnisseProblemfragenfoto.png'
import dashboardFoto from '../assets/images/dashboardfoto.png'
import addCatlogFoto from '../assets/images/addcatalogfoto.png'
import { useEffect } from 'react'

const LandingPage = () => {
  const navigate = useNavigate()

  return (
    <div className='landing-container'>
      {/* Navbar */}
      <div className='navbarLandingPage'>
        <img src={icon} style={{ height: '5vh' }} alt='App Icon' />
        <div className='navbarLandingPageButtonDiv'>
          <p onClick={() => navigate('/register')}>Registrieren</p>
          <p onClick={() => navigate('/login')} className='login-highlight'>
            Login
          </p>
        </div>
      </div>

      {/* Content */}
      <div className='content'>
        <div className='halfDivLandingPage coloredBackground'>
          <img src={logo} alt='StudyBox Logo' />
        </div>
        <div className='halfDivLandingPage' style={{ position: 'relative' }}>
          <h2>
            Willkommen zu <span className='highlight'>StudyBox</span>!
          </h2>
          <p>Study smarter, not harder.</p>
          <button className='cta-button' onClick={() => navigate('/register')}>
            Jetzt loslegen
          </button>
          <p style={{ position: 'absolute', bottom: '0', fontWeight: '200' }}>
            Entwickelt von Schülern für Schüler!
          </p>
        </div>
      </div>

      {/* Sections */}
      <div className='pageLandingPage flexReverse'>
        <div className='halfDivLandingPage100' style={{ padding: '0' }}>
          <img className='dashboardImage' src={testFoto} alt='lol' />
        </div>

        <div
          className='halfDivLandingPage100 '
          style={{ backgroundColor: '#333' }}
        >
          <h3>Was ist StudyBox?</h3>
          <p style={{ color: 'white' }}>
            StudyBox hilft dir, effizienter und gezielter zu lernen. Erstelle
            individuelle Fragenkataloge, teste dein Wissen und verfolge deinen
            Fortschritt. Egal ob für Schule, Studium, Weiterbildung oder
            Lehrabschlussprüfung – mit StudyBox behältst du den Überblick!
          </p>
        </div>
      </div>

      <div className='pageLandingPage'>
        <div
          className='halfDivLandingPage100'
          style={{ backgroundColor: '#ff7e5f' }}
        >
          <h3 style={{ color: '#333' }}>Funktionen der App:</h3>
          <ul className='feature-list'>
            <li style={{ color: 'white' }}>
              Fragenkataloge erstellen und teilen
            </li>
            <li style={{ color: 'white' }}>
              Fortschritt in Echtzeit verfolgen
            </li>
            <li style={{ color: 'white' }}>Effiziente Lernmethoden anwenden</li>
            <li style={{ color: 'white' }}>
              Persönliche Statistiken und Analysen
            </li>
          </ul>
        </div>
        <div className='halfDivLandingPage100'>
          <img className='dashboardImage' src={dashboardFoto} alt='oida' />
        </div>
      </div>

      <div className='pageLandingPage flexReverse'>
        <div
          className='halfDivLandingPage100'
          style={{ backgroundColor: 'white' }}
        >
          <img className='dashboardImage' src={learnFoto} alt='oida' />
        </div>

        <div
          className='halfDivLandingPage100'
          style={{ backgroundColor: '#333' }}
        >
          <h3 style={{ color: '#ff7e5f' }}>Warum StudyBox?</h3>
          <p style={{ color: 'white' }}>
            Mit unserer App lernst du nicht härter, sondern klüger. Perfekt für
            alle, die ihre Ziele schneller und stressfreier erreichen wollen!
          </p>
        </div>
      </div>
    </div>
  )
}

export default LandingPage
