import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

const Text = ({ question, onSubmit, onDelete }) => {
  const [questionText, setQuestionText] = useState(question.question)
  const [answerText, setAnswerText] = useState(question.correctAnswer)

  const handleQuestionChange = e => {
    const newQuestion = e.target.value
    setQuestionText(newQuestion)
  }

  const handleAnswerChange = e => {
    const newAnswer = e.target.value
    setAnswerText(newAnswer)
  }

  const handleSubmit = e => {
    e.preventDefault()
    // Das ursprüngliche question-Objekt mit den aktualisierten Werten zusammenbauen
    const updatedQuestion = {
      ...question,
      question: questionText,
      correctAnswer: answerText
    }

    // Die übergebene onSubmit-Funktion mit dem aktualisierten Objekt aufrufen
    if (onSubmit) {
      onSubmit(updatedQuestion)
    }
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(question.id)
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <input
          style={{
            marginBottom: '20px',
            backgroundColor: '#333',
            color: 'white'
          }}
          value={questionText}
          onChange={handleQuestionChange}
        />
        <input value={answerText} onChange={handleAnswerChange} />
        <input
          className='editQuestionKomponentSubmitButton'
          type='submit'
          value='Änderungen speichern'
        />
      </form>
      <button
        style={{ marginTop: '6px', width: '100%' }}
        className='deleteButtonEditQuestions'
        onClick={handleDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  )
}

export default Text
