import React, { useState, useEffect } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import update from 'immutability-helper'
import './style/DndQuestion.css'

const DndQuestion = ({ data, onAnswerSubmit, isWrong, handleNextQuestion }) => {
  // Shuffle function to randomize array order
  const shuffleArray = array => {
    return array
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
  }

  // Initialize blocks with shuffled data
  const [blocks, setBlocks] = useState([])

  useEffect(() => {
    setBlocks(shuffleArray(data.block || [])) // Shuffle blocks on mount
  }, [data.block])

  // Function to move a block within the list
  const moveBlock = (dragIndex, hoverIndex) => {
    const dragBlock = blocks[dragIndex]
    setBlocks(
      update(blocks, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragBlock]
        ]
      })
    )
  }

  // Function to submit the answer
  const handleSubmit = e => {
    e.preventDefault()
    onAnswerSubmit(blocks) // Submit the current block order
  }

  // Determine border color based on correctness
  const getBorderColor = (block, index) => {
    if (isWrong) {
      return block === data.block[index] ? 'green' : 'red'
    }
    return '#ccc'
  }

  useEffect(() => {
    const handleEnterKey = event => {
      if (event.key === 'Enter') {
        if (isWrong) {
          handleNextQuestion() // Move to the next question
        } else {
          event.preventDefault()
          document.getElementById('submitButton').click()
        }
      }
    }

    // Add and clean up event listener for Enter key
    document.addEventListener('keydown', handleEnterKey)
    return () => document.removeEventListener('keydown', handleEnterKey)
  }, [isWrong, handleNextQuestion])

  return (
    <div className='dndQuestion'>
      <h4>{data.question}</h4>

      {data.imageUrl && (
        <div className='questionImageContainer'>
          <img
            src={data.imageUrl}
            alt='Question related'
            className='questionImage'
          />
        </div>
      )}

      <div className='dndContainer'>
        {blocks.map((block, index) => (
          <Block
            key={index}
            index={index}
            text={block}
            moveBlock={moveBlock}
            getBorderColor={getBorderColor}
          />
        ))}
        {isWrong && (
          <div className='correctAnswer'>
            <strong>Richtige Reihenfolge:</strong>
            <ul style={{ width: '100%' }}>
              {data.block.map((block, index) => (
                <li style={{ width: '100%' }} key={index}>
                  {block}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <button className='submitButton' id='submitButton' onClick={handleSubmit}>
        Weiter
      </button>
    </div>
  )
}

const Block = ({ text, index, moveBlock, getBorderColor }) => {
  const [, ref] = useDrag({
    type: 'BLOCK',
    item: { index }
  })

  const [, drop] = useDrop({
    accept: 'BLOCK',
    hover (item) {
      if (item.index !== index) {
        moveBlock(item.index, index)
        item.index = index
      }
    }
  })

  return (
    <div
      ref={node => ref(drop(node))}
      className='dndBlock'
      style={{
        borderColor: getBorderColor(text, index)
      }}
    >
      {text}
    </div>
  )
}

export default DndQuestion
