import { collection, getDocs, addDoc, query, where } from 'firebase/firestore'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { db } from '../firebaseConfig'

export const handleAddQuestion = async ({
  setIsLoading,
  question,
  correctAnswer,
  category,
  type,
  block,
  questionNumber,
  answers,
  image,
  task,
  heading,
  themes,
  themeAnswers,
  setMessage,
  setQuestion,
  setAnswers,
  setCorrectAnswer,
  setImage,
  setIsPulsing,
  setQuestionNumber,
  questionInputRef,
  userData,
  setTask,
  setHeading,
  setThemes,
  setThemeAnswers,
  clearFileInput,
  setBlocks,
  setFillInTheBlanksQuestion,
  setSelectedBlanks,
  selectedBlanks,
  fillInTheBlanksQuestion,
  selectedCatalog,
  catalogAdmins,
  user,
  multipleAnswers,
  setMultipleAnswers,
  tolerance,
  setTolerance
}) => {
  // Check if user is an admin
  if (!catalogAdmins.includes(user.uid)) {
    setMessage('Access denied. Only admins can add questions.')
    return
  }

  // Check if selectedCatalog is provided
  if (!selectedCatalog) {
    setMessage('Invalid catalog selection. Please select a catalog.')
    setIsLoading(false)
    return
  }

  if (
    !category ||
    (type === 'multiple' && answers.some(answer => !answer.trim())) ||
    (type === 'text' && !correctAnswer.trim()) ||
    (type === 'fillInTheBlanks' &&
      (fillInTheBlanksQuestion.trim() === '' || selectedBlanks.length === 0)) ||
    (type === 'dnd' && block.some(block => !block.trim())) ||
    (type === 'lap' &&
      (!task.trim() ||
        !heading.trim() ||
        themes.length === 0 || // Länge überprüfen
        themes.some(theme => !theme.trim()) || // Inhalte überprüfen
        themeAnswers.length !== themes.length || // Gleiche Länge sicherstellen
        themeAnswers.some(answer => !answer.trim())))
  ) {
    setMessage('Please fill out all required fields.')

    return
  }

  setIsLoading(true)

  // Check if question number is already taken
  const isQuestionNumberTaken = async () => {
    const q = query(
      collection(db, 'questions', selectedCatalog, 'questions'),
      where('category', '==', category),
      where('questionNumber', '==', questionNumber)
    )

    const querySnapshot = await getDocs(q)
    return !querySnapshot.empty
  }

  const numberTaken = await isQuestionNumberTaken()
  if (numberTaken) {
    setMessage(
      `Question number ${questionNumber} is already taken in this category.`
    )
    setIsLoading(false)
    return
  }

  let imageDownloadUrl = ''

  // Upload image to Firebase Storage, if any
  if (image) {
    try {
      const storage = getStorage()
      const storageRef = ref(storage, `questions/${image.name}`)
      await uploadBytes(storageRef, image)
      imageDownloadUrl = await getDownloadURL(storageRef)
    } catch (error) {
      console.error('Error uploading image:', error)
      setMessage('Error uploading image. Try again.')
      setIsLoading(false)
      return
    }
  }

  try {
    const questionData = {
      question,
      category,
      type,
      questionNumber,
      answers:
        type === 'multiple'
          ? answers
          : type === 'multipleAnswers'
          ? multipleAnswers
          : [],
      correctAnswer,
      imageUrl: imageDownloadUrl || '',
      task: type === 'lap' ? task : null,
      heading: type === 'lap' ? heading : null,
      themes: type === 'lap' ? themes : [],
      themeAnswers: type === 'lap' ? themeAnswers : [],
      block: type === 'dnd' ? block : null,
      fillInTheBlanksQuestion:
        type === 'fillInTheBlanks' ? fillInTheBlanksQuestion : '',
      selectedBlanks: type === 'fillInTheBlanks' ? selectedBlanks : [],
      createdBy: user.uid,
      tolerance
    }

    // Add question to Firestore
    await addDoc(
      collection(db, 'questions', selectedCatalog, 'questions'),
      questionData
    )

    setMessage('Question added successfully!')
    setQuestion('')
    setAnswers(['', '', '', ''])
    setTask('')
    setHeading('')
    setCorrectAnswer('')
    setImage(null)
    clearFileInput()
    setIsPulsing(true)
    setQuestionNumber(prevNumber => Number(prevNumber) + 1)
    setBlocks(['', '', '', ''])
    setSelectedBlanks([])
    setFillInTheBlanksQuestion('')
    setMultipleAnswers(Array(3).fill(''))
    setThemes(['', '', '', '', ''])
    setThemeAnswers(['', '', '', '', ''])
    setTimeout(() => {
      setIsPulsing(false)
      setIsLoading(false)
    }, 500)
    setTolerance(2)

    if (questionInputRef.current && type !== 'lap' && type !== 'dnd') {
      questionInputRef.current.focus()
    }
  } catch (error) {
    console.error('Error adding question:', error)
    setIsLoading(false)
    setMessage('Error adding question. Try again.')
  }
}
