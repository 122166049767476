import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { UserProvider } from './context/UserContext'
import { DataProvider } from './context/DataContext'

const Root = () => {
  const [catalog, setCatalog] = useState(null)
  const [userId, setUserId] = useState(null)

  const handleCatalogChange = catalog => {
    setCatalog(catalog)
  }

  return (
    <UserProvider>
      <DataProvider catalog={catalog} userId={userId}>
        {' '}
        {/* `catalog` und `userId` übergeben */}
        <App onCatalogChange={handleCatalogChange} setUserId={setUserId} />
      </DataProvider>
    </UserProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<Root />)
