import React, { useState, useEffect, useRef } from 'react'
import { useUser } from '../context/UserContext'

import { handleAddQuestion } from '../utils/questionsUtils'
import QuestionTypeFields from '../components/questions/QuestionTypeFields'

import EditIcon from '@mui/icons-material/Edit'
import CategoryIcon from '@mui/icons-material/Category'

import EditCategories from './EditCategories'
import EditQuestions from '../components/questions/EditQuestions'

import './styles/Questions.css'
import { useData } from '../context/DataContext'
import { AddBox } from '@mui/icons-material'

const Questions = ({ selectedCatalog }) => {
  const { user, userData } = useUser()
  const { catalogData, categories, questions } = useData()

  const [catalogAdmins, setCatalogAdmins] = useState([]) // State for catalog admins
  const [question, setQuestion] = useState('')
  const [category, setCategory] = useState('')
  const [type, setType] = useState('multiple')
  const [answers, setAnswers] = useState(['', '', '', '']) // Multiple Choice answers
  const [correctAnswer, setCorrectAnswer] = useState([])
  const [message, setMessage] = useState('')
  const [image, setImage] = useState(null)
  const [tolerance, setTolerance] = useState(2)
  const [sortedCategories, setSortedCategories] = useState([])
  const [questionNumber, setQuestionNumber] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [currentDropdown, setCurrentDropdown] = useState('')

  // LAP specific state variables
  const [task, setTask] = useState('')
  const [heading, setHeading] = useState('')
  const [themes, setThemes] = useState(['', '', '', '', ''])
  const [themeAnswers, setThemeAnswers] = useState(['', '', '', '', ''])

  //dnd
  const [blocks, setBlocks] = useState(['', '', '', ''])

  //fill in the blanks
  const [fillInTheBlanksQuestion, setFillInTheBlanksQuestion] = useState('')
  const [selectedBlanks, setSelectedBlanks] = useState([])

  const [multipleAnswers, setMultipleAnswers] = useState(['', '', '', ''])

  const questionInputRef = useRef(null)
  const fileInputRef = useRef(null) // Add reference for the file input

  const [isPulsing, setIsPulsing] = useState(false)

  useEffect(() => {
    if (type === 'lap') {
      setThemes(['', '', '', '', '']) // Standardanzahl an Themen
      setThemeAnswers(['', '', '', '', '']) // Standardanzahl an Antworten
    }
  }, [type])

  useEffect(() => {
    // Fetch catalog admins when the component mounts or when selectedCatalog changes
    const fetchCatalogAdmins = async () => {
      if (!selectedCatalog) return

      setCatalogAdmins(catalogData.admin)
    }

    fetchCatalogAdmins()
  }, [selectedCatalog, catalogData])

  useEffect(() => {
    if (message === 'Question added successfully!' && type !== 'lap') {
      if (questionInputRef.current) {
        questionInputRef.current.focus()
      }
    }
  }, [message, type])

  useEffect(() => {
    // Sort categories by name when categories data is available
    if (categories.length) {
      const sorted = [...categories].sort((a, b) =>
        a.name.localeCompare(b.name)
      )

      setSortedCategories(sorted.map(category => category.name)) // Extract only names if needed
    }
  }, [categories, selectedCatalog])

  useEffect(() => {
    if (!category) return // Exit if no category is selected

    // Filter questions to only those in the selected category and catalog
    const categoryQuestions = questions.filter(
      question => question.category === category
    )

    // Extract the question numbers in the filtered questions
    const numbers = categoryQuestions.map(question => question.questionNumber)

    // Find the highest question number and determine the next available number
    const highestNumber = numbers.length > 0 ? Math.max(...numbers) : 0
    const nextNumber = highestNumber + 1

    setQuestionNumber(nextNumber) // Set the next available question number
  }, [category, questions, selectedCatalog])

  const clearFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Clears the file input field
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    const questionData = {
      question,
      correctAnswer,
      category,
      type,
      questionNumber,
      answers:
        type === 'multiple'
          ? answers
          : type === 'multipleAnswers'
          ? multipleAnswers
          : [],
      image: type === 'image' || type === 'lap' ? image : null,
      task: type === 'lap' ? task : null,
      heading: type === 'lap' ? heading : null,
      themes: type === 'lap' ? themes : [],
      themeAnswers: type === 'lap' ? themeAnswers : [],
      block: type === 'dnd' ? blocks : [],
      fillInTheBlanksQuestion:
        type === 'fillInTheBlanks' ? fillInTheBlanksQuestion : '',
      selectedBlanks: type === 'fillInTheBlanks' ? selectedBlanks : [],
      tolerance
    }

    await handleAddQuestion({
      ...questionData,
      setMessage,
      setQuestion,
      setAnswers,
      handleAddAnswer,
      handleRemoveAnswer,
      setCorrectAnswer,
      setImage,
      setIsPulsing,
      setQuestionNumber,
      questionInputRef,
      userData,
      setIsLoading,
      setTask,
      setHeading,
      setThemes,
      setThemeAnswers,
      clearFileInput, // Pass clearFileInput function here
      setBlocks,
      setFillInTheBlanksQuestion,
      setSelectedBlanks,
      selectedBlanks,
      fillInTheBlanksQuestion,
      selectedCatalog,
      catalogAdmins, // Pass catalogAdmins here
      user,
      multipleAnswers,
      setMultipleAnswers,
      tolerance,
      setTolerance
    })
  }

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers]
    newAnswers[index] = value
    setAnswers(newAnswers)
  }

  const handleAddAnswer = () => {
    if (answers.length < 8) {
      setAnswers([...answers, ''])
    }
  }

  const handleRemoveAnswer = () => {
    if (answers.length > 2) {
      setAnswers(answers.slice(0, answers.length - 1))
    }
  }

  const handleDropdownClick = dropdown => {
    if (currentDropdown === dropdown) {
      setCurrentDropdown('')
    } else {
      setCurrentDropdown(dropdown)
    }
  }

  return (
    <div
      className={`addQuestionsOuterDiv ${
        currentDropdown !== '' ? 'addQuestionsOuterDivDropdown' : ''
      }`}
    >
      <div
        className={`addQuestions ${
          currentDropdown !== '' ? '' : 'addQuestionsNotDropedDown'
        }`}
      >
        <div
          onClick={() => handleDropdownClick('editQuestions')}
          className={`addQuestionsDropdown ${
            currentDropdown === 'editQuestions'
              ? 'addQuestionsDropdownDropedDown'
              : ''
          }`}
        >
          <EditIcon style={{ fontSize: '4rem' }} />

          <h2>Fragen bearbeiten</h2>
          {message && currentDropdown === 'editQuestion' && <p>{message}</p>}
        </div>
        {currentDropdown === 'editQuestions' && (
          <div className='addQuestionsDropedDown'>
            <EditQuestions selectedCatalog={selectedCatalog} />
          </div>
        )}
      </div>
      <div
        className={`addQuestions ${
          currentDropdown !== '' ? '' : 'addQuestionsNotDropedDown'
        }`}
      >
        <div
          onClick={() => handleDropdownClick('editCategories')}
          className={`addQuestionsDropdown ${
            currentDropdown === 'editCategories'
              ? 'addQuestionsDropdownDropedDown'
              : ''
          }`}
        >
          <CategoryIcon style={{ fontSize: '4rem' }} />

          <h2>Kategorien bearbeiten</h2>
          {message && currentDropdown === 'editCategories' && <p>{message}</p>}
        </div>
        {currentDropdown === 'editCategories' && (
          <div className='addQuestionsDropedDown'>
            <EditCategories selectedCatalog={selectedCatalog} />
          </div>
        )}
      </div>
      <div
        className={`addQuestions ${
          currentDropdown !== '' ? '' : 'addQuestionsNotDropedDown'
        }`}
      >
        <div
          onClick={() => handleDropdownClick('addQuestion')}
          className={`addQuestionsDropdown ${
            currentDropdown === 'addQuestion'
              ? 'addQuestionsDropdownDropedDown'
              : ''
          }`}
        >
          <AddBox style={{ fontSize: '4rem' }} />
          <h2>Frage hinzufügen</h2>
          {message && currentDropdown === 'addQuestion' && <p>{message}</p>}
        </div>

        {currentDropdown === 'addQuestion' && (
          <div className='addQuestionsDropedDown'>
            <div className='topSelection'>
              <div className='addQuestionsTopSelectionRowDiv'>
                <div>
                  <label htmlFor='category'>Kategorie:</label>
                  <select
                    id='category'
                    value={category}
                    onChange={e => setCategory(e.target.value)}
                  >
                    <option value=''>Wählen Sie eine Kategorie</option>
                    {sortedCategories.map((cat, index) => (
                      <option key={index} value={cat}>
                        {cat}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label htmlFor='questionNumber'>Fragen Nummer:</label>
                  <input
                    style={{ width: '90px' }}
                    type='number'
                    id='questionNumber'
                    value={questionNumber}
                    className={isPulsing ? 'pulse' : ''}
                    onChange={e => setQuestionNumber(Number(e.target.value))}
                  />
                </div>
              </div>

              <div>
                <label htmlFor='type'>Fragen Typ:</label>
                <select
                  id='type'
                  value={type}
                  onChange={e => setType(e.target.value)}
                >
                  <option value='multiple'>Multiple Choice</option>
                  <option value='text'>Text Antwort</option>
                  <option value='lap'>LAP Frage</option>
                  <option value='dnd'>Drag and Drop Frage</option>
                  <option value='fillInTheBlanks'>
                    Fill in the Blanks Frage
                  </option>
                  <option value='multipleAnswers'>Mehrere Antworten</option>
                </select>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              {type !== 'lap' && type !== 'fillInTheBlanks' && (
                <div>
                  <label htmlFor='question'>Frage:</label>
                  <input
                    type='text'
                    id='question'
                    value={question}
                    ref={questionInputRef}
                    onChange={e => setQuestion(e.target.value)}
                    placeholder='Enter the question'
                  />
                </div>
              )}

              <QuestionTypeFields
                type={type}
                answers={answers}
                handleAddAnswer={handleAddAnswer}
                handleRemoveAnswer={handleRemoveAnswer}
                correctAnswer={correctAnswer}
                handleAnswerChange={handleAnswerChange}
                setCorrectAnswer={setCorrectAnswer}
                setAnswers={setAnswers}
                image={image}
                setImage={setImage}
                task={task}
                setTask={setTask}
                heading={heading}
                setHeading={setHeading}
                themes={themes}
                setThemes={setThemes}
                themeAnswers={themeAnswers}
                setThemeAnswers={setThemeAnswers}
                fileInputRef={fileInputRef} // Ensure this is passed
                blocks={blocks}
                setBlocks={setBlocks}
                fillInTheBlanksQuestion={fillInTheBlanksQuestion}
                setFillInTheBlanksQuestion={setFillInTheBlanksQuestion}
                selectedBlanks={selectedBlanks}
                setSelectedBlanks={setSelectedBlanks}
                multipleAnswers={multipleAnswers}
                setMultipleAnswers={setMultipleAnswers}
                tolerance={tolerance}
                setTolerance={setTolerance}
              />

              {isLoading ? (
                <div className='spinner'></div>
              ) : (
                <button type='submit'>Frage hinzufügen</button>
              )}
            </form>
          </div>
        )}
      </div>
    </div>
  )
}

export default Questions
