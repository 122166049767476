import React, { useState } from 'react'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { auth, db } from '../firebaseConfig'
import {
  doc,
  setDoc,
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import { Link } from 'react-router-dom'
import Loading from '../components/Loading'
import logo from '../assets/icon.png'
import './Register.css'

const Register = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('') // Neuer State
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [username, setUsername] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleRegister = async () => {
    try {
      setErrorMessage('')
      setIsLoading(true)

      // Überprüfen, ob die Passwörter übereinstimmen
      if (password !== confirmPassword) {
        setErrorMessage('Die Passwörter stimmen nicht überein.')
        setIsLoading(false)
        return
      }

      // Überprüfen, ob der Benutzername bereits existiert
      const usersRef = collection(db, 'users')
      const usernameQuery = query(usersRef, where('username', '==', username))
      const querySnapshot = await getDocs(usernameQuery)

      if (!querySnapshot.empty) {
        setErrorMessage('Der Benutzername ist bereits vergeben.')
        setIsLoading(false)
        return
      }

      // Benutzer erstellen
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      )
      const user = userCredential.user

      // Direkt nach der Registrierung die Benutzerinformationen in Firestore speichern
      await setDoc(doc(db, 'users', user.uid), {
        firstName,
        lastName,
        username,
        email,
        createdAt: new Date(),
        catalogs: [],
        lastCatalog: '',
        streak: 0,
        demos: [{}]
      })

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Register Error:', error)

      switch (error.code) {
        case 'auth/email-already-in-use':
          setErrorMessage('Diese E-Mail-Adresse wird bereits verwendet.')
          break
        case 'auth/invalid-email':
          setErrorMessage('Die E-Mail-Adresse ist ungültig.')
          break
        case 'auth/weak-password':
          setErrorMessage(
            'Das Passwort ist zu schwach. Verwenden Sie ein stärkeres Passwort.'
          )
          break
        case 'auth/operation-not-allowed':
          setErrorMessage('Registrierung ist derzeit deaktiviert.')
          break
        default:
          setErrorMessage(
            'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.'
          )
      }
    }
  }

  return (
    <div className='register-container'>
      <div className='register-content'>
        <img src={logo} alt='Logo' className='login-logo' />

        <h2>Registrieren</h2>
        {errorMessage && <p className='error-message'>{errorMessage}</p>}
        <div className='input-group'>
          <input
            type='text'
            placeholder='Benutzername'
            value={username}
            onChange={e => setUsername(e.target.value)}
            className='input-field'
          />
          <input
            type='text'
            placeholder='Vorname'
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            className='input-field'
          />
          <input
            type='text'
            placeholder='Nachname'
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            className='input-field'
          />
          <input
            type='email'
            placeholder='E-Mail'
            value={email}
            onChange={e => setEmail(e.target.value)}
            className='input-field'
          />
          <input
            type='password'
            placeholder='Passwort'
            value={password}
            onChange={e => setPassword(e.target.value)}
            className='input-field'
          />
          <input
            type='password'
            placeholder='Passwort bestätigen'
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)} // Neues Eingabefeld
            onKeyDown={e => e.key === 'Enter' && handleRegister()}
            className='input-field'
          />
        </div>
        <div className='button-group'>
          <button className='registerButton' onClick={handleRegister}>
            Registrieren
          </button>
          <Link to='/login' className='loginLink'>
            Anmelden
          </Link>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  )
}

export default Register
