// pages/Profile.js
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUser } from '../context/UserContext'
import { signOut, deleteUser } from 'firebase/auth'
import { auth } from '../firebaseConfig'
import './styles/Profile.css'

const Profile = () => {
  const { user, userData } = useUser()
  const navigate = useNavigate()

  if (!user || !userData) {
    return <p>Lade Daten...</p>
  }

  const handleLogout = async () => {
    try {
      await signOut(auth)
      navigate('/login')
    } catch (error) {
      console.error('Logout error:', error)
    }
  }

  const handleDeleteAccount = async () => {
    const confirmation = window.confirm(
      'Are you sure you want to delete your account? This action cannot be undone.'
    )
    if (confirmation) {
      try {
        await deleteUser(user)
        alert('Account deleted successfully')
        navigate('/register')
      } catch (error) {
        console.error('Error deleting account:', error)
        if (error.code === 'auth/requires-recent-login') {
          alert('Please re-login to delete your account.')
        }
      }
    }
  }

  return (
    <div className='profile-container'>
      <div className='profile-content'>
        <h2>Profil</h2>
        <div className='profile-info'>
          <p>
            <strong>Vorname:</strong> {userData.firstName}
          </p>
          <p>
            <strong>Nachname:</strong> {userData.lastName}
          </p>
          <p>
            <strong>Email:</strong> {user.email}
          </p>
        </div>
        <div className='profile-actions'>
          <button className='logout-button' onClick={handleLogout}>
            Logout
          </button>
          <button className='delete-button' onClick={handleDeleteAccount}>
            Account löschen
          </button>
        </div>
      </div>
    </div>
  )
}

export default Profile
