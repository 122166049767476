import React, { useEffect, useState } from 'react'
import { useData } from '../../context/DataContext'
import { db } from '../../firebaseConfig'
import { doc, updateDoc, deleteDoc } from 'firebase/firestore'
import EditQuestionRenderComponent from './editQuestions/EditQuestionRenderComponent'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

const EditQuestions = ({ selectedCatalog }) => {
  const { categories, questions } = useData() // Access categories and questions from context
  const [categoryQuestions, setCategoryQuestions] = useState({})
  const [openDropdown, setOpenDropdown] = useState('')
  const [categoryColors, setCategoryColors] = useState({})
  const [message, setMessage] = useState({ id: '', text: '' }) // State for notification message with question ID

  useEffect(() => {
    // Map category colors
    const colorsMap = categories.reduce((acc, category) => {
      acc[category.name] = category.color // Map category name to color
      return acc
    }, {})
    setCategoryColors(colorsMap)

    // Filter, sort, and group questions by category and question number
    const sortedQuestions = questions.sort((a, b) => {
      const categoryComparison = a.category.localeCompare(b.category)
      return categoryComparison !== 0
        ? categoryComparison
        : a.questionNumber - b.questionNumber
    })

    const groupedQuestions = sortedQuestions.reduce((acc, question) => {
      const { category } = question
      if (!acc[category]) acc[category] = []
      acc[category].push(question)
      return acc
    }, {})

    setCategoryQuestions(groupedQuestions)
  }, [categories, questions, selectedCatalog])

  const toggleDropdown = category => {
    setOpenDropdown(prev => (prev === category ? '' : category))
  }

  const handleSubmit = async updatedQuestion => {
    try {
      const questionRef = doc(
        db,
        'questions',
        selectedCatalog,
        'questions',
        updatedQuestion.id
      )
      await updateDoc(questionRef, {
        ...updatedQuestion
      })

      setMessage({
        id: updatedQuestion.id,
        text: 'Question updated successfully!'
      })
      setTimeout(() => setMessage({ id: '', text: '' }), 3000) // Clear message after 3 seconds
    } catch (error) {
      console.error('Error updating question:', error)
      setMessage({ id: updatedQuestion.id, text: 'Error updating question.' })
    }
  }

  const handleDelete = async questionId => {
    try {
      const questionRef = doc(
        db,
        'questions',
        selectedCatalog,
        'questions',
        questionId
      )
      await deleteDoc(questionRef)

      setMessage({ id: questionId, text: 'Question deleted successfully!' })
      setTimeout(() => setMessage({ id: '', text: '' }), 3000) // Clear message after 3 seconds
    } catch (error) {
      console.error('Error deleting question:', error)
      setMessage({ id: questionId, text: 'Error deleting question.' })
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
      {Object.keys(categoryQuestions).map(category => (
        <div
          key={category}
          style={{ backgroundColor: '#333', borderRadius: '5px' }}
        >
          <div
            onClick={() => toggleDropdown(category)}
            style={{
              backgroundColor: categoryColors[category] || '#ccc',
              padding: '10px',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '0px'
            }}
            className='categoryDropdown'
          >
            <p>{category}</p>
            <ArrowDropDownIcon />
          </div>
          {openDropdown === category && (
            <div
              style={{
                marginTop: '-5px',
                paddingTop: '25px',
                paddingBottom: '25px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px'
              }}
            >
              {categoryQuestions[category].map(question => (
                <React.Fragment key={question.id}>
                  <h2
                    style={{
                      color: 'white',
                      textAlign: 'right',
                      marginBottom: '0',
                      paddingRight: '1rem',
                      width: '95%'
                    }}
                  >
                    {question.questionNumber}
                  </h2>
                  <div
                    className='editQuestionKomponent'
                    style={{
                      marginBottom: '5px',
                      width: '95%',
                      backgroundColor: '#ccc'
                    }}
                  >
                    <EditQuestionRenderComponent
                      question={question}
                      onSubmit={handleSubmit}
                      onDelete={handleDelete}
                    />
                    {/* Display message if it corresponds to this question */}
                    {message.id === question.id && (
                      <div
                        style={{
                          backgroundColor: '#4caf50',
                          color: 'white',
                          padding: '10px',
                          textAlign: 'center',
                          borderRadius: '5px',
                          marginBottom: '10px',
                          marginTop: '10px',
                          width: '100%'
                        }}
                      >
                        {message.text}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default EditQuestions
