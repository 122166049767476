// chatUtils.js
import {
  collection,
  addDoc,
  query,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
  setDoc,
  where,
  getDocs,
  updateDoc,
  arrayUnion
} from 'firebase/firestore'

export const fetchCatalogName = async (db, catalogId, setCatalogName) => {
  try {
    const catalogDocRef = doc(db, 'questions', catalogId)
    const catalogDocSnap = await getDoc(catalogDocRef)

    if (catalogDocSnap.exists()) {
      setCatalogName(catalogDocSnap.data().name)
    } else {
      console.log('Catalog document not found')
    }
  } catch (error) {
    console.error('Error fetching catalog name:', error)
  }
}

// Function to fetch the creator ID of a catalog
export const fetchCreatorId = async (db, catalogId, setMessageTo) => {
  if (!catalogId) return

  try {
    const catalogDocRef = doc(db, 'questions', catalogId)
    const catalogDocSnap = await getDoc(catalogDocRef)

    if (catalogDocSnap.exists()) {
      const creatorId = catalogDocSnap.data().creator
      setMessageTo(creatorId)
    } else {
      console.log('Catalog document not found')
    }
  } catch (error) {
    console.error('Error fetching creator ID:', error)
  }
}

// Function to fetch messages in real-time
export const fetchMessages = (db, chatId, setMessages, loadUserNames) => {
  const messagesRef = collection(db, 'chats', chatId, 'messages')
  const q = query(messagesRef, orderBy('timestamp'))

  return onSnapshot(q, snapshot => {
    const msgs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    setMessages(msgs)
    loadUserNames(msgs)
  })
}

// Function to fetch requests
export const fetchRequests = async (
  db,
  messageTo,
  userId,
  catalogId,
  setRequest
) => {
  if (!messageTo || !userId) return

  try {
    const requestsRef = collection(db, 'users', messageTo, 'requests')
    const q = query(
      requestsRef,
      where('requesterId', '==', userId),
      where('catalogId', '==', catalogId)
    )

    const requestSnapshot = await getDocs(q)

    if (!requestSnapshot.empty) {
      setRequest(true)
    } else {
      setRequest(false)
    }
  } catch (error) {
    console.error('Error fetching request:', error)
  }
}

export const fetchUserName = async (db, userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId))
    return userDoc.exists() ? userDoc.data().username : 'User not found'
  } catch (error) {
    console.error('Error fetching user name:', error)
    return 'User not found'
  }
}

// Function to send a message
export const sendMessage = async ({
  db,
  chatId,
  user,
  message,
  messageTo,
  userId,
  catalogId,
  setMessage,
  type = 'text' // Default type to 'text'
}) => {
  const chatRef = doc(db, 'chats', chatId) // Reference to the chat document
  const messagesRef = collection(db, 'chats', chatId, 'messages') // Reference to the messages subcollection

  try {
    // Allow empty messages only for 'request' type
    if (type !== 'request' && message.trim() === '') {
      console.warn('Message cannot be empty for type:', type)
      return
    }

    const chatDoc = await getDoc(chatRef)

    if (!chatDoc.exists()) {
      // If chat does not exist, create it
      await setDoc(chatRef, {
        participants: [userId, messageTo],
        createdAt: new Date(),
        catalogId: catalogId,
        lastMessage: new Date(),
        seller: { id: messageTo, read: false },
        buyer: { id: userId, read: true },
        sold: false
      })
    } else {
      const chatData = chatDoc.data()

      // Update lastMessage and read status
      const updateData =
        userId === user.uid
          ? {
              lastMessage: new Date(),
              seller: {
                ...chatData.seller,
                read: false
              }
            }
          : {
              lastMessage: new Date(),
              buyer: {
                ...chatData.buyer,
                read: false
              }
            }

      await updateDoc(chatRef, updateData)
    }

    // Add the message to the messages subcollection

    const newMessage = {
      senderId: user.uid,
      timestamp: new Date(),
      ...(type === 'request' ? { type: 'request' } : { message })
    }

    await addDoc(messagesRef, newMessage)
    setMessage('') // Clear the input field
  } catch (error) {
    console.error('Error sending message:', error)
  }
}

export const acceptRequest = async (db, userId, catalogId, chatId) => {
  try {
    // Reference to the user's document
    const requestRef = doc(db, 'users', userId)

    // Reference to the specific chat document
    const chatRef = doc(db, 'chats', chatId)

    // Reference to the specific catalog document
    const catalogRef = doc(db, 'questions', catalogId)
    const catalogDocSnap = await getDoc(catalogRef)

    if (!catalogDocSnap.exists()) {
      throw new Error(`Catalog ${catalogId} does not exist.`)
    }

    const catalogData = catalogDocSnap.data()
    const currentTimesSold = catalogData.timesSold || 0 // Default to 0 if timesSold does not exist

    // Update user's catalogs array
    await updateDoc(requestRef, {
      catalogs: arrayUnion(catalogId)
    })

    // Mark the chat as sold
    await updateDoc(chatRef, {
      sold: true
    })

    // Increment times sold
    await updateDoc(catalogRef, {
      timesSold: currentTimesSold + 1
    })
  } catch (error) {
    console.error('Error accepting request:', error)
  }
}

export const fetchIsSold = (db, chatId, setCatalogIsSold) => {
  const messagesRef = doc(db, 'chats', chatId)

  onSnapshot(messagesRef, snapshot => {
    const chatData = snapshot.data()

    setCatalogIsSold(chatData?.sold)
  })
}
