import React, { useState } from 'react'
import { AnswerFields } from './AnswerFields'

import './QuetsionTypeFields.css'

const QuestionTypeFields = ({
  type,
  answers = [],
  handleAddAnswer,
  handleRemoveAnswer,
  correctAnswer = [],
  handleAnswerChange,
  setCorrectAnswer,
  task,
  setTask,
  heading,
  setHeading,
  themes = [], // Sicherstellen, dass themes initialisiert ist
  setThemes,
  themeAnswers = [], // Sicherstellen, dass themeAnswers initialisiert ist
  setThemeAnswers,
  image, // Foto Zustand hinzufügen
  setImage, // Setter für das Bild,,
  fileInputRef, // Accept the fileInputRef from props
  blocks, // Now passing blocks correctly
  setBlocks, // Also passing setBlocks to update the state
  fillInTheBlanksQuestion,
  setFillInTheBlanksQuestion,
  selectedBlanks,
  setSelectedBlanks,
  multipleAnswers,
  setMultipleAnswers,
  tolerance,
  setTolerance
}) => {
  const [numberOfThemes, setNumberOfThemes] = useState(5)

  const handleToleranceChange = event => {
    const value = event.target.value
    switch (value) {
      case 'Genau':
        setTolerance(1)
        break
      case 'Mittel':
        setTolerance(2)
        break
      case 'Grob':
        setTolerance(3)
        break
      default:
        setTolerance(1) // Standard auf 'Genau' setzen
    }
  }

  const addMultipleAnswer = () => {
    setMultipleAnswers([...multipleAnswers, ''])
  }

  // Handle removing an answer input
  const removeMultipleAnswer = index => {
    if (multipleAnswers.length > 1) {
      const updatedAnswers = multipleAnswers.filter((_, i) => i !== index)
      setMultipleAnswers(updatedAnswers)
    }
  }

  // Handle changing a specific answer
  const handleMultipleAnswerChange = (index, value) => {
    const updatedAnswers = [...multipleAnswers]
    updatedAnswers[index] = value
    setMultipleAnswers(updatedAnswers)
    setMultipleAnswers(updatedAnswers)
  }

  const renderQuestionPreview = () => {
    return fillInTheBlanksQuestion.split(' ').map((word, index) => {
      if (selectedBlanks.includes(index)) {
        // Render blank space for the selected blank index
        return (
          <span key={index} style={{ marginRight: '2px', color: 'black' }}>
            ____ {/* Render a blank line for the selected word */}
          </span>
        )
      }
      return (
        <span key={index} style={{ marginRight: '2px', color: 'black' }}>
          {word} {/* Render the original word */}
        </span>
      )
    })
  }

  const handleSetBlanks = index => {
    if (selectedBlanks.includes(index)) {
      setSelectedBlanks(selectedBlanks.filter(i => i !== index))
    } else {
      setSelectedBlanks([...selectedBlanks, index])
    }
  }

  // Funktion zum Aktualisieren eines bestimmten Themas
  const handleThemeChange = (index, value) => {
    const updatedThemes = [...themes]
    updatedThemes[index] = value
    setThemes(updatedThemes)
  }

  // Funktion zum Aktualisieren einer Antwort für ein bestimmtes Thema
  const handleThemeAnswerChange = (index, value) => {
    const updatedThemeAnswers = [...themeAnswers]
    updatedThemeAnswers[index] = value
    setThemeAnswers(updatedThemeAnswers)
  }

  // Funktion zum Entfernen eines Themas und der zugehörigen Antwort
  const removeTheme = () => {
    if (themes.length > 1) {
      setNumberOfThemes(prevThemes => prevThemes - 1) // Reduziert die Anzahl der Themen

      setThemes(prevThemes => prevThemes.slice(0, -1)) // Entfernt das letzte Thema
      setThemeAnswers(prevAnswers => prevAnswers.slice(0, -1)) // Entfernt die letzte Antwort
    } else {
      alert('Mindestens ein Thema muss vorhanden sein.')
    }
  }

  // Funktion zum Hinzufügen eines neuen Themas
  const addTheme = () => {
    const maxThemes = 10 // Beispiel: Limit auf 10 Themen
    if (themes.length >= maxThemes) {
      alert('Maximale Anzahl von Themen erreicht.')
      return
    }
    setNumberOfThemes(prevThemes => prevThemes + 1) // Erhöht die Anzahl der Themen

    setThemes(prevThemes => [...prevThemes, '']) // Fügt ein leeres Thema hinzu
    setThemeAnswers(prevAnswers => [...prevAnswers, '']) // Fügt eine leere Antwort hinzu
  }

  // Funktion zum Verwalten des hochgeladenen Bildes
  const handleImageChange = e => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0])
    }
  }

  const handleBlockChange = (index, value) => {
    const updatedBlocks = [...blocks]
    updatedBlocks[index] = value
    setBlocks(updatedBlocks) // Make sure to use setBlocks to update the state
  }

  const removeBlock = () => {
    if (blocks.length > 1) {
      setBlocks(prev => prev.slice(0, -1))
    }
  }

  switch (type) {
    case 'multiple':
      return (
        <AnswerFields
          answers={answers}
          correctAnswer={correctAnswer}
          handleAnswerChange={handleAnswerChange}
          setCorrectAnswer={setCorrectAnswer}
          handleAddAnswer={handleAddAnswer}
          handleRemoveAnswer={handleRemoveAnswer}
        />
      )

    case 'text':
      return (
        <div
          style={{
            marginBottom: '10px',
            marginTop: '10px'
          }}
        >
          <label htmlFor='correctAnswer'>Korrekte Antwort:</label>
          <input
            type='text'
            id='correctAnswer'
            value={correctAnswer}
            onChange={e => setCorrectAnswer(e.target.value)}
            placeholder='Enter the correct text answer'
          />
          <label style={{ marginTop: '10px' }} htmlFor='tolerance-select'>
            Toleranz auswählen:
          </label>
          <select
            id='tolerance-select'
            className='select-tolerance'
            onChange={handleToleranceChange}
          >
            <option>Genau</option>
            <option>Mittel</option>
            <option>Grob</option>
          </select>
        </div>
      )

    case 'lap':
      return (
        <div>
          <div className='addQuestionFormDivRow'>
            <div className='addQuestionFormDivCenter'>
              <label htmlFor='heading'>Überschrift:</label>
              <input
                type='text'
                id='heading'
                value={heading}
                onChange={e => setHeading(e.target.value)}
                placeholder='Überschrift eingeben'
              />
              <label htmlFor='task'>Aufgabe:</label>
              <input
                type='text'
                id='task'
                value={task}
                onChange={e => setTask(e.target.value)}
                placeholder='Aufgabe eingeben'
              />

              {/* Datei-Upload für das Bild */}
              <label htmlFor='imageUpload'>Bild hochladen:</label>
              <input
                style={{ marginBottom: '10px' }}
                type='file'
                id='imageUpload'
                accept='image/*'
                ref={fileInputRef} // Attach the passed fileInputRef here
                onChange={handleImageChange}
              />
            </div>
            {image && (
              <div className='uploadedImageDiv'>
                <p>Hochgeladenes Bild: {image.name}</p>
                <img
                  src={URL.createObjectURL(image)}
                  alt='Uploaded task'
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
            )}
          </div>
          <div className='themesDiv'>
            {' '}
            {Array.from({ length: numberOfThemes }, (_, i) => (
              <div key={i} className='themeDiv'>
                <label htmlFor={`theme-${i + 1}`}>Thema {i + 1}:</label>
                <input
                  type='text'
                  id={`theme-${i + 1}`}
                  value={themes[i] || ''} // Verhindern von undefined, falls kein Thema vorhanden ist
                  onChange={e => handleThemeChange(i, e.target.value)}
                  placeholder='Thema eingeben'
                />
                <input
                  type='text'
                  id={`answer-${i + 1}`}
                  value={themeAnswers[i] || ''} // Verhindern von undefined, falls keine Antwort vorhanden ist
                  onChange={e => handleThemeAnswerChange(i, e.target.value)}
                  placeholder='Antwort eingeben'
                />
              </div>
            ))}
          </div>
          <div
            className='buttonDivForm'
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            <button type='button' onClick={addTheme}>
              Thema hinzufügen
            </button>
            <button
              className='buttonDivFormEntfernenButton'
              type='button'
              onClick={removeTheme}
            >
              Thema entfernen
            </button>
          </div>
        </div>
      )

    case 'dnd':
      return (
        <div className='dragAndDropForm'>
          <div className='blocksContainer' style={{ marginTop: '1rem' }}>
            {blocks.map((block, index) => (
              <div key={index} className='blockItem'>
                <label htmlFor={`block-${index + 1}`}>
                  Baustein {index + 1}:
                </label>
                <input
                  type='text'
                  id={`block-${index + 1}`}
                  value={block}
                  onChange={e => handleBlockChange(index, e.target.value)}
                  placeholder={`Text für Baustein ${index + 1}`}
                />
              </div>
            ))}
            <div className='buttonDiv' style={{ marginBottom: '1rem' }}>
              <button type='button' onClick={() => setBlocks([...blocks, ''])}>
                Baustein hinzufügen
              </button>
              <button type='button' onClick={removeBlock}>
                Baustein entfernen
              </button>
            </div>
          </div>
        </div>
      )

    case 'fillInTheBlanks':
      return (
        <div className='fillInTheBlanksQuestionDiv'>
          <label htmlFor='tolerance-select'>Toleranz auswählen:</label>
          <select
            id='tolerance-select'
            className='select-tolerance'
            onChange={handleToleranceChange}
          >
            <option>Genau</option>
            <option>Mittel</option>
            <option>Grob</option>
          </select>
          <label htmlFor='question'>Frage:</label>
          <input
            style={{ width: '100%' }}
            onChange={e => setFillInTheBlanksQuestion(e.target.value)}
            value={fillInTheBlanksQuestion}
            id='question'
          />

          {/* Zeige die Wörter der Frage als Vorschau an */}
          <div className='wordsContainer'>
            {fillInTheBlanksQuestion.split(' ').map((word, index) => (
              <div
                key={index}
                onClick={() => handleSetBlanks(index)}
                className={`wordPreview ${
                  selectedBlanks.includes(index) ? 'selected' : ''
                }`} // Highlight selected words if necessary
                style={{
                  cursor: 'pointer',
                  display: 'inline-block',
                  margin: '4px'
                }}
              >
                {word}
              </div>
            ))}
          </div>

          {/* Vorschau der vollständigen Frage mit Lücken */}
          <div className='questionPreview'>
            <h4>Vorschau der Frage:</h4>
            <p>{renderQuestionPreview()}</p>
          </div>
        </div>
      )

    case 'multipleAnswers':
      return (
        <div style={{ marginBottom: '10px', marginTop: '10px' }}>
          <label htmlFor='tolerance-select'>Toleranz auswählen:</label>
          <select
            id='tolerance-select'
            className='select-tolerance'
            onChange={handleToleranceChange}
          >
            <option>Genau</option>
            <option>Mittel</option>
            <option>Grob</option>
          </select>
          <label>Korrekte Antworten:</label>

          {multipleAnswers.map((answer, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '5px'
              }}
            >
              <input
                type='text'
                value={answer}
                onChange={e =>
                  handleMultipleAnswerChange(index, e.target.value)
                }
                placeholder={`Antwort ${index + 1}`}
                style={{
                  marginRight: '5px',
                  backgroundColor: '#333',
                  color: 'white'
                }}
              />
              <button
                type='button'
                onClick={() => removeMultipleAnswer(index)}
                style={{
                  background: 'red',
                  color: 'white',
                  border: 'none',
                  cursor: 'pointer'
                }}
              >
                Entfernen
              </button>
            </div>
          ))}
          <button
            type='button'
            onClick={addMultipleAnswer}
            style={{ marginTop: '5px' }}
          >
            Antwort hinzufügen
          </button>
        </div>
      )

    default:
      return null
  }
}

export default QuestionTypeFields
