import React, { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { isTouchDevice } from '../../utils/utils'

import ImageQuestion from './ImageQuestions'
import TextQuestion from './TextQuestion'
import MultipleChoiceQuestion from './MultipleChoiceQuestion'
import LapQuestion from './LapQuestion'
import DndQuestion from './DndQuestion'
import FillInTheBlankQuestion from './FillInTheBlankQuestion'
import MultipleAnswers from './MultipleAnswers.js'

export const QuestionContainer = ({
  questionData,
  onAnswerSubmit,
  isWrong,
  onLapAnswerSubmit,
  isEvaluated,
  onDndAnswerSubmit,
  onFillInTheBlanksAnswerSubmit,
  handleNextQuestion,
  onMultipleAnswersSubmit,
  handleMultipleChoiceSubmit
}) => {
  const { type } = questionData

  const renderQuestion = () => {
    switch (type) {
      case 'image':
        return (
          <ImageQuestion
            data={questionData}
            onAnswerSubmit={onAnswerSubmit}
            handleNextQuestion={handleNextQuestion}
          />
        )
      case 'lap':
        return (
          <LapQuestion
            data={questionData}
            onAnswerSubmit={onLapAnswerSubmit}
            isWrong={isWrong}
            isEvaluated={isEvaluated}
            mode='learn'
            handleNextQuestion={handleNextQuestion}
          />
        )

      case 'text':
        return (
          <TextQuestion
            question={questionData}
            onAnswerSubmit={onAnswerSubmit}
            isWrong={isWrong}
            handleNextQuestion={handleNextQuestion}
          />
        )
      case 'multiple':
        return (
          <MultipleChoiceQuestion
            handleNextQuestion={handleNextQuestion}
            question={questionData}
            onAnswerSubmit={handleMultipleChoiceSubmit} // Richtig zuweisen
            isWrong={isWrong}
          />
        )

      case 'dnd':
        return (
          <DndProvider
            backend={isTouchDevice() ? TouchBackend : HTML5Backend}
            options={{ enableMouseEvents: true }} // Aktiviert Mouse-Events für TouchBackend
          >
            <DndQuestion
              data={questionData}
              onAnswerSubmit={onDndAnswerSubmit}
              isWrong={isWrong}
              handleNextQuestion={handleNextQuestion}
            />
          </DndProvider>
        )

      case 'fillInTheBlanks':
        return (
          <FillInTheBlankQuestion
            data={questionData}
            onAnswerSubmit={onFillInTheBlanksAnswerSubmit}
            handleNextQuestion={handleNextQuestion}
            isWrong={isWrong}
          />
        )

      case 'multipleAnswers':
        return (
          <MultipleAnswers
            question={questionData}
            onAnswerSubmit={onMultipleAnswersSubmit}
            isWrong={isWrong}
            handleNextQuestion={handleNextQuestion}
          />
        )

      default:
        return <p>Unbekannter Fragetyp</p>
    }
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {renderQuestion()}
    </div>
  )
}
