import React, { useEffect, useState } from 'react'
import './style/MultipleAnswers.css'

const MultipleAnswers = ({
  question,
  onAnswerSubmit,
  isWrong,
  handleNextQuestion
}) => {
  const [answers, setAnswers] = useState(['', '', ''])

  useEffect(() => {
    setAnswers(Array(question.answers.length).fill(''))
  }, [question])

  useEffect(() => {
    const handleEnterKey = event => {
      if (event.key === 'Enter') {
        event.preventDefault() // Prevent form from submitting by default
        if (!isWrong) {
          document.getElementById('submit-button').click() // Trigger submit button click
        } else {
          handleNextQuestion() // Go to the next question
        }
      }
    }

    // Add event listener for Enter key
    document.addEventListener('keydown', handleEnterKey)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEnterKey)
    }
  }, [isWrong, handleNextQuestion]) // Add dependencies for consistency

  // Handle changing an individual answer input
  const handleAnswerChange = (index, value) => {
    const updatedAnswers = [...answers]
    updatedAnswers[index] = value
    setAnswers(updatedAnswers)
  }

  const handleSubmit = e => {
    e.preventDefault()
    onAnswerSubmit(answers) // Pass all answers to the parent component
  }

  return (
    <div className='multipleTextAnswersQuestion'>
      <div className='questionAndNumberDiv'>
        <h4 className='questionTitle'>{question?.question}</h4>
        <p style={{ color: '#333333' }}>{question?.questionNumber}</p>
      </div>
      {/* Render the image if imageUrl exists */}
      {question?.imageUrl && (
        <div className='questionImageContainer'>
          <img
            src={question.imageUrl}
            alt='Question related'
            className='questionImage'
          />
        </div>
      )}

      <form
        onSubmit={handleSubmit}
        className='multipleTextAnswersForm'
        style={{ maxWidth: '600px' }}
      >
        {answers &&
          answers.map((answer, index) => (
            <div key={index} className='answerInputContainer'>
              <input
                type='text'
                id={`answer-${index + 1}`}
                value={answer}
                onChange={e => handleAnswerChange(index, e.target.value)}
                placeholder={`Antwort ${index + 1}`}
                required
                className='answerInput'
              />
            </div>
          ))}
        {isWrong && (
          <div className='correctAnswer'>
            <p>{question.question}</p>
            <strong>Richtige Antworten:</strong>
            {question.answers.map((answer, index) => (
              <p key={index}>{answer}</p>
            ))}
          </div>
        )}
        <button type='submit' id='submit-button' className='submitButton'>
          Weiter
        </button>
      </form>
    </div>
  )
}

export default MultipleAnswers
