import React from 'react'
import Dnd from './unterKomponenten/Dnd'
import Lap from './unterKomponenten/Lap'
import FillInTheBlanks from './unterKomponenten/FillInTheBlank'
import Multiple from './unterKomponenten/Multiple'
import Text from './unterKomponenten/Text'
import MultipleAnswers from './unterKomponenten/MultipleAnswers'

import './EditQuestionRenderComponent.css'

const EditQuestionRenderComponent = ({ question, onSubmit, onDelete }) => {
  let RenderedComponent

  switch (question.type) {
    case 'multiple':
      RenderedComponent = (
        <Multiple question={question} onSubmit={onSubmit} onDelete={onDelete} />
      )
      break
    case 'text': // corrected 'tect' to 'text'
      RenderedComponent = (
        <Text question={question} onSubmit={onSubmit} onDelete={onDelete} />
      )
      break
    case 'lap':
      RenderedComponent = (
        <Lap question={question} onSubmit={onSubmit} onDelete={onDelete} />
      )
      break
    case 'fillInTheBlanks':
      RenderedComponent = (
        <FillInTheBlanks
          question={question}
          onSubmit={onSubmit}
          onDelete={onDelete}
        />
      )
      break
    case 'dnd':
      RenderedComponent = (
        <Dnd question={question} onSubmit={onSubmit} onDelete={onDelete} />
      )
      break
    case 'multipleAnswers':
      RenderedComponent = (
        <MultipleAnswers
          question={question}
          onSubmit={onSubmit}
          onDelete={onDelete}
        />
      )
      break
    default:
      RenderedComponent = <div>Invalid question type</div>
  }

  return <div style={{ width: '100%' }}>{RenderedComponent}</div>
}

export default EditQuestionRenderComponent
