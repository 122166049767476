import React, { useEffect, useState } from 'react'
import { useData } from '../context/DataContext'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import './style/Categorie.css'

const Categorie = ({
  title,
  progressDocs,
  color,
  onStartCategory,
  handleCategorieClick,
  selectedCatalog,
  mode,
  wrongQuestionIds
}) => {
  const { questions } = useData() // Access questions from the context
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [progressPercentage, setProgressPercentage] = useState(0)

  useEffect(() => {
    // Filter questions to get the total number for this category
    const categoryQuestions = questions.filter(
      question => question.category === title
    )
    const totalQuestionsCount = categoryQuestions.length
    setTotalQuestions(totalQuestionsCount)

    // Filter progressDocs to include only those that match the IDs in categoryQuestions
    const progressDocsFiltered = progressDocs.filter(doc =>
      categoryQuestions.some(question => question.id === doc.questionId)
    )

    // Calculate the learning progress based on how many times each question was answered correctly
    let totalProgress = 0
    progressDocsFiltered.forEach(doc => {
      const timesRight = Math.min(doc.timesRight, 3) // Cap timesRight at 3
      const questionProgress = (timesRight / 3) * (100 / totalQuestionsCount)
      totalProgress += questionProgress
    })

    setProgressPercentage(totalProgress.toFixed(2)) // Set to 2 decimal places
  }, [title, questions, progressDocs])

  // Inline styles for the progress bar
  const progressBarContainerStyle = {
    height: '24px',
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: '12px',
    margin: '20px 0'
  }

  const progressBarStyle = {
    height: '100%',
    width: `${progressPercentage}%`,
    backgroundColor: progressPercentage >= 100 ? '#4caf50' : '#2196f3', // Green if 100%, blue otherwise
    borderRadius: '12px',
    textAlign: 'center',
    lineHeight: '24px',
    color: 'white',
    transition: 'width 0.4s ease'
  }

  return (
    <div className='categorie' style={{ backgroundColor: color }}>
      <div className='categorieStats'>
        <div className='categorieStatsText'>
          <h3>{title}</h3>
          {mode === 'wrongQuestions' ? (
            <p style={{ color: 'white' }}>Fragen: {wrongQuestionIds.length}</p>
          ) : (
            <p>Fragen: {totalQuestions}</p>
          )}
        </div>
        <div className='categorieStatsButton'>
          <button onClick={() => handleCategorieClick(title)}>
            <FormatListBulletedIcon sx={{ fontSize: '20px' }} />
          </button>
          <button onClick={() => onStartCategory(title)}>
            <PlayArrowIcon sx={{ fontSize: '30px' }} />
          </button>
        </div>
      </div>
      {mode !== 'wrongQuestions' && (
        <div style={progressBarContainerStyle}>
          <div style={progressBarStyle}>
            <p
              style={{
                marginLeft: '10px',
                marginTop: '0',
                marginBottom: '0',
                marginRight: '0',
                color: 'black'
              }}
            >
              {progressPercentage}%
            </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Categorie
