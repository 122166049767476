import React, { useState } from 'react'
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'

import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

const Lap = ({ question, onSubmit, onDelete }) => {
  const [heading, setHeading] = useState(question.heading || '')
  const [imageUrl, setImageUrl] = useState(question.imageUrl || '')
  const [task, setTask] = useState(question.task || '')
  const [themes, setThemes] = useState(question.themes || [])
  const [themeAnswers, setThemeAnswers] = useState(question.themeAnswers || [])
  const [imageFile, setImageFile] = useState(null)

  const storage = getStorage()

  const handleHeadingChange = e => setHeading(e.target.value)
  const handleTaskChange = e => setTask(e.target.value)

  const handleThemeChange = (index, value) => {
    const newThemes = [...themes]
    newThemes[index] = value
    setThemes(newThemes)
  }

  const handleThemeAnswerChange = (index, value) => {
    const newThemeAnswers = [...themeAnswers]
    newThemeAnswers[index] = value
    setThemeAnswers(newThemeAnswers)
  }

  const addTheme = () => {
    setThemes([...themes, ''])
    setThemeAnswers([...themeAnswers, ''])
  }

  const removeTheme = index => {
    const newThemes = [...themes]
    const newThemeAnswers = [...themeAnswers]
    newThemes.splice(index, 1)
    newThemeAnswers.splice(index, 1)
    setThemes(newThemes)
    setThemeAnswers(newThemeAnswers)
  }

  const handleImageChange = e => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0])
    }
  }

  const handleImageUpload = async () => {
    if (!imageFile) return

    const imageRef = ref(storage, `questions/${imageFile.name}`)
    await uploadBytes(imageRef, imageFile)
    const url = await getDownloadURL(imageRef)
    setImageUrl(url)
    setImageFile(null)
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (imageFile) {
      await handleImageUpload()
    }

    const updatedQuestion = {
      ...question,
      heading,
      imageUrl,
      task,
      themes,
      themeAnswers
    }

    if (onSubmit) {
      onSubmit(updatedQuestion)
    }
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(question.id)
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <label>Heading:</label>
        <input
          style={{
            marginBottom: '20px',
            backgroundColor: '#333',
            color: 'white'
          }}
          type='text'
          value={heading}
          onChange={handleHeadingChange}
          placeholder='Heading'
        />

        <label>Bild hochladen:</label>
        <input type='file' onChange={handleImageChange} />
        <button type='button' onClick={handleImageUpload}>
          Bild hochladen
        </button>

        {imageUrl && (
          <img
            src={imageUrl}
            alt='Preview'
            style={{ maxWidth: '100%', marginTop: '10px', maxHeight: '50vh' }}
          />
        )}

        <label style={{ marginTop: '1rem' }}>Task:</label>
        <input
          type='text'
          value={task}
          onChange={handleTaskChange}
          placeholder='Enter task description'
        />

        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <label>Themes and Answers:</label>
          {themes.map((theme, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <input
                type='text'
                value={theme}
                onChange={e => handleThemeChange(index, e.target.value)}
                placeholder={`Theme ${index + 1}`}
              />
              <input
                type='text'
                value={themeAnswers[index]}
                onChange={e => handleThemeAnswerChange(index, e.target.value)}
                placeholder={`Answer for Theme ${index + 1}`}
                style={{ marginLeft: '5px' }}
              />
              <button
                type='button'
                onClick={() => removeTheme(index)}
                style={{ marginLeft: '5px' }}
              >
                Löschen
              </button>
            </div>
          ))}
          <button
            style={{
              backgroundColor: 'green',
              width: 'auto',
              marginBottom: '2rem'
            }}
            type='button'
            onClick={addTheme}
          >
            <AddIcon style={{ fontSize: '1.5rem' }} />
          </button>
        </div>

        <input
          className='editQuestionKomponentSubmitButton'
          type='submit'
          value='Änderungen speichern'
          style={{ marginTop: '10px' }}
        />
      </form>
      <button
        className='deleteButtonEditQuestions'
        onClick={handleDelete}
        style={{ marginTop: '6px', width: '100%' }}
      >
        <DeleteIcon />
      </button>
    </div>
  )
}

export default Lap
