import React, { useEffect, useState } from 'react'
import { useUser } from '../context/UserContext'
import './styles/Dashboard.css'
import WhatshotIcon from '@mui/icons-material/Whatshot' // Feuer-Icon für Streak
import Stats from '../components/Stats'
import { db } from '../firebaseConfig'
import { doc, updateDoc } from 'firebase/firestore'
import { Link } from 'react-router-dom'

const Dashboard = selectedCatalog => {
  const { user, userData } = useUser()
  const [validDemos, setValidDemos] = useState([])

  const isValidCatalog = catalogId => {
    if (!userData) return false // Sicherheitscheck

    // Check if it's a valid regular catalog
    if (userData?.catalogs.includes(catalogId)) {
      return true
    }

    // Check if it's a valid demo catalog and the endDate hasn't passed
    const demoCatalog = userData?.demos.find(
      demo => demo.catalogId === catalogId
    )
    if (demoCatalog) {
      const currentDate = new Date()
      let endDate

      // Handle Firestore Timestamp or Date string
      if (
        demoCatalog.endDate &&
        typeof demoCatalog.endDate.toDate === 'function'
      ) {
        endDate = demoCatalog.endDate.toDate()
      } else {
        endDate = new Date(demoCatalog.endDate)
      }

      return currentDate < endDate
    }

    // If not found in catalogs or as a valid demo, return false
    return false
  }

  useEffect(() => {
    if (!userData || !userData.demos) {
      setValidDemos([]) // Falls keine Daten vorhanden sind, validDemos leeren
      return
    }

    const validDemoCatalogs = userData.demos
      .map(demo => demo.catalogId) // Extrahiere catalogId aus jedem Demo-Eintrag
      .filter(catalogId => isValidCatalog(catalogId)) // Überprüfe mit isValidCatalog

    setValidDemos(validDemoCatalogs) // Speichere die validen Catalog-IDs
  }, [userData, selectedCatalog])

  useEffect(() => {
    const checkAndResetStreak = async () => {
      if (!user || !userData?.lastLearned) return

      const lastLearnedDate = userData.lastLearned.toDate() // Konvertiere Timestamp in Date
      const yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0) // Setze auf Mitternacht

      if (lastLearnedDate < yesterday) {
        // Wenn lastLearned älter als gestern ist, streak auf 0 setzen
        try {
          const userRef = doc(db, 'users', user.uid) // Passe den Pfad zu deinem Firestore-Dokument an
          await updateDoc(userRef, {
            streak: 0
          })
        } catch (error) {
          console.error('Fehler beim Zurücksetzen der Streak:', error)
        }
      }
    }

    checkAndResetStreak()
  }, [user, userData])
  return (
    <div className='dashboard'>
      {user && (
        <div className='innerDashboard'>
          <h2 className='welcomeTitle'>
            Willkommen,{' '}
            <span style={{ color: '#ff7e5f' }}>{userData?.firstName}</span>{' '}
            {userData?.lastName}!
          </h2>
          {userData?.catalogs?.length > 0 || validDemos.length > 0 ? (
            <>
              {/* Streak Display */}
              <div className='streakContainer'>
                <WhatshotIcon className='streakIcon' />
                <p className='streakText'>
                  {userData?.streak || 0} Day{userData?.streak === 1 ? '' : 's'}{' '}
                  Streak
                </p>
              </div>
              <Stats userId={user.uid} selectedCatalog={selectedCatalog} />
            </>
          ) : (
            <div className='membershipPrompt'>
              <h3>
                Entdecken Sie die Kataloge die{' '}
                <span
                  style={{
                    color: '#ff7e5f',
                    fontWeight: 'bold',
                    fontSize: '1.4rem'
                  }}
                >
                  StudyBox
                </span>{' '}
                zu bieten hat!
              </h3>
              <button className='addCatalogButton'>
                <a href='/addCatalog'>Kataloge entdecken</a>
              </button>
            </div>
          )}
        </div>
      )}
      {user && (
        <Link to={'/addCatalog'} className='addCatalogDashboardButton'>
          Katalog hinzufügen!
        </Link>
      )}
    </div>
  )
}

// Separate component for each benefit item

export default Dashboard
