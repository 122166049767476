import React, { useEffect, useState } from 'react'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebaseConfig'
import CatalogDetail from '../components/CatalogDetail'
import CreateCatalog from '../components/CreateCatalog'
import EditCatalog from '../components/EditCatalog'
import './AddCatalogPage.css'
import { useUser } from '../context/UserContext'

const AddCatalogPage = () => {
  const [catalogs, setCatalogs] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedCatalog, setSelectedCatalog] = useState(null)
  const { user, userData } = useUser()
  const [createCatalog, setCreateCatalog] = useState(false)
  const [editCatalog, setEditCatalog] = useState(false)

  const [myCatalogs, setMyCatalogs] = useState([])
  const [purchasedCatalogs, setPurchasedCatalogs] = useState([])
  const [demoCatalogs, setDemoCatalogs] = useState([])
  const [nonPurchasedCatalogs, setNonPurchasedCatalogs] = useState([])

  const [catalogReviews, setCatalogReviews] = useState({})
  const [catalogRatings, setCatalogRatings] = useState({})

  const fetchCatalogReviews = async catalogs => {
    const reviewsData = {}
    const ratingsData = {}

    for (const catalog of catalogs) {
      try {
        const reviewsCollectionRef = collection(
          db,
          'questions',
          catalog.id,
          'reviews'
        )
        const reviewsSnapshot = await getDocs(reviewsCollectionRef)

        const reviews = reviewsSnapshot.docs.map(doc => doc.data())
        reviewsData[catalog.id] = reviews

        // Durchschnitt berechnen
        if (reviews.length > 0) {
          const totalRating = reviews.reduce(
            (acc, review) => acc + review.rating,
            0
          )
          ratingsData[catalog.id] = (totalRating / reviews.length).toFixed(1)
        } else {
          ratingsData[catalog.id] = 0
        }
      } catch (error) {
        console.error(
          `Fehler beim Abrufen der Bewertungen für ${catalog.id}:`,
          error
        )
        reviewsData[catalog.id] = []
        ratingsData[catalog.id] = 0
      }
    }

    setCatalogReviews(reviewsData)
    setCatalogRatings(ratingsData)
  }

  useEffect(() => {
    const fetchCatalogs = async () => {
      try {
        const catalogCollection = collection(db, 'questions')
        const catalogSnapshot = await getDocs(catalogCollection)
        const catalogList = catalogSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        setCatalogs(catalogList)
        await fetchCatalogReviews(catalogList)
      } catch (error) {
        console.error('Error fetching catalogs:', error)
      }
    }
    fetchCatalogs()
  }, [])

  const isValidCatalog = catalogId => {
    // Check if it's a valid regular catalog
    if (userData?.catalogs?.includes(catalogId)) {
      return true
    }

    // Check if it's a valid demo catalog and the endDate hasn't passed
    const demoCatalog = userData?.demos?.find(
      demo => demo.catalogId === catalogId
    )
    if (demoCatalog) {
      const currentDate = new Date()
      let endDate

      // Handle Firestore Timestamp or Date string
      if (
        demoCatalog.endDate &&
        typeof demoCatalog.endDate.toDate === 'function'
      ) {
        endDate = demoCatalog.endDate.toDate()
      } else {
        endDate = new Date(demoCatalog.endDate)
      }

      return currentDate < endDate
    }

    // If not found in catalogs or as a valid demo, return false
    return false
  }

  useEffect(() => {
    const distributeCatalogs = () => {
      const myCatalogs = []
      const purchased = []
      const demo = []
      const nonPurchased = []

      catalogs.forEach(catalog => {
        const isPurchased = userData?.catalogs?.includes(catalog.id)
        const catalogValid = isValidCatalog(catalog.id)
        const isDemo = userData?.demos?.find(
          demo => demo.catalogId === catalog.id
        )
        const isMine = catalog.creator === user.uid
        const isPublic = catalog.public

        if (isMine || isPublic || isPurchased) {
          // If it's the user's catalog, add it to myCatalogs regardless of public status
          if (isMine) {
            myCatalogs.push(catalog)
          } else if (isPurchased) {
            purchased.push(catalog)
          } else if (isDemo && catalogValid) {
            demo.push(catalog)
          } else {
            nonPurchased.push(catalog)
          }
        }
      })

      setMyCatalogs(myCatalogs)
      setPurchasedCatalogs(purchased)
      setDemoCatalogs(demo)
      setNonPurchasedCatalogs(nonPurchased)
    }

    distributeCatalogs()
  }, [catalogs, userData, user])

  const handleCatalogClick = catalog => {
    setSelectedCatalog(catalog)
  }

  const handleEditCatalog = catalog => {
    setEditCatalog(catalog)
  }

  const filteredCatalogs = list =>
    list.filter(catalog =>
      catalog.name.toLowerCase().includes(searchTerm.toLowerCase())
    )

  return (
    <>
      {selectedCatalog ? (
        <>
          <CatalogDetail catalog={selectedCatalog} />
          <button
            className='backButtonCatalog'
            onClick={() => setSelectedCatalog(null)}
          >
            Zurück
          </button>
        </>
      ) : createCatalog ? (
        <CreateCatalog />
      ) : editCatalog ? (
        <EditCatalog catalogId={editCatalog} />
      ) : (
        <div className='catalogPage'>
          <h2>Durchsuche unsere Kataloge</h2>
          <div className='searchField'>
            <input
              type='text'
              placeholder='Kataloge...'
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>

          <div className='catalogList'>
            {filteredCatalogs(nonPurchasedCatalogs).map(catalog => (
              <div
                key={catalog.id}
                className='catalogCard'
                onClick={() => handleCatalogClick(catalog)}
              >
                <p className='priceTag'>
                  {catalog.price || catalog.price > 0
                    ? catalog.price + ' €'
                    : 'Kostenlos'}
                </p>
                <h3>{catalog.name}</h3>
                <p className='description'>{catalog.description}</p>
                <div className='review'>
                  {catalogRatings[catalog.id] > 0 ? (
                    <>
                      <span>⭐</span> {catalogRatings[catalog.id]} / 5{' '}
                      <span style={{ color: 'gray', fontSize: '0.8rem' }}>
                        ({catalogReviews[catalog.id]?.length || 0})
                      </span>
                    </>
                  ) : (
                    <p>Keine Bewertungen</p>
                  )}
                </div>
              </div>
            ))}
          </div>

          {myCatalogs.length > 0 && (
            <div className='purchasedCatalogs'>
              <h3>Deine Kataloge</h3>
              <div className='purchasedCatalogList'>
                {filteredCatalogs(myCatalogs).map(catalog => (
                  <div
                    style={{ backgroundColor: '#ff8a65' }}
                    onClick={() => handleEditCatalog(catalog.id)}
                    key={catalog.id}
                    className='purchasedCatalogCard'
                  >
                    <h4>{catalog.name}</h4>
                  </div>
                ))}
              </div>
            </div>
          )}

          {purchasedCatalogs.length > 0 && (
            <div className='purchasedCatalogs'>
              <h3>Gekaufte Kataloge</h3>
              <div className='purchasedCatalogList'>
                {filteredCatalogs(purchasedCatalogs).map(catalog => (
                  <div
                    onClick={() => handleCatalogClick(catalog)}
                    key={catalog.id}
                    className='purchasedCatalogCard'
                  >
                    <h4>{catalog.name}</h4>
                  </div>
                ))}
              </div>
            </div>
          )}

          {demoCatalogs.length > 0 && (
            <div className='demoCatalogs'>
              <h3>Demo Kataloge</h3>
              <div className='purchasedCatalogList'>
                {filteredCatalogs(demoCatalogs).map(catalog => (
                  <div
                    style={{
                      backgroundColor: '#333'
                    }}
                    key={catalog.id}
                    className='purchasedCatalogCard'
                    onClick={() => handleCatalogClick(catalog)}
                  >
                    <h5 style={{ color: 'seashell' }}>{catalog.name}</h5>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {!selectedCatalog && !createCatalog && !editCatalog && (
        <button
          className='backButtonCatalog'
          onClick={() => setCreateCatalog(!createCatalog)}
        >
          Katalog erstellen
        </button>
      )}

      {!selectedCatalog && (createCatalog || editCatalog) && (
        <button
          className='backButtonCatalog'
          onClick={
            createCatalog
              ? () => setCreateCatalog(false)
              : () => setEditCatalog(null)
          }
        >
          Zurück
        </button>
      )}
    </>
  )
}

export default AddCatalogPage
