import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import Login from './pages/Login'
import Register from './pages/Register'
import Dashboard from './pages/Dashboard'
import LandingPage from './pages/LandingPage'
import Profile from './pages/Profile'
import Questions from './pages/Questions'
import Learn from './pages/Learn'
import AddCatalogPage from './pages/AddCatalogPage'
import Test from './pages/Test'
import Navbar from './components/Navbar'
import { useUser } from './context/UserContext'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth, db } from './firebaseConfig'
import { doc, updateDoc, getDoc } from 'firebase/firestore'

import './App.css'
import ChatPage from './pages/ChatPage'
import ChatSelectionPage from './pages/ChatSelectionPage'

function App ({ onCatalogChange, setUserId }) {
  const [user] = useAuthState(auth)
  const { userData } = useUser()
  const [selectedCatalog, setSelectedCatalog] = useState('')
  const [admin, setAdmin] = useState([]) // Initialize as an array

  useEffect(() => {
    if (onCatalogChange) {
      onCatalogChange(selectedCatalog) // Callback aufrufen, wenn `selectedCatalog` sich ändert
    }
  }, [selectedCatalog, onCatalogChange])

  // Überprüfen, ob `user` definiert ist, bevor `userId` gesetzt wird
  useEffect(() => {
    if (user?.uid) {
      setUserId(user.uid)
    }
  }, [setUserId, user])

  useEffect(() => {
    if (!user) {
      setSelectedCatalog('')
      localStorage.removeItem('selectedCatalog')
    }
  }, [user])

  const isValidCatalog = catalogId => {
    if (!userData) return false // Sicherheitscheck

    // Check if it's a valid regular catalog
    if (userData?.catalogs?.includes(catalogId)) {
      return true
    }

    // Check if it's a valid demo catalog and the endDate hasn't passed
    const demoCatalog = userData?.demos?.find(
      demo => demo.catalogId === catalogId
    )
    if (demoCatalog) {
      const currentDate = new Date()
      let endDate

      // Handle Firestore Timestamp or Date string
      if (
        demoCatalog.endDate &&
        typeof demoCatalog.endDate.toDate === 'function'
      ) {
        endDate = demoCatalog.endDate.toDate()
      } else {
        endDate = new Date(demoCatalog.endDate)
      }

      return currentDate < endDate
    }

    // If not found in catalogs or as a valid demo, return false
    return false
  }

  // Set selectedCatalog based on lastCatalog or localStorage, ensuring it's valid
  useEffect(() => {
    const checkAndSetCatalog = async () => {
      const savedCatalog = localStorage.getItem('selectedCatalog')
      const lastCatalog = savedCatalog || userData?.lastCatalog || ''

      // Check if the last catalog is valid and belongs to the user
      if (
        userData?.catalogs?.includes(lastCatalog) ||
        isValidCatalog(lastCatalog)
      ) {
        setSelectedCatalog(lastCatalog)
      } else if (userData?.catalogs?.length > 0) {
        // Set to the first available catalog if the saved one is invalid or demo expired
        const firstAvailableCatalog = userData.catalogs[0]
        setSelectedCatalog(firstAvailableCatalog)
        localStorage.setItem('selectedCatalog', firstAvailableCatalog)
      } else {
        // No valid catalog; clear localStorage and set selectedCatalog to empty
        setSelectedCatalog('')
        localStorage.removeItem('selectedCatalog')
      }
    }

    if (userData) {
      checkAndSetCatalog()
    }
  }, [userData, isValidCatalog])

  // Update Firestore with selectedCatalog when it changes
  useEffect(() => {
    if (user?.uid && selectedCatalog) {
      const updateLastCatalog = async () => {
        try {
          const userDocRef = doc(db, 'users', user.uid)
          await updateDoc(userDocRef, { lastCatalog: selectedCatalog })
        } catch (error) {
          console.error('Error updating lastCatalog:', error)
        }
      }
      updateLastCatalog()
    }
  }, [selectedCatalog, user?.uid])

  // Fetch admin permissions for selectedCatalog
  useEffect(() => {
    const fetchCatalogData = async () => {
      if (selectedCatalog) {
        try {
          const catalogRef = doc(db, 'questions', selectedCatalog)
          const catalogSnap = await getDoc(catalogRef)

          if (catalogSnap.exists()) {
            const adminIDs = catalogSnap.data().admin || []
            setAdmin(adminIDs)
          } else {
            console.log('No such document!')
          }
        } catch (e) {
          console.log('Error:', e)
        }
      }
    }

    fetchCatalogData()
  }, [selectedCatalog])

  return (
    <Router>
      {user && (
        <Navbar
          setSelectedCatalog={setSelectedCatalog}
          selectedCatalog={selectedCatalog}
        />
      )}
      <Routes>
        <Route
          path='/'
          element={user ? <Navigate to='/dashboard' /> : <LandingPage />}
        />
        <Route
          path='/login'
          element={user ? <Navigate to='/dashboard' /> : <Login />}
        />
        <Route
          path='/register'
          element={user ? <Navigate to='/dashboard' /> : <Register />}
        />
        <Route
          path='/dashboard'
          element={
            user ? (
              <Dashboard selectedCatalog={selectedCatalog} />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route
          path='/profile'
          element={
            user ? (
              <Profile selectedCatalog={selectedCatalog} />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        <Route
          path='/addCatalog'
          element={
            user ? (
              <AddCatalogPage selectedCatalog={selectedCatalog} />
            ) : (
              <Navigate to='/login' />
            )
          }
        />
        {/* Route for Learn */}
        <Route
          path='/learn'
          element={
            user &&
            (userData?.catalogs?.includes(selectedCatalog) ||
              admin.includes(user.uid) ||
              isValidCatalog(selectedCatalog)) ? (
              <Learn selectedCatalog={selectedCatalog} />
            ) : (
              <Navigate to='/dashboard' />
            )
          }
        />
        <Route
          path='/test'
          element={
            user &&
            (userData?.catalogs?.includes(selectedCatalog) ||
              admin.includes(user.uid) ||
              isValidCatalog(selectedCatalog)) ? (
              <Test selectedCatalog={selectedCatalog} />
            ) : (
              <Navigate to='/dashboard' />
            )
          }
        />
        {/* Route for Questions */}
        <Route
          path='/questions'
          element={
            user && admin.includes(user.uid) ? (
              <Questions selectedCatalog={selectedCatalog} />
            ) : (
              <Navigate to='/dashboard' />
            )
          }
        />
        <Route
          path='/chat/:chatId'
          element={user ? <ChatPage /> : <Navigate to='/dashboard' />}
        />
        <Route
          path='/chats'
          element={
            user &&
            (userData?.catalogs?.includes(selectedCatalog) ||
              admin.includes(user.uid) ||
              isValidCatalog(selectedCatalog)) ? (
              <ChatSelectionPage />
            ) : (
              <Navigate to='/dashboard' />
            )
          }
        />
      </Routes>
    </Router>
  )
}

export default App
