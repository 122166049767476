import React, { useState, useEffect } from 'react'
import { useUser } from '../context/UserContext'
import { db } from '../firebaseConfig'
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc
} from 'firebase/firestore'
import { Link, useParams } from 'react-router-dom'
import './ChatSelectionPage.css'
import { format } from 'date-fns'
import { de } from 'date-fns/locale'

const ChatSelectionPage = () => {
  const { user } = useUser()
  const [chats, setChats] = useState([])
  const [catalogNames, setCatalogNames] = useState({})
  const [userNames, setUserNames] = useState({})
  const { chatId } = useParams()

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const chatsRef = collection(db, 'chats')
        const q = query(
          chatsRef,
          where('participants', 'array-contains', user.uid)
        )

        const querySnapshot = await getDocs(q)
        const userChats = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }))

        // Sortiere die Chats nach dem lastMessage-Timestamp in absteigender Reihenfolge
        userChats.sort((a, b) => {
          const aTime = a.lastMessage ? a.lastMessage.toMillis() : 0
          const bTime = b.lastMessage ? b.lastMessage.toMillis() : 0
          return bTime - aTime
        })

        setChats(userChats)
        await fetchCatalogAndUserNames(userChats)
      } catch (error) {
        console.error('Fehler beim Abrufen der Chats:', error)
      }
    }

    const fetchCatalogAndUserNames = async chats => {
      const catalogPromises = []
      const userPromises = []

      chats.forEach(chat => {
        if (chat.catalogId && !catalogNames[chat.catalogId]) {
          catalogPromises.push(fetchCatalogName(chat.catalogId))
        }
        chat.participants.forEach(participantId => {
          if (participantId !== user.uid && !userNames[participantId]) {
            userPromises.push(fetchUserName(participantId))
          }
        })
      })

      const catalogResults = await Promise.all(catalogPromises)
      const userResults = await Promise.all(userPromises)

      setCatalogNames(prev => ({
        ...prev,
        ...Object.fromEntries(catalogResults)
      }))

      setUserNames(prev => ({
        ...prev,
        ...Object.fromEntries(userResults)
      }))
    }

    fetchChats()
  }, [user.uid, catalogNames, userNames])

  const fetchCatalogName = async catalogId => {
    try {
      const catalogDoc = await getDoc(doc(db, 'questions', catalogId))
      return [catalogId, catalogDoc.data().name]
    } catch (error) {
      console.error('Error fetching catalog name:', error)
      return [catalogId, 'Katalog nicht gefunden']
    }
  }

  const fetchUserName = async userId => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId))
      return [userId, userDoc.data().username]
    } catch (error) {
      console.error('Error fetching user name:', error)
      return [userId, 'Benutzer nicht gefunden']
    }
  }
  const getFormattedDate = date => {
    const now = new Date()
    const inputDate = date.toDate()

    // Check if the date is today
    if (
      inputDate.getDate() === now.getDate() &&
      inputDate.getMonth() === now.getMonth() &&
      inputDate.getFullYear() === now.getFullYear()
    ) {
      return format(inputDate, 'HH:mm', { locale: de })
    }

    // Check if the date is yesterday
    const yesterday = new Date(now)
    yesterday.setDate(yesterday.getDate() - 1)
    if (
      inputDate.getDate() === yesterday.getDate() &&
      inputDate.getMonth() === yesterday.getMonth() &&
      inputDate.getFullYear() === yesterday.getFullYear()
    ) {
      return 'Gestern'
    }

    // Otherwise, return only the date
    return format(inputDate, 'dd.MM.yyyy', { locale: de })
  }

  return (
    <div className='chat-selection-page'>
      <h2 style={{ marginTop: '3rem' }}>Deine Chats</h2>
      <ul className='chat-list'>
        {chats.length === 0 && (
          <>
            <p style={{ color: '#333' }}>Keine Chats vorhanden</p>
            <button style={{ marginTop: '0rem' }} className='addCatalogButton'>
              <a href='/addCatalog'>Kataloge entdecken</a>
            </button>
          </>
        )}
        {chats.map(chat => (
          <div
            key={chat.id}
            className='chat-item'
            style={{
              backgroundColor: chatId === chat.id && '#333'
            }}
          >
            <Link to={`/chat/${chat.id}`} className='chat-link'>
              <div className='chat-info'>
                <p
                  className='chat-catalog'
                  style={{ color: chatId === chat.id && 'white' }}
                >
                  {chat.participants
                    .filter(participantId => participantId !== user.uid)
                    .map(participantId => userNames[participantId] || 'Lädt...')
                    .join(', ')}
                </p>
                <p className='chat-participants'>
                  {chat.catalogId
                    ? `${catalogNames[chat.catalogId] || 'Lädt...'}`
                    : 'Allgemeiner Chat'}
                </p>
                <p className='chat-last-message'>
                  {chat.lastMessage
                    ? getFormattedDate(chat.lastMessage)
                    : 'Keine Nachrichten'}
                </p>
              </div>
              {user.uid === chat.seller.id && chat.seller.read === false ? (
                <div className='unreadBox'></div>
              ) : null}
              {user.uid === chat.buyer.id && chat.buyer.read === false ? (
                <div className='unreadBox'></div>
              ) : null}
            </Link>
          </div>
        ))}
      </ul>
    </div>
  )
}

export default ChatSelectionPage
