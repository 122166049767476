import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

const FillInTheBlanks = ({ question, onSubmit, onDelete }) => {
  const [fillInTheBlanksQuestion, setFillInTheBlanksQuestion] = useState(
    question.fillInTheBlanksQuestion
  )
  const [selectedBlanks, setSelectedBlanks] = useState(
    question.selectedBlanks || []
  )

  const handleQuestionChange = e => {
    setFillInTheBlanksQuestion(e.target.value)
  }

  const toggleBlankIndex = index => {
    // Füge den Index zu `selectedBlanks` hinzu oder entferne ihn, wenn er schon existiert
    setSelectedBlanks(prevSelectedBlanks =>
      prevSelectedBlanks.includes(index)
        ? prevSelectedBlanks.filter(i => i !== index)
        : [...prevSelectedBlanks, index]
    )
  }

  const handleSubmit = e => {
    e.preventDefault()
    const updatedQuestion = {
      ...question,
      fillInTheBlanksQuestion,
      selectedBlanks
    }

    if (onSubmit) {
      onSubmit(updatedQuestion)
    }
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(question.id)
    }
  }

  // Satz in Wörter aufteilen, um jedes Wort separat anzuzeigen
  const words = fillInTheBlanksQuestion.split(' ')

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <label>Frage:</label>
        <input
          type='text'
          value={fillInTheBlanksQuestion}
          onChange={handleQuestionChange}
          placeholder='Geben Sie den vollständigen Satz ein'
        />

        <div style={{ marginTop: '10px' }}>
          <label>Wählen Sie die Lücken:</label>
          <div style={{ display: 'flex', gap: '5px', flexWrap: 'wrap' }}>
            {words.map((word, index) => (
              <div
                key={index}
                onClick={() => toggleBlankIndex(index)}
                style={{
                  padding: '5px 10px',
                  backgroundColor: selectedBlanks.includes(index)
                    ? '#333'
                    : '#ddd',
                  color: selectedBlanks.includes(index) ? '#fff' : '#000',
                  cursor: 'pointer',
                  borderRadius: '4px'
                }}
              >
                {word}
              </div>
            ))}
          </div>
        </div>

        <div style={{ marginTop: '10px' }}>
          <label>Vorschau der Frage:</label>
          <p>
            {words.map((word, index) =>
              selectedBlanks.includes(index) ? ' ___ ' : ` ${word} `
            )}
          </p>
        </div>

        <input
          className='editQuestionKomponentSubmitButton'
          type='submit'
          value='Änderungen speichern'
          style={{ marginTop: '10px' }}
        />
      </form>
      <button
        className='deleteButtonEditQuestions'
        onClick={handleDelete}
        style={{ marginTop: '6px', width: '100%' }}
      >
        <DeleteIcon />
      </button>
    </div>
  )
}

export default FillInTheBlanks
