import React, { useState } from 'react'
import { useData } from '../../context/DataContext' // Import useData for context access
import './Select.css'

const Select = ({ onSelect }) => {
  const [selectedChapters, setSelectedChapters] = useState([]) // Selected chapters by name
  const [questionsPerChapter, setQuestionsPerChapter] = useState(5)
  const { categories, progress, questions } = useData() // Access categories and progress from context

  const handleCheckboxChange = chapterName => {
    // Toggle Chapter in selectedChapters
    if (selectedChapters.includes(chapterName)) {
      setSelectedChapters(prev => prev.filter(name => name !== chapterName))
    } else {
      setSelectedChapters(prev => [...prev, chapterName])
    }
  }

  const handleSelectAll = () => {
    const allChapters = categories.map(category => category.name)
    setSelectedChapters(allChapters)
    onSelect(allChapters)
  }

  const handleSubmit = () => {
    onSelect(selectedChapters, questionsPerChapter) // Pass questions per chapter
  }

  const calculateProgressForChapter = chapterName => {
    // Filter questions for the specified chapter
    const chapterQuestions = questions.filter(
      question => question.category === chapterName
    )
    const totalQuestions = chapterQuestions.length

    if (totalQuestions === 0) return 0

    // Filter progress data for the specified chapter and only include relevant questions
    const progressDocs = progress.filter(doc =>
      chapterQuestions.some(question => question.id === doc.questionId)
    )

    if (progressDocs.length === 0) return 0

    let totalProgress = 0
    progressDocs.forEach(doc => {
      const timesRight = Math.min(doc.timesRight, 3) // Cap timesRight at 3
      totalProgress += (timesRight / 3) * (100 / totalQuestions)
    })

    const progressPercentage = totalProgress.toFixed(2)
    const cappedProgressPercentage = Math.min(progressPercentage, 100)

    return cappedProgressPercentage // Return progress as percentage with 2 decimal places
  }

  const getTotalQuestionsForChapter = chapterName => {
    const totalQuestions = questions.filter(
      question => question.category === chapterName
    ).length

    return totalQuestions
  }

  const progressBarContainerStyle = {
    height: '24px',
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: '12px',
    margin: '10px 0'
  }

  const getProgressBarStyle = progressPercentage => ({
    height: '100%',
    width: `${progressPercentage}%`,
    backgroundColor: progressPercentage >= 100 ? '#4caf50' : '#2196f3', // Green if 100%, blue otherwise
    borderRadius: '12px',
    textAlign: 'center',
    lineHeight: '24px',
    color: 'white',
    transition: 'width 0.4s ease'
  })

  const handleQuestionsPerChapterChange = value => {
    if (value === '' || value === null) {
      setQuestionsPerChapter('') // Allow empty input field
    } else {
      const parsedValue = parseInt(value)
      if (!isNaN(parsedValue) && parsedValue > 0) {
        setQuestionsPerChapter(parsedValue) // Only allow valid numbers
      } else {
        setQuestionsPerChapter(1) // Set default value if invalid
      }
    }
  }

  return (
    <div className='select-container'>
      <h2>Wähle Kapitel</h2>
      <div>
        <div className='input-container'>
          <label>Fragen/Kapitel:</label>
          <input
            type='number'
            value={questionsPerChapter}
            onChange={e => handleQuestionsPerChapterChange(e.target.value)}
            step='1' // Prevents decimal values
            min='1' // Optional: Prevents values below 1
          />
        </div>
      </div>
      <div className='checkbox-list'>
        {categories
          .sort((a, b) => a.name.localeCompare(b.name)) // Sortiere nach dem 'name'-Feld
          .filter(chapter => getTotalQuestionsForChapter(chapter.name) > 0) // Nur Kapitel mit Fragen
          .map(chapter => {
            const progressPercentage = calculateProgressForChapter(chapter.name)

            return (
              <div
                key={chapter.name}
                style={{ boxShadow: `0 0 5px ${chapter.color}` }}
                className={`checkbox-item ${
                  selectedChapters.includes(chapter.name) ? 'selected' : ''
                }`} // Apply class if selected
                onClick={() => handleCheckboxChange(chapter.name)} // Toggle selection
              >
                {chapter.name}

                <div
                  className={`checkedBox ${
                    selectedChapters.includes(chapter.name) ? 'checked' : ''
                  }`} // Add class 'checked' if selected
                >
                  {selectedChapters.includes(chapter.name) && (
                    <p style={{ color: 'black' }}>X</p>
                  )}
                </div>

                {/* Progress Bar */}
                <div style={progressBarContainerStyle}>
                  <div style={getProgressBarStyle(progressPercentage)}>
                    <p
                      style={{
                        marginLeft: '10px',
                        marginTop: '0',
                        marginBottom: '0',
                        marginRight: '0',
                        color: 'black'
                      }}
                    >
                      {progressPercentage}%
                    </p>
                  </div>
                </div>
              </div>
            )
          })}
      </div>

      <div className='select-buttons'>
        <button onClick={handleSelectAll} className='button select-all-button'>
          Alle Kapitel auswählen
        </button>
        <button onClick={handleSubmit} className='button submit-button'>
          Auswahl bestätigen
        </button>
      </div>
    </div>
  )
}

export default Select
