import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'

const EditableMultipleAnswers = ({ question, onSubmit, onDelete }) => {
  const [questionText, setQuestionText] = useState(question.question || '')
  const [answers, setAnswers] = useState(question.answers || ['']) // Initialize with an empty field if no answers
  const [correctAnswers, setCorrectAnswers] = useState(
    question.correctAnswers || []
  ) // Store multiple correct answers

  // Update question text
  const handleQuestionChange = e => {
    setQuestionText(e.target.value)
  }

  // Update individual answer text
  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers]
    newAnswers[index] = value
    setAnswers(newAnswers)
  }

  // Add a new answer field
  const handleAddAnswer = () => {
    setAnswers([...answers, '']) // Add an empty answer field
  }

  // Remove an answer field
  const handleRemoveAnswer = index => {
    setAnswers(answers.filter((_, i) => i !== index)) // Remove answer at the given index
    setCorrectAnswers(
      correctAnswers.filter(answerIndex => answerIndex !== index)
    ) // Adjust correct answers
  }

  // Submit the edited question
  const handleSubmit = e => {
    e.preventDefault()
    const updatedQuestion = {
      ...question,
      question: questionText,
      answers: answers
    }
    if (onSubmit) {
      onSubmit(updatedQuestion)
    }
  }

  // Delete the question
  const handleDelete = () => {
    if (onDelete) {
      onDelete(question.id)
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <input
          style={{
            marginBottom: '20px',
            backgroundColor: '#333',
            color: 'white',
            width: '100%',
            padding: '8px',
            borderRadius: '5px'
          }}
          type='text'
          value={questionText}
          onChange={handleQuestionChange}
          placeholder='Edit the question text'
        />

        {answers.map((answer, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '8px'
            }}
          >
            <input
              type='text'
              value={answer}
              onChange={e => handleAnswerChange(index, e.target.value)}
              placeholder={`Edit answer ${index + 1}`}
              style={{ width: '80%', padding: '6px', borderRadius: '4px' }}
            />

            {answers.length > 1 && (
              <button
                type='button'
                onClick={() => handleRemoveAnswer(index)}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                  width: 'auto'
                }}
              >
                <DeleteIcon style={{ color: 'red' }} />
              </button>
            )}
          </div>
        ))}

        <button
          type='button'
          onClick={handleAddAnswer}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
            background: 'none',
            border: 'none',
            color: '#333',
            cursor: 'pointer',
            marginBottom: '10px'
          }}
        >
          <AddIcon /> Add Answer
        </button>

        <button
          type='submit'
          style={{
            width: '100%',
            padding: '10px',
            backgroundColor: '#333',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontWeight: 'bold'
          }}
        >
          Änderungen speichern
        </button>
      </form>

      <button
        style={{
          marginTop: '6px',
          width: '100%',
          padding: '10px',
          backgroundColor: 'red',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        onClick={handleDelete}
      >
        <DeleteIcon style={{ marginRight: '5px' }} />
      </button>
    </div>
  )
}

export default EditableMultipleAnswers
