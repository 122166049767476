import React, { useEffect, useState } from 'react'

import { useUser } from '../context/UserContext'
import { useData } from '../context/DataContext'
import FeedbackDisplay from './categoryContent/FeedbackDisplay'
import { saveProgress } from '../utils/progressUtils'
import { handleProgressDecrease } from '../utils/progressUtils'
import { handleResetProgress } from '../utils/categoryUtils'
import { updateStreak } from '../utils/categoryUtils'

import { isSimilar } from '../utils/categoryUtils'
import { QuestionContainer } from './questionsComponents/QuestionContainer'

import CorrectSound from '../assets/soundEffects/correct.mp3'

import './style/CategoryContent.css'
import './style/progressBar.css'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

const CategoryContent = ({
  categoryName,
  selectedCatalog,
  setSelectedCategory
}) => {
  const [questionsToAsk, setQuestionsToAsk] = useState([])
  const [questionsToAskOrderd, setQuestionsToAskOrderd] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [allAnswered, setAllAnswered] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [streak, setStreak] = useState(0)
  const [progressFull, setProgressFull] = useState(false)
  const [categoryColor, setCategoryColor] = useState('#f5f5f5')
  const [questionsRight, setQuestionsRight] = useState(0)
  const [questionsWrong, setQuestionsWrong] = useState(0)
  const [questionProgress, setQuestionProgress] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [questionIsWrong, setQuestionIsWrong] = useState(false)
  const [isEvaluated, setIsEvaluated] = useState(false)
  const { user } = useUser()
  const [element, setElement] = useState(null)
  const { categories, questions, progress, wrongQuestions } = useData() // Access data from context

  const correctAnswerSound = new Audio(CorrectSound) // Initialize audio

  useEffect(() => {
    const totalQuestions = questionsToAsk?.length
    const totalAnswered = questionsRight + questionsWrong

    if (totalQuestions > 0) {
      setQuestionProgress(Math.round((totalAnswered / totalQuestions) * 100))
    } else {
      setQuestionProgress(0)
    }
  }, [questionsToAsk, questionsRight, questionsWrong, questionProgress])

  useEffect(() => {
    // Filter questions belonging to the current category
    if (categoryName !== 'Falsche Fragen') {
      const categoryQuestions = questions.filter(
        question => question.category === categoryName
      )

      // Map progress data for easier access
      const progressData = progress.reduce((acc, item) => {
        acc[item.id] = item.timesRight || 0
        return acc
      }, {})

      // Filter out questions that have been answered less than 3 times correctly
      const unansweredQuestions = categoryQuestions.filter(
        question => (progressData[question.id] || 0) < 3
      )
      setQuestionsToAskOrderd(unansweredQuestions)

      // Check if all questions are answered 3 times
      const allAnswered = categoryQuestions.every(
        question => (progressData[question.id] || 0) >= 3
      )

      setProgressFull(allAnswered)
    } else {
      // Display wrong questions
      const wrongQuestionIds = wrongQuestions.map(
        question => question.questionId
      )

      const categoryQuestions = questions.filter(question =>
        wrongQuestionIds.includes(question.id)
      )

      setQuestionsToAskOrderd(categoryQuestions)
    }
  }, [questions, progress, categoryName, wrongQuestions])

  useEffect(() => {
    // Find the category and get its color
    const category = categories.find(cat => cat.name === categoryName)
    if (category) {
      setCategoryColor(category.color) // Set the color if the category is found
    }
  }, [categories, categoryName])

  useEffect(() => {
    const loadData = async () => {
      if (!user?.uid) return // Sicherstellen, dass der Nutzer eingeloggt ist
    }

    loadData()
  }, [categoryName, user, selectedCatalog, questionsToAskOrderd.length]) // Lade nur, wenn Fragen noch nicht geladen wurden

  // setCategoryColor(data.categoryColor)
  // setQuestionsToAskOrderd(data.filteredQuestions)
  // setProgressFull(data.allAnsweredThreeTimes)

  useEffect(() => {
    if (questionsToAskOrderd.length > 0) {
      const shuffleArray = array => {
        return array
          .map(item => ({ item, sort: Math.random() }))
          .sort((a, b) => a.sort - b.sort)
          .map(({ item }) => item)
      }

      const shuffledQuestions = shuffleArray(questionsToAskOrderd)
      setQuestionsToAsk(shuffledQuestions)
    }
  }, [questionsToAskOrderd])

  const playCorrectAnswerSound = () => {
    correctAnswerSound
      .play()
      .catch(error => console.error('Error playing sound:', error))
  }

  const handleDndAnswerSubmit = async block => {
    if (isSubmitted) return

    setIsSubmitted(true)

    const currentQuestion = questionsToAsk[currentQuestionIndex]

    let isCorrect = false

    isCorrect = currentQuestion.block.toString() === block.toString()

    if (isCorrect) {
      playCorrectAnswerSound() // Play sound if the answer is correct

      setFeedback('Correct!')
      setStreak(streak + 1)
      setQuestionsRight(questionsRight + 1)
    } else {
      setQuestionIsWrong(true)
      setQuestionsWrong(questionsWrong + 1)
      setFeedback('Incorrect')
      setStreak(0)
    }

    if (categoryName !== 'Falsche Fragen') {
      await saveProgress(
        user.uid,
        currentQuestion.id,
        categoryName,
        isCorrect,
        selectedCatalog
      )
    } else {
      if (isCorrect) {
        await handleProgressDecrease(user.uid, currentQuestion.id)
      }
    }

    if (isCorrect) {
      setTimeout(() => {
        setFeedback('')
        if (currentQuestionIndex < questionsToAsk.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1)
          setIsSubmitted(false)
          setIsEvaluated(false)
        } else {
          setAllAnswered(true)
        }
      }, 500)
    }
  }

  const handleFillInTheBlankAnswerSubmit = async answer => {
    if (isSubmitted) return

    setIsSubmitted(true)

    let isCorrect = false
    const currentQuestion = questionsToAsk[currentQuestionIndex]
    const tolerance = currentQuestion.tolerance
      ? currentQuestion.tolerance
      : 1.8

    if (
      isSimilar(
        answer.toLowerCase().trim(),
        questionsToAsk[currentQuestionIndex].fillInTheBlanksQuestion
          .toLowerCase()
          .trim(),
        tolerance
      )
    ) {
      isCorrect = true
    }

    if (isCorrect) {
      playCorrectAnswerSound() // Play sound if the answer is correct

      setFeedback('Correct!')
      setStreak(streak + 1)
      setQuestionsRight(questionsRight + 1)
    } else {
      setQuestionIsWrong(true)
      setQuestionsWrong(questionsWrong + 1)
      setFeedback('Incorrect')
      setStreak(0)
    }

    if (categoryName !== 'Falsche Fragen') {
      await saveProgress(
        user.uid,
        currentQuestion.id,
        categoryName,
        isCorrect,
        selectedCatalog
      )
    } else {
      if (isCorrect) {
        await handleProgressDecrease(user.uid, currentQuestion.id)
      }
    }

    if (isCorrect) {
      setTimeout(() => {
        setFeedback('')
        if (currentQuestionIndex < questionsToAsk.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1)
          setIsSubmitted(false)
          setIsEvaluated(false)
        } else {
          setAllAnswered(true)
        }
      }, 500)
    }
  }

  const handleMultipleAnswersSubmit = async answer => {
    if (isSubmitted) return

    setIsSubmitted(true)

    let isCorrect = false
    const currentQuestion = questionsToAsk[currentQuestionIndex]
    const tolerance = currentQuestion.tolerance
      ? currentQuestion.tolerance
      : 1.8

    if (
      answer.length === currentQuestion.answers.length &&
      answer.every(a =>
        currentQuestion.answers.some(b =>
          isSimilar(a.toLowerCase().trim(), b.toLowerCase().trim(), tolerance)
        )
      ) &&
      currentQuestion.answers.every(b =>
        answer.some(a =>
          isSimilar(a.toLowerCase().trim(), b.toLowerCase().trim(), tolerance)
        )
      )
    ) {
      isCorrect = true
    }

    if (isCorrect) {
      playCorrectAnswerSound() // Play sound if the answer is correct

      setFeedback('Correct!')
      setStreak(streak + 1)
      setQuestionsRight(questionsRight + 1)
    } else {
      setQuestionIsWrong(true)
      setQuestionsWrong(questionsWrong + 1)
      setFeedback('Incorrect')
      setStreak(0)
    }

    if (categoryName !== 'Falsche Fragen') {
      await saveProgress(
        user.uid,
        currentQuestion.id,
        categoryName,
        isCorrect,
        selectedCatalog
      )
    } else {
      if (isCorrect) {
        await handleProgressDecrease(user.uid, currentQuestion.id)
      }
    }

    if (isCorrect) {
      setTimeout(() => {
        setFeedback('')
        if (currentQuestionIndex < questionsToAsk.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1)
          setIsSubmitted(false)
          setIsEvaluated(false)
        } else {
          setAllAnswered(true)
        }
      }, 500)
    }
  }

  const handleLapAnswerSubmit = async lapData => {
    if (isSubmitted) return
    setIsSubmitted(true)

    const currentQuestion = questionsToAsk[currentQuestionIndex]

    // Find the indices where selfEvaluations are false
    const incorrectIndices = lapData.selfEvaluations
      .map((evaluation, index) => (evaluation === false ? index : null))
      .filter(index => index !== null)

    let isCorrect = incorrectIndices.length === 0 // Correct if no false values

    setIsEvaluated(true)

    if (isCorrect) {
      setFeedback('Correct!')
      playCorrectAnswerSound() // Play sound if the answer is correct

      setStreak(streak + 1)
      setQuestionsRight(questionsRight + 1)
    } else {
      setQuestionIsWrong(incorrectIndices)
      setQuestionsWrong(questionsWrong + 1)
      setFeedback('Incorrect')
      setStreak(0)
    }

    if (categoryName !== 'Falsche Fragen') {
      await saveProgress(
        user.uid,
        currentQuestion.id,
        categoryName,
        isCorrect,
        selectedCatalog
      )
    } else {
      if (isCorrect) {
        await handleProgressDecrease(user.uid, currentQuestion.id)
      }
    }

    if (isCorrect) {
      setTimeout(() => {
        setFeedback('')
        if (currentQuestionIndex < questionsToAsk.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1)
          setIsSubmitted(false)
          setIsEvaluated(false)
        } else {
          setAllAnswered(true)
        }
      }, 500)
    }
  }

  const handleMultipleChoiceSubmit = async answer => {
    if (isSubmitted) return
    const correctAnswersIndexes =
      questionsToAsk[currentQuestionIndex].correctAnswer

    setIsSubmitted(true)

    let isCorrect = false
    const currentQuestion = questionsToAsk[currentQuestionIndex]

    if (
      answer.length === correctAnswersIndexes.length && // Gleiche Anzahl von Elementen
      answer.every(index => correctAnswersIndexes.includes(index)) && // Alle ausgewählten Indizes sind in den korrekten enthalten
      correctAnswersIndexes.every(index => answer.includes(index)) // Alle korrekten Indizes sind ausgewählt
    ) {
      isCorrect = true
    }

    if (isCorrect) {
      playCorrectAnswerSound() // Play sound if the answer is correct

      setFeedback('Correct!')
      setStreak(streak + 1)
      setQuestionsRight(questionsRight + 1)
    } else {
      setQuestionIsWrong(true)
      setQuestionsWrong(questionsWrong + 1)
      setFeedback('Incorrect')
      setStreak(0)
    }

    if (categoryName !== 'Falsche Fragen') {
      await saveProgress(
        user.uid,
        currentQuestion.id,
        categoryName,
        isCorrect,
        selectedCatalog
      )
    } else {
      if (isCorrect) {
        await handleProgressDecrease(user.uid, currentQuestion.id)
      }
    }

    if (isCorrect) {
      setTimeout(() => {
        setFeedback('')
        if (currentQuestionIndex < questionsToAsk.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1)
          setIsSubmitted(false)
          setIsEvaluated(false)
        } else {
          setAllAnswered(true)
        }
      }, 500)
    }
  }

  const handleAnswerSubmit = async e => {
    setElement(e)
    if (isSubmitted) return
    setIsSubmitted(true)

    const answer = e.target.answer.value.trim().toLowerCase()
    const currentQuestion = questionsToAsk[currentQuestionIndex]
    const tolerance = currentQuestion.tolerance ? currentQuestion.tolerance : 2

    let isCorrect = false

    if (currentQuestion.type === 'text') {
      const solutionText = currentQuestion?.correctAnswer?.toLowerCase()
      isCorrect = isSimilar(answer, solutionText, tolerance)
    }

    if (isCorrect) {
      setFeedback('Correct!')
      setStreak(streak + 1)
      playCorrectAnswerSound() // Play sound if the answer is correct

      setQuestionsRight(questionsRight + 1)
    } else {
      setQuestionIsWrong(true)
      setQuestionsWrong(questionsWrong + 1)
      setFeedback('Incorrect')
      setStreak(0)
    }

    if (categoryName !== 'Falsche Fragen') {
      await saveProgress(
        user.uid,
        currentQuestion.id,
        categoryName,
        isCorrect,
        selectedCatalog
      )
    } else {
      if (isCorrect) {
        await handleProgressDecrease(user.uid, currentQuestion.id)
      }
    }

    if (isCorrect) {
      setTimeout(() => {
        setFeedback('')
        if (currentQuestionIndex < questionsToAsk.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1)
          setIsSubmitted(false)
          e.target.answer.value = ''
        } else {
          setAllAnswered(true)
        }
      }, 500)
    } else {
    }
  }

  useEffect(() => {
    if (user?.uid && allAnswered) {
      updateStreak(user.uid)
    }
  }, [allAnswered, user])

  const handleNextQuestion = () => {
    setFeedback('')
    setQuestionIsWrong(false)
    setIsEvaluated(false)
    if (currentQuestionIndex < questionsToAsk.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      setIsSubmitted(false)
      if (element) {
        element.target.answer.value = ''
      }
    } else {
      setAllAnswered(true)
    }
  }

  return (
    <div className='categoryContent'>
      <h2
        style={{
          backgroundColor: categoryColor,
          padding: '10px',
          borderRadius: '10px'
        }}
      >
        {categoryName}
      </h2>

      <div className='progressBarContainer'>
        <div className='progressBarOuter'>
          <div
            className='progressBarCorrect'
            style={{
              width: `${(questionsRight / questionsToAsk.length) * 100}%`
            }}
          ></div>
          <div
            className='progressBarWrong'
            style={{
              width: `${(questionsWrong / questionsToAsk.length) * 100}%`
            }}
          ></div>
          <div
            className='progressBarUnanswered'
            style={{
              width: `${
                100 -
                (questionsRight / questionsToAsk.length) * 100 -
                (questionsWrong / questionsToAsk.length) * 100
              }%`
            }}
          ></div>
        </div>
      </div>

      <FeedbackDisplay feedback={feedback} streak={streak} />

      {allAnswered ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            marginTop: '2rem',
            width: '100%',
            maxWidth: '600px'
          }}
        >
          <h3>Super, du hast alle Fragen in dieser Kategorie beantwortet!</h3>

          <button onClick={() => setSelectedCategory(null)}>Zurück!</button>
        </div>
      ) : questionsToAsk.length > 0 ? (
        <div className='questionItem'>
          {/* Render the question using the QuestionContainer */}
          <QuestionContainer
            questionData={questionsToAsk[currentQuestionIndex]}
            onAnswerSubmit={handleAnswerSubmit}
            isWrong={questionIsWrong}
            onLapAnswerSubmit={handleLapAnswerSubmit}
            onDndAnswerSubmit={handleDndAnswerSubmit}
            isEvaluated={isEvaluated}
            onFillInTheBlanksAnswerSubmit={handleFillInTheBlankAnswerSubmit}
            handleNextQuestion={handleNextQuestion}
            onMultipleAnswersSubmit={handleMultipleAnswersSubmit}
            handleMultipleChoiceSubmit={handleMultipleChoiceSubmit}
          />

          <div className='buttonDivLearn'>
            {questionIsWrong && (
              <button
                onClick={() => handleNextQuestion()}
                className='nextQuestionButton'
                id='nextQuestionButton'
              >
                Nächste Frage
              </button>
            )}
            <button
              className='backButtonLearn'
              onClick={() => setSelectedCategory(null)}
            >
              <KeyboardBackspaceIcon />
            </button>
          </div>
        </div>
      ) : (
        <p>Keine Fragen in dieser Kategorie.</p>
      )}

      {progressFull && (
        <button
          style={{ backgroundColor: 'red', borderRadius: '5px' }}
          className='resetButton'
          onClick={() => handleResetProgress(user, categoryName)}
        >
          Fortschritt zurücksetzen
        </button>
      )}
    </div>
  )
}

export default CategoryContent
