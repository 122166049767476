import React, { createContext, useContext, useState, useEffect } from 'react'
import { db } from '../firebaseConfig'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where
} from 'firebase/firestore'

const DataContext = createContext()

export const useData = () => useContext(DataContext)

export const DataProvider = ({ children, catalog, userId }) => {
  const [categories, setCategories] = useState([])
  const [questions, setQuestions] = useState([])
  const [catalogData, setCatalogData] = useState({})
  const [wrongQuestions, setWrongQuestions] = useState([])
  const [progress, setProgress] = useState([])
  const [testResults, setTestResults] = useState([])

  useEffect(() => {
    if (!catalog) return

    const fetchCatalogData = async () => {
      try {
        const catalogRef = doc(db, 'questions', catalog)
        const catalogSnap = await getDoc(catalogRef)
        if (catalogSnap.exists()) {
          setCatalogData(catalogSnap.data())
        }
      } catch (e) {
        console.log('Error fetching catalog data:', e)
      }
    }

    const fetchCategories = async () => {
      try {
        const ref = collection(db, 'questions', catalog, 'categories')
        const categoriesSnapshot = await getDocs(ref)
        setCategories(
          categoriesSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name,
            color: doc.data().color
          }))
        )
      } catch (e) {
        console.log('Error fetching categories:', e)
      }
    }

    const fetchQuestions = async () => {
      try {
        const ref = collection(db, 'questions', catalog, 'questions')
        const snapshot = await getDocs(ref)
        setQuestions(
          snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        )
      } catch (e) {
        console.log('Error fetching questions:', e)
      }
    }

    const fetchWrongQuestions = async () => {
      if (!userId) return
      try {
        const wrongQuestionsCollection = collection(
          db,
          'users',
          userId,
          'wrongQuestions'
        )
        const wrongQuestionsQuery = query(
          wrongQuestionsCollection,
          where('catalog', '==', catalog)
        )
        const wrongQuestionsSnapshot = await getDocs(wrongQuestionsQuery)
        setWrongQuestions(
          wrongQuestionsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        )
      } catch (e) {
        console.log('Error fetching wrong questions:', e)
      }
    }

    const fetchTestResults = async () => {
      if (!userId) return
      try {
        const testResultsCollection = collection(
          db,
          'users',
          userId,
          'testResults'
        )
        const testResultsQuery = query(
          testResultsCollection,
          where('catalog', '==', catalog)
        )
        const testResultsSnapshot = await getDocs(testResultsQuery)
        setTestResults(
          testResultsSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        )
      } catch (e) {
        console.log('Error fetching test results:', e)
      }
    }

    const fetchProgress = async () => {
      if (!userId) return
      try {
        const progressCollection = collection(db, 'users', userId, 'progress')
        const progressQuery = query(
          progressCollection,
          where('catalog', '==', catalog)
        )
        const progressSnapshot = await getDocs(progressQuery)
        setProgress(
          progressSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
        )
      } catch (e) {
        console.log('Error fetching progress:', e)
      }
    }

    // Fetch all data when catalog or userId changes
    fetchCatalogData()
    fetchCategories()
    fetchQuestions()
    fetchWrongQuestions()
    fetchTestResults()
    fetchProgress()
  }, [catalog, userId])

  return (
    <DataContext.Provider
      value={{
        categories,
        questions,
        catalogData,
        wrongQuestions,
        testResults,
        progress
      }}
    >
      {children}
    </DataContext.Provider>
  )
}
