import React, { useState, useEffect, useRef } from 'react'
import { db } from '../firebaseConfig'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import {
  fetchCreatorId,
  fetchMessages,
  fetchUserName,
  sendMessage,
  acceptRequest,
  fetchIsSold,
  fetchCatalogName
} from '../utils/chatUtils'
import { useUser } from '../context/UserContext'
import { Link, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ChatSelectionPage from './ChatSelectionPage'
import './ChatPage.css'

const ChatPage = () => {
  const { user } = useUser()
  const { chatId } = useParams()
  const [userId, catalogId] = chatId.split('-')
  const [messageTo, setMessageTo] = useState()
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [userNames, setUserNames] = useState({})
  const [role, setRole] = useState()
  const [catalogIsSold, setCatalogIsSold] = useState(false)
  const messagesEndRef = useRef(null)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [catalogName, setCatalogName] = useState('')
  const [isLegitChat, setIsLegitChat] = useState(null)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    const checkChatLegitimacy = async () => {
      if (userId === user.uid || messageTo === user.uid) {
        setIsLegitChat(true)
      } else {
        setIsLegitChat(false)
      }
    }

    checkChatLegitimacy()
  }, [userId, chatId, role, messageTo]) // Entferne catalogId, da es nicht verwendet wird

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Enter' && document.activeElement.tagName === 'INPUT') {
        sendMessage({
          db,
          chatId,
          user,
          message, // Aktueller Wert von message
          messageTo,
          userId,
          catalogId,
          setMessage
        })
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [db, chatId, user, message, messageTo, userId, catalogId, setMessage]) // Abhängigkeiten aktualisieren

  useEffect(() => {
    if (userId === user.uid) {
      setRole('buyer')
    } else {
      setRole('seller')
    }
  }, [userId, user.uid])

  useEffect(() => {
    fetchIsSold(db, chatId, setCatalogIsSold)
  }, [chatId])

  useEffect(() => {
    fetchCreatorId(db, catalogId, setMessageTo)
    fetchCatalogName(db, catalogId, setCatalogName)
  }, [catalogId])

  useEffect(() => {
    const unsubscribe = fetchMessages(db, chatId, setMessages, loadUserNames)
    return () => unsubscribe()
  }, [chatId])

  useEffect(() => {
    scrollToBottom()
    updateReadStatus()
  }, [messages])

  useEffect(() => {
    updateReadStatus()
  }, [messageTo, userId, chatId])

  const updateReadStatus = async () => {
    if (!userId || messageTo === undefined) return
    const chatRef = doc(db, 'chats', chatId)
    const chatDoc = await getDoc(chatRef)

    if (!chatDoc.exists()) return

    const chatData = chatDoc.data()

    if (userId === user.uid) {
      await updateDoc(chatRef, {
        buyer: {
          id: userId,
          read: true
        }
      })
    } else {
      // Update only the read status for buyer, preserving existing id
      await updateDoc(chatRef, {
        seller: {
          id: messageTo, // Keep the existing id and other fields
          read: true
        }
      })
    }
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const loadUserNames = async msgs => {
    const uniqueUserIds = [...new Set(msgs.map(msg => msg.senderId))]
    const newUserNames = {}

    await Promise.all(
      uniqueUserIds.map(async userId => {
        if (!userNames[userId]) {
          const userName = await fetchUserName(db, userId)
          newUserNames[userId] = userName
        }
      })
    )

    setUserNames(prevUserNames => ({ ...prevUserNames, ...newUserNames }))
  }

  return (
    <div className='chat-page-outer-div'>
      {screenWidth > 800 && (
        <div className='chat-page'>
          <ChatSelectionPage />
        </div>
      )}

      <div className='chat-page'>
        {/* Überprüfe die Legitimität des Chats */}
        {isLegitChat === null ? (
          <div className='loading-message'>
            <p>Überprüfung des Chats läuft...</p>
          </div>
        ) : isLegitChat ? (
          <>
            <div className='chat-header'>
              <button>
                <Link to={'/chats'}>
                  <ArrowBackIcon />
                </Link>
              </button>
              {catalogName ? catalogName : 'Lädt...'}
            </div>
            <div className='chat-messages'>
              {messages.length === 0 && (
                <div className='no-messages'>
                  <h4>
                    Hier können Sie mit dem Ersteller des Katalogs{' '}
                    <span style={{ color: '#ff7e5f' }}>{catalogName} </span>
                    schreiben und den Katalog erwerben!
                  </h4>
                </div>
              )}
              {messages.map(msg => (
                <React.Fragment key={msg.id}>
                  <div
                    className={`chat-message ${
                      msg.senderId === user.uid ? 'sent' : 'received'
                    }`}
                  >
                    <p className='message-sender'>
                      {msg.senderId === user.uid
                        ? 'Du'
                        : userNames[msg.senderId] || 'Lädt...'}
                    </p>
                    <p className='message-text'>{msg?.message}</p>
                  </div>
                  {msg.type === 'request' &&
                  role === 'seller' &&
                  !catalogIsSold ? (
                    <div className='requestDiv'>
                      <p>Anfrage erhalten</p>
                      <button
                        onClick={() =>
                          acceptRequest(db, userId, catalogId, chatId)
                        }
                      >
                        Anfrage annehmen
                      </button>
                    </div>
                  ) : msg.type === 'request' &&
                    role === 'buyer' &&
                    !catalogIsSold ? (
                    <div className='requestDiv'>
                      <p>Anfrage gesendet</p>
                    </div>
                  ) : msg.type === 'request' &&
                    role === 'buyer' &&
                    catalogIsSold ? (
                    <div
                      className='requestDiv'
                      style={{ backgroundColor: 'lightgreen' }}
                    >
                      <p>Anfrage wurde akzeptiert</p>
                    </div>
                  ) : msg.type === 'request' &&
                    role === 'seller' &&
                    catalogIsSold ? (
                    <div
                      className='requestDiv'
                      style={{ backgroundColor: 'lightgreen' }}
                    >
                      <p>Sie haben die Anfrage akzeptiert!</p>
                    </div>
                  ) : null}
                </React.Fragment>
              ))}
              {role === 'buyer' &&
                !messages.some(message => message.type === 'request') &&
                messages.length > 0 && (
                  <div className='sendRequestDiv'>
                    <p>Anfrage senden?</p>
                    <button
                      onClick={() =>
                        sendMessage({
                          db,
                          chatId,
                          user,
                          message,
                          messageTo,
                          userId,
                          catalogId,
                          setMessage,
                          type: 'request'
                        })
                      }
                    >
                      Anfrage senden
                    </button>
                  </div>
                )}
              <div ref={messagesEndRef} />
            </div>
            <div className='chat-input'>
              <input
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder='Nachricht eingeben...'
              />
              <button
                onClick={() =>
                  sendMessage({
                    db,
                    chatId,
                    user,
                    message,
                    messageTo,
                    userId,
                    catalogId,
                    setMessage
                  })
                }
              >
                Senden
              </button>
            </div>
          </>
        ) : (
          <div className='unauthorized-message'>
            <h3>Dieser Chat ist nicht legitim.</h3>
            <p>Sie haben keine Berechtigung, diesen Chat einzusehen.</p>
            <Link to={'/chats'}>
              <button>Zurück zur Chat-Auswahl</button>
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default ChatPage
