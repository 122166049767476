import React, { useState, useEffect } from 'react'
import './style/FillInTheBlankQuestion.css'

const FillInTheBlankQuestion = ({
  data,
  onAnswerSubmit,
  isWrong,
  handleNextQuestion
}) => {
  const resetUserAnswers = () => {
    setUserAnswers(data.selectedBlanks.map(() => ''))
  }
  useEffect(() => {
    resetUserAnswers()
  }, [data])
  // State für die Lücken im Text
  const [userAnswers, setUserAnswers] = useState(
    data.selectedBlanks.map(() => '') // Initialisiere leere Antworten für die Lücken
  )

  // Funktion, um die Antwort für eine Lücke zu setzen
  const handleInputChange = (value, index) => {
    const updatedAnswers = [...userAnswers]
    updatedAnswers[index] = value
    setUserAnswers(updatedAnswers)
  }

  // Funktion, um den Satz mit Benutzerantworten zu erstellen
  const setSentenceTogheter = () => {
    return data.fillInTheBlanksQuestion
      .split(' ')
      .map((word, index) => {
        if (data.selectedBlanks.includes(index)) {
          // Setze die Benutzerantwort anstelle des Platzhalters ein
          return userAnswers[data.selectedBlanks.indexOf(index)] || '____'
        } else {
          return word
        }
      })
      .join(' ') // Füge alle Wörter mit einem Leerzeichen zusammen
  }

  // Funktion zum Übermitteln der Antworten
  const handleSubmit = e => {
    e.preventDefault()

    const sentence = setSentenceTogheter() // Erstelle den Satz mit den Benutzerantworten

    onAnswerSubmit(sentence) // Übermittle die Antworten
  }

  // Funktion zur Überprüfung, ob die Lücken richtig ausgefüllt wurden
  const getBorderColor = (userAnswer, correctAnswer) => {
    if (isWrong) {
      return userAnswer === correctAnswer ? 'green' : 'red'
    }
    return '#ccc'
  }

  useEffect(() => {
    const handleEnterKey = event => {
      if (event.key === 'Enter') {
        if (isWrong) {
          handleNextQuestion() // Go to the next question
        } else {
          event.preventDefault() // Prevent form from reloading on enter key
          document.getElementById('submit-button').click() // Trigger submit button click
        }
      }
    }

    // Add event listener for Enter key
    document.addEventListener('keydown', handleEnterKey)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEnterKey)
    }
  }, [isWrong, handleNextQuestion])

  return (
    <div className='fillInTheBlanksQuestion'>
      <h4>Fill in the blanks</h4>

      <div className='fillInTheBlanksContainer'>
        {data.fillInTheBlanksQuestion.split(' ').map((word, index) => (
          <React.Fragment key={index}>
            {/* Überprüfe, ob der aktuelle Index in den ausgewählten Lücken enthalten ist */}
            {data.selectedBlanks.includes(index) ? (
              <input
                type='text'
                value={userAnswers[data.selectedBlanks.indexOf(index)] || ''}
                onChange={e =>
                  handleInputChange(
                    e.target.value,
                    data.selectedBlanks.indexOf(index)
                  )
                }
                className='blankInput'
                style={{
                  borderColor: getBorderColor(
                    userAnswers[data.selectedBlanks.indexOf(index)],
                    data.selectedBlanks.indexOf(index)
                  )
                }}
              />
            ) : (
              <span style={{ marginRight: '5px' }}>{word}</span> // Zeige das normale Wort an
            )}
          </React.Fragment>
        ))}
      </div>

      {isWrong && (
        <div className='correctAnswer'>
          <strong>Richtige Antworten:</strong>
          <p style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            {data.fillInTheBlanksQuestion.split(' ').map((word, index) => (
              <React.Fragment key={index}>
                {/* Wenn es eine Lücke ist, zeige das korrekte Wort in grün */}
                {data.selectedBlanks.includes(index) ? (
                  <span style={{ color: 'green', marginRight: '5px' }}>
                    {data.fillInTheBlanksQuestion.split(' ')[index]}
                  </span>
                ) : (
                  <span style={{ marginRight: '5px' }}>{word}</span>
                )}
              </React.Fragment>
            ))}
          </p>
        </div>
      )}

      <button
        className='submitButton'
        id='submit-button'
        onClick={handleSubmit}
      >
        Weiter
      </button>
    </div>
  )
}

export default FillInTheBlankQuestion
