import React, { useState } from 'react'
import Categories from '../components/Categories'
import { useUser } from '../context/UserContext'
import CategoryContent from '../components/CategoryContent' // This will be the new component
import CategoryList from '../components/CategoryList.js' // Assuming you have a CategoryList component

const Learn = ({ selectedCatalog }) => {
  const { userData } = useUser()
  const [selectedCategory, setSelectedCategory] = useState(null) // Track the selected category
  const [selectedCategoryList, setSelectedCategoryList] = useState(null) // Track the selected category list

  // Handle the start button click for an individual category
  const handleStartCategory = categoryName => {
    setSelectedCategory(categoryName) // Set the selected category
  }

  // Set the selected category list
  const handleCategoryListSelect = categoryListName => {
    setSelectedCategoryList(categoryListName) // Set the selected category list
  }

  return (
    <div className='learn'>
      {/* Conditionally render based on selectedCategory or selectedCategoryList */}
      {selectedCategory ? (
        <CategoryContent
          categoryName={selectedCategory}
          selectedCatalog={selectedCatalog}
          setSelectedCategory={setSelectedCategory}
        /> // Render content when a category is selected
      ) : selectedCategoryList ? (
        <>
          <CategoryList
            listName={selectedCategoryList}
            selectedCatalog={selectedCatalog}
          />
          <button
            style={{
              marginTop: '20px',
              borderRadius: '5px',
              position: 'fixed',
              bottom: '10px'
            }}
            onClick={() => setSelectedCategoryList(null)}
          >
            Zurück
          </button>{' '}
        </>
      ) : (
        <div className='categories'>
          <div className='welcomeMessage'>
            <h2>{userData?.firstName}, was möchtest du lernen?</h2>
          </div>
          <Categories
            selectedCatalog={selectedCatalog}
            onCategoryListSelect={handleCategoryListSelect} // Pass handler to Categories for selecting lists
            onStartCategory={handleStartCategory} // Pass the handler to start individual category
          />
        </div>
      )}
    </div>
  )
}

export default Learn
