import React, { useEffect, useState } from 'react'

const MultipleChoiceQuestion = ({
  question,
  onAnswerSubmit,
  isWrong,
  handleNextQuestion
}) => {
  const [selectedAnswers, setSelectedAnswers] = useState([])

  useEffect(() => {
    setSelectedAnswers([]) // Zurücksetzen der Auswahl bei einer neuen Frage
  }, [question])

  useEffect(() => {
    const handleEnterKey = event => {
      if (event.key === 'Enter') {
        event.preventDefault() // Standardverhalten verhindern

        if (!isWrong) {
          document.getElementById('submit-button').click() // Trigger submit button
        } else {
          handleNextQuestion() // Nächste Frage aufrufen
        }
      }
    }

    // Event Listener hinzufügen
    document.addEventListener('keydown', handleEnterKey)

    // Event Listener entfernen beim Component Unmount
    return () => {
      document.removeEventListener('keydown', handleEnterKey)
    }
  }, [isWrong, handleNextQuestion])

  // Antwort auswählen (Mehrfachauswahl unterstützen)
  const handleAnswerChange = index => {
    if (selectedAnswers.includes(index)) {
      // Antwort abwählen
      setSelectedAnswers(selectedAnswers.filter(answer => answer !== index))
    } else {
      // Antwort hinzufügen
      setSelectedAnswers([...selectedAnswers, index])
    }
  }

  // Bestimmen der CSS-Klasse für die Antwort
  const getAnswerClass = index => {
    if (!isWrong) return 'answerLabel' // Standard-Styling

    const isCorrect = question.correctAnswer.includes(index) // Prüfen, ob die Antwort korrekt ist
    if (selectedAnswers.includes(index) && !isCorrect) {
      return 'answerLabel wrong' // Falsche Auswahl
    }
    if (isCorrect) {
      return 'answerLabel correct' // Richtige Antwort
    }
    return 'answerLabel' // Standard-Styling
  }

  const handleSubmit = e => {
    e.preventDefault()
    onAnswerSubmit(selectedAnswers) // Pass all answers to the parent component
  }

  return (
    <div className='multipleChoiceQuestion'>
      <div className='questionAndNumberDiv'>
        <h4 className='questionTitle'>{question.question}</h4>
        <p style={{ color: '#333333' }}>{question.questionNumber}</p>
      </div>

      {/* Bild rendern, falls vorhanden */}
      {question.imageUrl && (
        <div className='questionImageContainer'>
          <img
            src={question.imageUrl}
            alt='Question related'
            className='questionImage'
          />
        </div>
      )}

      <form onSubmit={handleSubmit} className='multipleChoiceForm'>
        {question.answers.map((answer, index) => (
          <div className='answerOption' key={index}>
            <input
              type='checkbox' // Checkbox für Mehrfachauswahl
              id={`answer-${index}`}
              name='answer'
              value={index} // Verwenden des Index als Wert
              className='answerInput'
              onChange={() => handleAnswerChange(index)}
              checked={selectedAnswers.includes(index)} // Überprüfen, ob der Index ausgewählt ist
              disabled={isWrong} // Deaktivieren, falls die Antwort falsch ist
            />
            <label
              htmlFor={`answer-${index}`}
              className={getAnswerClass(index)} // Klasse basierend auf Index
            >
              {answer}
            </label>
          </div>
        ))}
        <button type='submit' id='submit-button' className='submitButton'>
          Weiter
        </button>
      </form>
    </div>
  )
}

export default MultipleChoiceQuestion
