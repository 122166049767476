import React, { useEffect, useState } from 'react'
import Categorie from './Categorie'
import { useData } from '../context/DataContext'
import InfoIcon from '@mui/icons-material/Info'
import CancelIcon from '@mui/icons-material/Cancel'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'

const Categories = ({
  onStartCategory,
  onCategoryListSelect,
  selectedCatalog
}) => {
  const { categories, progress, wrongQuestions } = useData()
  const [progressData, setProgressData] = useState({})
  const [wrongQuestionIds, setWrongQuestionIds] = useState([])
  const [infoActive, setInfoActive] = useState(false)

  useEffect(() => {
    // Group progress by category when progress data changes
    const groupedProgress = progress.reduce((acc, doc) => {
      const category = doc.questionCategory
      if (!acc[category]) {
        acc[category] = []
      }
      acc[category].push({
        id: doc.id,
        ...doc
      })
      return acc
    }, {})

    setProgressData(groupedProgress)
  }, [progress])

  useEffect(() => {
    // Extract question ids from wrong questions
    const ids = wrongQuestions.map(q => q.catalog)
    setWrongQuestionIds(ids)
  }, [wrongQuestions])

  if (categories.length === 0) {
    return <p>Keine Kategorien vorhanden...</p>
  }

  return (
    <div className='categories'>
      <Categorie
        selectedCatalog={selectedCatalog}
        title={'Falsche Fragen'}
        color={'#ff0000'}
        onStartCategory={onStartCategory}
        handleCategorieClick={onCategoryListSelect}
        progressDocs={[]} // Pass grouped progress by category name
        mode={'wrongQuestions'}
        wrongQuestionIds={wrongQuestionIds || []}
      />
      <div></div>
      {categories
        .sort((a, b) => a.name.localeCompare(b.name)) // Sortiere die Kategorien alphabetisch nach Name
        .map(categorie => (
          <Categorie
            selectedCatalog={selectedCatalog}
            key={categorie.id}
            title={categorie.name}
            color={categorie.color}
            onStartCategory={onStartCategory}
            handleCategorieClick={onCategoryListSelect}
            progressDocs={progressData[categorie.name] || []} // Pass grouped progress by category name
            mode={'normalCategory'}
          />
        ))}

      {!infoActive ? (
        <div
          className='infoDivLearn'
          onClick={() => setInfoActive(!infoActive)}
        >
          <InfoIcon style={{ color: '#ff7e5f', fontSize: '2rem' }} />
        </div>
      ) : (
        <div
          className='infoDivLearn infoDivLearnActive'
          onClick={() => setInfoActive(!infoActive)}
        >
          <p
            style={{
              maxWidth: '600px',
              color: 'black',
              textAlign: 'center'
            }}
          >
            Hier können Sie die einzelen Kapitel Ihres Kataloges lernen
            <br></br>
            (<PlayArrowIcon />) <br></br>
            und euch die Fragen nochmal im Detail ansehen. <br></br>
            (<FormatListBulletedIcon />)<br></br>
          </p>
          <CancelIcon style={{ color: 'black' }} />
        </div>
      )}
    </div>
  )
}

export default Categories
