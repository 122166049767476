import { collection, query, where, getDocs } from 'firebase/firestore'
import { db } from '../firebaseConfig'

const getUnreadMessages = async user => {
  if (!user) {
    console.error('Kein Benutzer übergeben.')
    return 0
  }

  try {
    const chatsRef = collection(db, 'chats')
    const q = query(chatsRef, where('participants', 'array-contains', user.uid))
    const querySnapshot = await getDocs(q)

    let unreadMessages = 0
    querySnapshot.forEach(doc => {
      const chat = doc.data()

      // Prüfen, ob der Benutzer der Käufer oder Verkäufer ist
      if (user.uid === chat.seller.id && chat.seller.read === false) {
        unreadMessages++
      } else if (user.uid === chat.buyer.id && chat.buyer.read === false) {
        unreadMessages++
      }
    })

    return unreadMessages
  } catch (error) {
    console.error('Fehler beim Abrufen ungelesener Nachrichten:', error)
    return 0
  }
}

export default getUnreadMessages
