export const AnswerFields = ({
  answers,
  correctAnswer = [], // Array für mehrere korrekte Antworten
  handleAnswerChange,
  setCorrectAnswer,
  handleAddAnswer,
  handleRemoveAnswer
}) => {
  // Funktion zum Verarbeiten von Checkbox-Änderungen
  const handleCheckboxChange = index => {
    if (correctAnswer.includes(index)) {
      // Entferne den Index, wenn er bereits ausgewählt ist
      setCorrectAnswer(correctAnswer.filter(i => i !== index))
    } else {
      // Füge den Index hinzu, wenn er noch nicht ausgewählt ist
      setCorrectAnswer([...correctAnswer, index])
    }
  }

  return (
    <div style={{ width: '100%', marginTop: '2rem' }}>
      <label>Antworten:</label>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          backgroundColor: '#333',
          padding: '1rem',
          borderRadius: '8px'
        }}
      >
        {answers.map((answer, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              width: '100%'
            }}
          >
            <input
              style={{
                flex: 1,
                backgroundColor: 'white'
              }}
              type='text'
              value={answer}
              onChange={e => handleAnswerChange(index, e.target.value)}
              placeholder={`Antwort ${index + 1}`}
            />
            <label style={{ color: 'white' }}>
              <input
                type='checkbox'
                checked={correctAnswer.includes(index)} // Checkbox ausgewählt, wenn Index in correctAnswer
                onChange={() => handleCheckboxChange(index)}
              />
              Richtig
            </label>
          </div>
        ))}
      </div>

      <div
        style={{
          marginTop: '1rem',
          display: 'flex',
          gap: '5px',
          marginBottom: '1rem'
        }}
      >
        <button
          type='button'
          onClick={() => {
            handleRemoveAnswer()
          }}
          style={{
            background: 'red',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            padding: '0.5rem 1rem',
            borderRadius: '4px',
            width: '50%'
          }}
        >
          Antwort entfernen
        </button>
        <button
          type='button'
          onClick={() => {
            handleAddAnswer()
          }}
          style={{
            background: 'green',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            padding: '0.5rem 1rem',
            borderRadius: '4px',
            width: '50%'
          }}
        >
          Antwort hinzufügen
        </button>
      </div>
    </div>
  )
}
