import {
  updateDoc,
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  query,
  where
} from 'firebase/firestore'
import { db } from '../firebaseConfig'

export const handleResetProgress = async (user, categoryName) => {
  try {
    // Fetch the progress documents only for the current category
    const progressCollection = collection(db, `users/${user.uid}/progress`)
    const q = query(
      progressCollection,
      where('questionCategory', '==', categoryName)
    )
    const progressSnapshot = await getDocs(q)

    const resetPromises = progressSnapshot.docs.map(doc => {
      const progressDoc = doc.ref
      return updateDoc(progressDoc, {
        timesRight: 0,
        timesWrong: 0
      })
    })

    await Promise.all(resetPromises)
    window.location.reload() // Refresh the page
  } catch (error) {
    console.error('Error resetting progress:', error)
  }
}

export const updateStreak = async userId => {
  try {
    const userDocRef = doc(db, 'users', userId)
    const userSnapshot = await getDoc(userDocRef)

    const today = new Date()
    today.setHours(0, 0, 0, 0) // Set to midnight for comparison
    const yesterday = new Date(today)
    yesterday.setDate(today.getDate() - 1)

    if (userSnapshot.exists()) {
      const { lastLearned, streak = 0 } = userSnapshot.data()

      const lastLearnedDate = lastLearned
        ? new Date(lastLearned.seconds * 1000)
        : null

      // Check if the user learned today or yesterday
      if (
        lastLearnedDate &&
        lastLearnedDate.setHours(0, 0, 0, 0) === today.getTime()
      ) {
        // User has already learned today, no change
        return
      } else if (
        lastLearnedDate &&
        lastLearnedDate.setHours(0, 0, 0, 0) === yesterday.getTime()
      ) {
        // User learned yesterday, increase streak by 1
        await updateDoc(userDocRef, {
          lastLearned: today,
          streak: streak + 1
        })
      } else {
        // User hasn't learned recently, reset streak
        await updateDoc(userDocRef, {
          lastLearned: today,
          streak: 0
        })
      }
    } else {
      // If the user document does not exist, create a new one
      await setDoc(userDocRef, {
        lastLearned: today,
        streak: 1
      })
    }
  } catch (error) {
    console.error('Error updating streak:', error)
  }
}

export const fetchCategoryData = async (
  categoryName,
  user,
  selectedCatalog
) => {
  try {
    // Fetch the category color from Firestore
    const categoriesCollection = collection(
      db,
      'questions',
      selectedCatalog,
      'categories'
    )
    const qCat = query(categoriesCollection, where('name', '==', categoryName))
    const categorySnapshot = await getDocs(qCat)

    let categoryColor = '#f5f5f5'
    if (!categorySnapshot.empty) {
      const categoryData = categorySnapshot.docs[0].data()
      categoryColor = categoryData.color || '#f5f5f5'
    }

    // Fetch progress for the current user
    const progressCollection = collection(db, `users/${user.uid}/progress`)
    const progressSnapshot = await getDocs(progressCollection)
    let progressData = {}

    progressSnapshot.forEach(doc => {
      progressData[doc.id] = doc.data()
    })

    // Fetch questions for the selected category
    const questionsCollection = collection(
      db,
      'questions',
      selectedCatalog,
      'questions'
    )
    const q = query(questionsCollection, where('category', '==', categoryName))
    const questionsSnapshot = await getDocs(q)

    const questionsList = questionsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }))

    // Filter out questions that have been answered 3 or more times correctly
    const filteredQuestions = questionsList.filter(question => {
      const timesRight = progressData[question.id]?.timesRight || 0
      return timesRight < 3 // Only keep questions answered less than 3 times correctly
    })

    // Check if all questions are answered 3 times
    const allAnsweredThreeTimes = questionsList.every(
      question => (progressData[question.id]?.timesRight || 0) >= 3
    )

    return {
      categoryColor,
      filteredQuestions,
      allAnsweredThreeTimes
    }
  } catch (error) {
    console.error('Error fetching category data:', error)
    return {
      categoryColor: '#f5f5f5',
      filteredQuestions: [],
      allAnsweredThreeTimes: false
    }
  }
}

// Levenshtein Distance Algorithm
const levenshteinDistance = (a, b) => {
  const matrix = Array(a.length + 1)
    .fill(null)
    .map(() => Array(b.length + 1).fill(null))

  for (let i = 0; i <= a.length; i += 1) {
    matrix[i][0] = i
  }
  for (let j = 0; j <= b.length; j += 1) {
    matrix[0][j] = j
  }

  for (let i = 1; i <= a.length; i += 1) {
    for (let j = 1; j <= b.length; j += 1) {
      const indicator = a[i - 1] === b[j - 1] ? 0 : 1
      matrix[i][j] = Math.min(
        matrix[i][j - 1] + 1, // Deletion
        matrix[i - 1][j] + 1, // Insertion
        matrix[i - 1][j - 1] + indicator // Substitution
      )
    }
  }

  return matrix[a.length][b.length]
}

// Funktion zum Überprüfen, ob zwei Strings "ähnlich" genug sind (Toleranz)
export const isSimilar = (answer, correctAnswer, tolerance = 2) => {
  const distance = levenshteinDistance(answer, correctAnswer)
  return distance <= tolerance // Toleranz für Tippfehler einstellen
}
