import React, { useEffect, useState, useRef } from 'react'

const TextQuestion = ({
  question,
  onAnswerSubmit,
  isWrong,
  handleNextQuestion,
  mode
}) => {
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false)
  const inputRef = useRef(null) // Add reference for the input field

  useEffect(() => {
    const handleEnterKey = event => {
      if (event.key === 'Enter') {
        if (isWrong) {
          handleNextQuestion() // Go to the next question
        } else {
          event.preventDefault() // Prevent form from reloading on enter key
          document.getElementById('submit-button').click() // Trigger submit button click
        }
      }
    }

    // Add event listener for Enter key
    document.addEventListener('keydown', handleEnterKey)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleEnterKey)
    }
  }, [isWrong, handleNextQuestion])

  useEffect(() => {
    if (isWrong) {
      setShowCorrectAnswer(true) // Set state to show the correct answer if the answer was wrong
    } else {
      setShowCorrectAnswer(false) // Hide the correct answer when correct or not yet answered
    }
  }, [isWrong])

  const handleSubmit = e => {
    e.preventDefault()
    onAnswerSubmit(e)

    if (mode === 'test') {
      // Clear the input field
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
  }

  return (
    <div className='textQuestion'>
      <div className='questionAndNumberDiv'>
        <h4 className='questionTitle'>{question.question}</h4>
        <p style={{ color: '#333333' }}>{question.questionNumber}</p>
      </div>

      {/* Render the image if imageUrl exists */}
      {question.imageUrl && (
        <div className='questionImageContainer'>
          <img
            src={question.imageUrl}
            alt='Question related'
            className='questionImage'
          />
        </div>
      )}

      <form onSubmit={handleSubmit} className='textQuestionForm'>
        <input
          type='text'
          name='answer'
          className='textInput'
          placeholder='Antwort ...'
          required
          id='answerFieldText'
          ref={inputRef} // Attach the reference
          {...(isWrong && {
            style: {
              transition: 'ease-in-out 0.1s',
              borderColor: 'red',
              borderWidth: '2px',
              color: 'red'
            }
          })}
        />
        {showCorrectAnswer && (
          <div
            className='textInput'
            style={{ borderColor: 'green', border: '2px solid green' }}
          >
            <p style={{ margin: '0' }}>
              <strong>Richtige Antwort:</strong> {question.correctAnswer}
            </p>
          </div>
        )}
        <button type='submit' id='submit-button' className='submitButton'>
          Weiter
        </button>
      </form>
    </div>
  )
}

export default TextQuestion
