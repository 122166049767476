import { doc, getDoc, setDoc, updateDoc, deleteDoc } from 'firebase/firestore' // Firestore functions
import { db } from '../firebaseConfig' // Your Firestore configuration

// Function to save progress
export const handleProgressDecrease = async (userId, questionId) => {
  try {
    const progressDocRef = doc(
      db,
      'users',
      userId,
      'wrongQuestions',
      questionId
    )
    const progressSnap = await getDoc(progressDocRef)

    if (progressSnap.exists()) {
      const progressData = progressSnap.data()

      if (progressData.timesWrong > 1) {
        await updateDoc(progressDocRef, {
          timesWrong: progressData.timesWrong - 1
        })
      } else if (progressData.timesWrong === 1) {
        await deleteDoc(progressDocRef)
      }
    } else {
      console.warn('No progress data found for question:', questionId)
    }
  } catch (error) {
    console.error('Error saving progress:', error)
  }
}

export const saveProgress = async (
  userId,
  questionId,
  categoryName,
  isCorrect,
  selectedCatalog
) => {
  try {
    const progressDocRef = doc(db, 'users', userId, 'progress', questionId)

    // Check if the progress document exists
    const progressSnap = await getDoc(progressDocRef)

    let progressData
    if (progressSnap.exists()) {
      // If progress exists, update it
      progressData = progressSnap.data()
      if (isCorrect) {
        progressData.timesRight = (progressData.timesRight || 0) + 1
      } else {
        progressData.timesWrong = (progressData.timesWrong || 0) + 1
      }
      await updateDoc(progressDocRef, progressData)
    } else {
      // If no progress exists, create a new document
      progressData = {
        timesRight: isCorrect ? 1 : 0,
        timesWrong: isCorrect ? 0 : 1,
        questionCategory: categoryName,
        questionId: questionId,
        catalog: selectedCatalog
      }
      await setDoc(progressDocRef, progressData)
    }
  } catch (error) {
    console.error('Error saving progress:', error)
  }
}
