import React, { useEffect, useState } from 'react'
import './style/CategoryList.css'
import { useData } from '../context/DataContext'

const CategoryList = ({ listName, selectedCatalog }) => {
  const { questions, wrongQuestions } = useData() // Access questions from the context
  const [filteredQuestions, setFilteredQuestions] = useState([]) // Filter questions to only include those in the current category (listName)
  // Filter questions to only include those in the current category (listName)
  // Sort by question number

  const [isImageFullscreen, setIsImageFullscreen] = useState(false)
  const [fullscreenImageUrl, setFullscreenImageUrl] = useState('')

  const handleImageClick = imageUrl => {
    setFullscreenImageUrl(imageUrl)
    setIsImageFullscreen(true)
  }

  const handleCloseFullscreen = () => {
    setIsImageFullscreen(false)
    setFullscreenImageUrl('')
  }

  useEffect(() => {
    if (listName === 'Falsche Fragen') {
      const wrongQuestionIds = wrongQuestions.map(
        question => question.questionId
      )

      const filteredQuestions = questions.filter(question =>
        wrongQuestionIds.includes(question.id)
      )
      setFilteredQuestions(filteredQuestions)
    } else {
      const filteredQuestions = questions
        .filter(question => question.category === listName)
        .sort((a, b) => a.questionNumber - b.questionNumber) // Sort by question number
      setFilteredQuestions(filteredQuestions)
    }
  }, [questions, wrongQuestions])
  return (
    <div className='categoryList'>
      {isImageFullscreen && (
        <div className='fullscreenOverlay' onClick={handleCloseFullscreen}>
          <img
            src={fullscreenImageUrl}
            alt='Fullscreen related to question'
            className='fullscreenImage'
          />
        </div>
      )}
      <h2>{listName}</h2>
      {/* Render the list of questions based on their type */}
      {filteredQuestions.length > 0 ? (
        <div className='questionDivList'>
          {filteredQuestions.map(question => (
            <div className='innerQuestionDivList' key={question.id}>
              {/* Check the type of the question and render accordingly */}
              {question.type === 'text' ? (
                <div className='singleQuestionDivList'>
                  <div className='questionAndNumberDiv'>
                    <p style={{ marginBottom: '1rem' }}>{question.question}</p>
                    <p>{question.questionNumber}</p>
                  </div>
                  <p className='singleQuestionListAnswer'>
                    {question.correctAnswer}
                  </p>
                </div>
              ) : question.type === 'multiple' ? (
                <div className='singleQuestionDivList'>
                  <div className='questionAndNumberDiv'>
                    <p style={{ marginBottom: '1rem' }}>{question.question}</p>
                    <p>{question.questionNumber}</p>
                  </div>
                  {question.answers.map((answer, index) => (
                    <div
                      style={{ marginTop: '0' }}
                      className={`answerDivList ${
                        index + 1 === question.correctAnswer
                          ? 'correctAnswer'
                          : 'wrongAnswer'
                      }`}
                      key={index}
                    >
                      {answer}
                    </div>
                  ))}
                </div>
              ) : question.type === 'dnd' ? (
                <div className='singleQuestionDivList'>
                  <div className='questionAndNumberDiv'>
                    <p style={{ marginBottom: '1rem' }}>{question.question}</p>
                    <p>{question.questionNumber}</p>
                  </div>
                  {question.block.map((answer, index) => (
                    <div
                      style={{ marginTop: '0' }}
                      className='answerDivList correctAnswer'
                      key={index}
                    >
                      {answer}
                    </div>
                  ))}
                </div>
              ) : question.type === 'fillInTheBlanks' ? (
                <div className='singleQuestionDivList'>
                  <div className='questionAndNumberDiv'>
                    <p className='singleQuestionListQuestion'>
                      {question.question}
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      {question.questionNumber}
                    </p>
                  </div>
                  {question.fillInTheBlanksQuestion
                    .split(' ')
                    .map((answer, index) => (
                      <div
                        style={{ marginTop: '0' }}
                        className={`answerDivList ${
                          question.selectedBlanks.includes(index)
                            ? 'correctAnswer'
                            : 'normalNotBlankField'
                        }`}
                        key={index}
                      >
                        {answer}
                      </div>
                    ))}
                </div>
              ) : question.type === 'lap' ? (
                <div className='singleQuestionDivList'>
                  <div className='questionAndNumberDiv'>
                    <p className='singleQuestionListQuestion'>
                      {question.question}
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      {question.questionNumber}
                    </p>
                  </div>
                  <div className='answerDivListLap'>
                    {question.imageUrl && (
                      <img
                        style={{ width: '100%' }}
                        src={question.imageUrl}
                        alt='Question related'
                        className='lapImage'
                        onClick={() => handleImageClick(question.imageUrl)} // Enable fullscreen on click
                      />
                    )}
                    <h2 style={{ color: 'white ', marginBottom: '0' }}>
                      {question.heading}
                    </h2>
                    <h3
                      style={{
                        textAlign: 'center',
                        color: 'white',
                        fontSize: '1rem'
                      }}
                    >
                      {question.task}
                    </h3>
                    {question.themes.map((theme, index) => (
                      <div
                        style={{ marginTop: '0' }}
                        className='innerAnswerDivListLap'
                        key={index}
                      >
                        <p>
                          <strong>{theme}</strong>
                        </p>
                        <p>{question.themeAnswers[index]}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : question.type === 'multipleAnswers' ? (
                <div className='singleQuestionDivList'>
                  <div className='questionAndNumberDiv'>
                    <p className='singleQuestionListQuestion'>
                      {question.question}
                    </p>
                    <p style={{ marginBottom: '1rem' }}>
                      {question.questionNumber}
                    </p>
                  </div>
                  <div className='answerDivListLap'>
                    {question.answers.map((theme, index) => (
                      <div
                        style={{ marginTop: '0' }}
                        className='innerAnswerDivListLap'
                        key={index}
                      >
                        <p>
                          <strong>{theme}</strong>
                        </p>
                        <p>{question.themeAnswers[index]}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>Unbekannter Fragetyp</p>
              )}
            </div>
          ))}
        </div>
      ) : (
        <p>Keine Fragen in dieser Kategorie.</p>
      )}
    </div>
  )
}

export default CategoryList
