import React, { useState } from 'react'

const ImageQuestion = ({ data, onAnswerSubmit, isWrong }) => {
  const { imageUrl, question, options, correctAnswer } = data
  const [selectedOption, setSelectedOption] = useState('')

  const handleAnswerChange = e => {
    setSelectedOption(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const isCorrect = selectedOption === correctAnswer // Überprüfung der Antwort
    onAnswerSubmit({ answer: selectedOption, isCorrect }) // Übergebe die Antwort und ob sie korrekt ist
    setSelectedOption('') // Auswahl nach dem Absenden löschen
  }

  return (
    <div className='imageQuestion'>
      <img src={imageUrl} alt='Fragebild' className='questionImage' />
      <p>{question}</p>
      <form onSubmit={handleSubmit}>
        {options.map((option, index) => (
          <div key={index}>
            <input
              type='radio'
              id={`option-${index}`}
              name='answer'
              value={option}
              checked={selectedOption === option}
              onChange={handleAnswerChange}
              required
            />
            <label htmlFor={`option-${index}`}>{option}</label>
          </div>
        ))}
        <button type='submit'>Weiter</button>
      </form>

      {/* Optional: Zeige das Feedback an, wenn die Antwort falsch ist */}
      {isWrong && (
        <div className='feedback'>
          <p>Falsche Antwort! Die richtige Antwort ist: {correctAnswer}</p>
        </div>
      )}
    </div>
  )
}

export default ImageQuestion
