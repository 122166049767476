// context/UserContext.js
import React, { createContext, useContext, useEffect, useState } from 'react'
import { auth, db } from '../firebaseConfig'
import { doc, getDoc } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth'

// Erstellen des UserContext
const UserContext = createContext()

export const useUser = () => useContext(UserContext)

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)

  // Funktion zum Abrufen der Benutzerdaten aus Firestore
  const fetchUserData = async userId => {
    if (!userId) return

    try {
      const userDoc = await getDoc(doc(db, 'users', userId))
      if (userDoc.exists()) {
        setUserData(userDoc.data())
      } else {
        console.log('No user document found!')
        setUserData({}) // Sicherstellen, dass userData immer ein Objekt ist
      }
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setUser(user)
      if (user) {
        await fetchUserData(user.uid)
      } else {
        setUserData(null)
      }
      setLoading(false)
    })

    // Aufräumen
    return () => unsubscribe()
  }, [])

  return (
    <UserContext.Provider value={{ user, userData, loading }}>
      {!loading && children}
    </UserContext.Provider>
  )
}
