import React from 'react'

import WhatshotIcon from '@mui/icons-material/Whatshot' // Feuer-Icon für Streak

const FeedbackDisplay = ({ feedback, streak }) => {
  return (
    <>
      <div className='feedbackDisplay'>
        {feedback && (
          <div
            className={`feedbackIndicationDiv ${
              feedback === 'Correct!' ? 'correct' : 'incorrect'
            }`}
          >
            <p style={{ margin: '0', color: 'black' }}>{feedback}</p>
          </div>
        )}
        <div className='feedbackStreakDiv'>
          <WhatshotIcon className='streakIcon' />
          <p style={{ margin: '0' }}>Streak: {streak}</p>
        </div>
      </div>
    </>
  )
}

export default FeedbackDisplay
