import React, { useState } from 'react'
import { doc, collection, addDoc, Timestamp, getDocs } from 'firebase/firestore'
import { db } from '../firebaseConfig'
import './style/ReviewModule.css'
import { useUser } from '../context/UserContext'

const ReviewModule = ({ catalog, onClose }) => {
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const { user, userData } = useUser()

  const handleSubmit = async () => {
    if (rating === 0) {
      alert('Bitte geben Sie eine Bewertung ab.')
      return
    }

    try {
      const reviewsCollectionRef = collection(
        doc(db, 'questions', catalog.id),
        'reviews'
      )

      const reviewsSnapshot = await getDocs(reviewsCollectionRef)
      const userReviewExists = reviewsSnapshot.docs.some(
        doc => doc.data().userId === user?.uid
      )

      if (userReviewExists) {
        alert('Du hast diesen Katalog bereits bewertet.')
        return
      }

      await addDoc(reviewsCollectionRef, {
        rating: rating,
        comment: comment.trim() || '', // Optionales Kommentarfeld
        userId: user?.uid,
        createdAt: Timestamp.now(),
        username: userData.username
      })

      setSuccessMessage('Bewertung erfolgreich gespeichert!')
      setRating(0)
      setComment('')

      setTimeout(() => {
        setSuccessMessage('')
        onClose()
      }, 3000)
    } catch (error) {
      console.error('Fehler beim Speichern der Bewertung:', error)
      alert('Fehler beim Speichern der Bewertung.')
    }
  }

  const handleStarClick = star => {
    setRating(star)
  }

  return (
    <div className='review-module'>
      <button className='close-button' onClick={onClose}>
        ✕
      </button>
      <h3>Bewertung abgeben</h3>
      <div className='rating'>
        <label>Bewertung:</label>
        <div className='stars'>
          {[1, 2, 3, 4, 5].map(star => (
            <span
              key={star}
              className={`star ${star <= rating ? 'selected' : ''}`}
              onClick={() => handleStarClick(star)}
            >
              ★
            </span>
          ))}
        </div>
      </div>
      <div className='comment'>
        <label>
          Kommentar (optional):
          <textarea
            value={comment}
            onChange={e => setComment(e.target.value)}
            placeholder='Teile deine Gedanken über diesen Katalog...'
          ></textarea>
        </label>
      </div>
      <button onClick={handleSubmit} className='submit-button-rewiev'>
        Bewertung absenden
      </button>

      {successMessage && <p className='success-message'>{successMessage}</p>}
    </div>
  )
}

export default ReviewModule
