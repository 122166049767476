import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { useUser } from '../context/UserContext'
import './Navbar.css'
import AddIcon from '@mui/icons-material/Add'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../firebaseConfig'
import getUnreadMessages from '../utils/navbarUtils'
import brandIcon from '../assets/icon.png'

const Navbar = ({ selectedCatalog, setSelectedCatalog }) => {
  const { user, userData } = useUser()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [catalogNames, setCatalogNames] = useState({})
  const [admin, setAdmin] = useState([])
  const [unreadMessages, setUnreadMessages] = useState([]) // Hook für ungelesene Nachrichten
  const location = useLocation() // Aktuelle URL

  useEffect(() => {
    const fetchUnreadMessages = async () => {
      if (user) {
        const count = await getUnreadMessages(user)
        setUnreadMessages(count) // Den Zustand mit der aufgelösten Anzahl aktualisieren
      }
    }

    fetchUnreadMessages()
  }, [user, location.pathname])

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
    document.body.classList.toggle('menu-open', isMenuOpen)
  }

  const isValidCatalog = catalogId => {
    // Check if it's a valid regular catalog
    if (userData?.catalogs?.includes(catalogId)) {
      return true
    }

    // Check if it's a valid demo catalog and the endDate hasn't passed
    const demoCatalog = userData?.demos?.find(
      demo => demo.catalogId === catalogId
    )
    if (demoCatalog) {
      const currentDate = new Date()
      let endDate

      // Handle Firestore Timestamp or Date string
      if (
        demoCatalog.endDate &&
        typeof demoCatalog.endDate.toDate === 'function'
      ) {
        endDate = demoCatalog.endDate.toDate()
      } else {
        endDate = new Date(demoCatalog.endDate)
      }

      return currentDate < endDate
    }

    // If not found in catalogs or as a valid demo, return false
    return false
  }

  useEffect(() => {
    const fetchCatalogData = async () => {
      try {
        const catalogRef = doc(db, 'questions', selectedCatalog)
        const catalogSnap = await getDoc(catalogRef)

        if (catalogSnap.exists()) {
          const adminIDs = catalogSnap.data().admin || []
          setAdmin(adminIDs)
        }
      } catch (e) {
        console.log('Error:', e)
      }
    }

    if (selectedCatalog) {
      fetchCatalogData()
    }
  }, [selectedCatalog])

  useEffect(() => {
    const fetchCatalogNames = async () => {
      const names = {}
      const currentDate = new Date()

      // Fetch user catalogs
      for (const catalog of userData?.catalogs || []) {
        const catalogDoc = doc(db, 'questions', catalog)
        const docSnap = await getDoc(catalogDoc)
        if (docSnap.exists()) {
          names[catalog] = docSnap.data().name || 'Katalogname nicht gefunden'
        }
      }

      // Fetch demo catalogs if endDate hasn't passed
      for (const demo of userData?.demos || []) {
        const demoCatalogId = demo.catalogId
        let endDate

        if (demo.endDate && typeof demo.endDate.toDate === 'function') {
          endDate = demo.endDate.toDate()
        } else {
          endDate = new Date(demo.endDate)
        }

        if (currentDate < endDate) {
          const demoCatalogDoc = doc(db, 'questions', demoCatalogId)
          const demoDocSnap = await getDoc(demoCatalogDoc)
          if (demoDocSnap.exists()) {
            names[demoCatalogId] =
              demoDocSnap.data().name || 'Demokatalogname nicht gefunden'
          }
        }
      }

      setCatalogNames(names)

      // Set default catalog from localStorage or lastCatalog
      const savedCatalog = localStorage.getItem('selectedCatalog')
      if (savedCatalog && names[savedCatalog]) {
        setSelectedCatalog(savedCatalog)
      } else if (userData?.lastCatalog && names[userData.lastCatalog]) {
        setSelectedCatalog(userData.lastCatalog)
      } else if (Object.keys(names).length > 0) {
        setSelectedCatalog(Object.keys(names)[0])
        localStorage.removeItem('selectedCatalog')
      }
    }

    if (userData?.catalogs || userData?.demos) {
      fetchCatalogNames()
    }
  }, [userData, setSelectedCatalog])

  const handleCatalogChange = e => {
    const newCatalog = e.target.value
    setSelectedCatalog(newCatalog)
    localStorage.setItem('selectedCatalog', newCatalog)
  }

  return (
    <nav>
      <div className='navbar-container'>
        <div className='brand'>
          <Link to={'/addCatalog'} className='addIconHover'>
            <img
              src={brandIcon}
              alt='Logo'
              style={{
                height: '5vh',
                width: '5vh',
                objectFit: 'contain'
              }}
            />
          </Link>
          {Object.keys(catalogNames).length > 0 && (
            <select value={selectedCatalog} onChange={handleCatalogChange}>
              {Object.keys(catalogNames).map(catalog => (
                <option key={catalog} value={catalog}>
                  {catalogNames[catalog] || 'Lädt...'}
                </option>
              ))}
            </select>
          )}
        </div>
        <div className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <ul>
            <li>
              <Link onClick={toggleMenu} to='/dashboard'>
                Dashboard
              </Link>
            </li>
            {(userData?.catalogs?.includes(selectedCatalog) ||
              admin.includes(user.uid) ||
              isValidCatalog(selectedCatalog)) && (
              <>
                <li>
                  <Link onClick={toggleMenu} to='/learn'>
                    Lernen
                  </Link>
                </li>
                <li>
                  <Link onClick={toggleMenu} to='/test'>
                    Test
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link onClick={toggleMenu} to='/chats' className='chat-link'>
                <span style={{ color: 'white', zIndex: '100' }}>Chat</span>

                {unreadMessages > 0 && (
                  <span className='notification-badge'>{unreadMessages}</span>
                )}
              </Link>
            </li>
            {admin.includes(user.uid) && (
              <li>
                <Link onClick={toggleMenu} to='/questions'>
                  Fragen
                </Link>
              </li>
            )}
            <li>
              <Link onClick={toggleMenu} to='/profile'>
                Profil
              </Link>
            </li>
          </ul>
        </div>
        <div className='burger-menu' onClick={toggleMenu}>
          <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
          <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
