import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyA8JP8Xhg6ub5rG47BEY66qRU6vyuyAaB4',
  authDomain: 'learnlap-c7184.firebaseapp.com',
  projectId: 'learnlap-c7184',
  storageBucket: 'learnlap-c7184.appspot.com',
  messagingSenderId: '452344755619',
  appId: '1:452344755619:web:069870feb3aefd1c9d5616',
  measurementId: 'G-095XHL3V9V'
}

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app) // Firestore hinzufügen
