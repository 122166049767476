// src/pages/Test.js
import React, { useState } from 'react'
import Select from '../components/test/Select'
import Test from '../components/test/Test'
import InfoIcon from '@mui/icons-material/Info'
import CancelIcon from '@mui/icons-material/Cancel'

const TestPage = ({ selectedCatalog }) => {
  const [selectedChapters, setSelectedChapters] = useState([])
  const [questionsPerChapter, setQuestionsPerChapter] = useState(5) // Initialisiere Fragen/Kapitel
  const [infoActive, setInfoActive] = useState(false)

  const handleSelect = (chapters, questions) => {
    setSelectedChapters(chapters)
    setQuestionsPerChapter(questions) // Speichere auch die Anzahl der Fragen
  }

  return (
    <div
      style={{
        padding: '20px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {selectedChapters.length > 0 ? (
        <Test
          selectedChapters={selectedChapters}
          questionsPerChapter={questionsPerChapter}
          selectedCatalog={selectedCatalog}
        />
      ) : (
        <Select onSelect={handleSelect} selectedCatalog={selectedCatalog} />
      )}
      {!infoActive ? (
        <div
          className='infoDivLearn'
          onClick={() => setInfoActive(!infoActive)}
        >
          <InfoIcon style={{ color: '#ff7e5f', fontSize: '2rem' }} />
        </div>
      ) : (
        <div
          className='infoDivLearn infoDivLearnActive'
          onClick={() => setInfoActive(!infoActive)}
        >
          <p
            style={{
              maxWidth: '600px',
              color: 'black',
              textAlign: 'center'
            }}
          >
            Hier können Sie Ihren Wissensstand überprüfen und Problemfragen
            herrausfinden! <br /> Am besten benutzen Sie diesen Modus nachdem
            Sie sich das Wissen schon in der <strong>Learn</strong> Page
            angeeignet haben!
          </p>
          <CancelIcon style={{ color: 'black' }} />
        </div>
      )}
    </div>
  )
}

export default TestPage
