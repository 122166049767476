import React, { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../firebaseConfig'
import { Link } from 'react-router-dom'
import Loading from '../components/Loading'
import logo from '../assets/icon.png'
import './Login.css'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errorMessage, setErrorMessage] = useState('') // Zustand für Fehlermeldung
  const [isLoading, setIsLoading] = useState(false)

  const handleLogin = async () => {
    try {
      setErrorMessage('') // Fehler zurücksetzen
      setIsLoading(true) // Ladezustand setzen

      await signInWithEmailAndPassword(auth, email, password)
      setIsLoading(false) // Ladezustand zurücksetzen
    } catch (error) {
      setIsLoading(false) // Ladezustand zurücksetzen
      console.error('Login Error: ', error)

      // Fehlercodes auswerten und benutzerfreundliche Nachricht anzeigen
      switch (error.code) {
        case 'auth/user-not-found':
          setErrorMessage('Kein Benutzer mit dieser E-Mail-Adresse gefunden.')
          break
        case 'auth/wrong-password':
          setErrorMessage('Falsches Passwort. Bitte versuchen Sie es erneut.')
          break
        case 'auth/invalid-email':
          setErrorMessage('Die E-Mail-Adresse ist ungültig.')
          break
        case 'auth/user-disabled':
          setErrorMessage('Dieses Konto wurde deaktiviert.')
          break
        default:
          setErrorMessage(
            'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.'
          )
      }
    }
  }

  return (
    <div className='login-container'>
      <div className='login-content'>
        <img src={logo} alt='Logo' className='login-logo' />
        <h2>Login</h2>
        {errorMessage && <p className='error-message'>{errorMessage}</p>}{' '}
        {/* Fehlernachricht anzeigen */}
        <div className='input-group'>
          <input
            type='email'
            placeholder='Email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            className='input-field'
          />
          <input
            type='password'
            placeholder='Passwort'
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={e => e.key === 'Enter' && handleLogin()} // Submit on Enter
            className='input-field'
          />
        </div>
        <div className='button-group'>
          <button className='loginButton' onClick={handleLogin}>
            Login
          </button>
          <Link to='/register' className='registerLink'>
            Registrieren
          </Link>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  )
}

export default Login
