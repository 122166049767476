import React, { useState, useEffect } from 'react'
import {
  collection,
  addDoc,
  Timestamp,
  updateDoc,
  setDoc,
  doc,
  getDoc
} from 'firebase/firestore' // Firestore Funktionen
import { db } from '../../firebaseConfig'
import { useUser } from '../../context/UserContext'
import { useData } from '../../context/DataContext'

import { isSimilar } from '../../utils/categoryUtils'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { isTouchDevice } from '../../utils/utils'

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

import MultipleChoiceQuestion from '../questionsComponents/MultipleChoiceQuestion'
import TextQuestion from '../questionsComponents/TextQuestion'
import LapQuestion from '../questionsComponents/LapQuestion'
import DndQuestion from '../questionsComponents/DndQuestion'
import FillInTheBlankQuestion from '../questionsComponents/FillInTheBlankQuestion'
import Loading from '../Loading'
import MultipleAnswers from '../questionsComponents/MultipleAnswers'

import './Test.css'

const Test = ({ selectedChapters, questionsPerChapter, selectedCatalog }) => {
  const { questions } = useData() // Access questions from context
  const [filteredQuestions, setFilteredQuestions] = useState([])

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [allAnswered, setAllAnswered] = useState(false)
  const [correctAnswers, setCorrectAnswers] = useState(0)
  const [incorrectQuestions, setIncorrectQuestions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [infoActive, setInfoActive] = useState(false)

  const { user } = useUser()

  // Funktion zur Auswahl von zufälligen Fragen
  const getRandomQuestions = (questionsArray, num) => {
    const shuffled = [...questionsArray].sort(() => 0.5 - Math.random()) // Shuffle the array randomly
    return shuffled.slice(0, num) // Return the first "num" questions
  }

  useEffect(() => {
    setIsLoading(true)

    // Filter questions based on the selected chapters
    let selectedQuestions = []
    selectedChapters.forEach(chapter => {
      const chapterQuestions = questions.filter(
        question => question.category === chapter
      )
      const randomQuestions = getRandomQuestions(
        chapterQuestions,
        questionsPerChapter
      )
      selectedQuestions = [...selectedQuestions, ...randomQuestions]
    })

    // Shuffle the selected questions
    const shuffledQuestions = selectedQuestions.sort(() => 0.5 - Math.random())
    setFilteredQuestions(shuffledQuestions)
    setIsLoading(false)
  }, [selectedChapters, questionsPerChapter, selectedCatalog, questions])

  const handleDndAnswerSubmit = async blocks => {
    setIsLoading(true)

    const currentQuestion = filteredQuestions[currentQuestionIndex]

    let isCorrect = true

    for (let i = 0; i < blocks.length; i++) {
      if (blocks[i] !== currentQuestion.block[i]) {
        isCorrect = false
        break
      }
    }

    if (isCorrect) {
      setCorrectAnswers(prev => prev + 1) // Increment correct answer count
    } else {
      setIncorrectQuestions(prev => [...prev, currentQuestion])
      await saveWrongQuestion(currentQuestion) // Save wrong question with provided answers
    }

    // Move to the next question
    if (currentQuestionIndex < filteredQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else {
      setAllAnswered(true)
      await saveTestResults(correctAnswers + (isCorrect ? 1 : 0)) // Korrekte Antworten in die Funktion mitgeben
    }
    setIsLoading(false)
  }

  const handleMultipleAnswersSubmit = async answer => {
    setIsLoading(true)

    let isCorrect = false
    const currentQuestion = filteredQuestions[currentQuestionIndex]

    if (
      answer.length === currentQuestion.answers.length &&
      answer.every(a =>
        currentQuestion.answers.some(b =>
          isSimilar(a.toLowerCase().trim(), b.toLowerCase().trim(), 1.8)
        )
      ) &&
      currentQuestion.answers.every(b =>
        answer.some(a =>
          isSimilar(a.toLowerCase().trim(), b.toLowerCase().trim(), 1.8)
        )
      )
    ) {
      isCorrect = true
    }

    if (isCorrect) {
      setCorrectAnswers(prev => prev + 1) // Increment correct answer count
    } else {
      setIncorrectQuestions(prev => [...prev, currentQuestion])
      await saveWrongQuestion(currentQuestion) // Save wrong question with provided answers
    }

    // Move to the next question
    if (currentQuestionIndex < filteredQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else {
      setAllAnswered(true)
      await saveTestResults(correctAnswers + (isCorrect ? 1 : 0)) // Korrekte Antworten in die Funktion mitgeben
    }
    setIsLoading(false)
  }

  const handleLapAnswerSubmit = async lapData => {
    setIsLoading(true)
    const currentQuestion = filteredQuestions[currentQuestionIndex]
    const selfEvaluations = lapData.selfEvaluations

    let isCorrect = true // Assume the answer is correct initially

    // If any of the evaluations are false, mark the answer as incorrect
    if (selfEvaluations.includes(false)) {
      isCorrect = false
    }

    if (isCorrect) {
      setCorrectAnswers(prev => prev + 1) // Increment correct answer count
    } else {
      setIncorrectQuestions(prev => [...prev, currentQuestion])
      await saveWrongQuestion(currentQuestion) // Save wrong question with provided answers
    }

    // Move to the next question
    if (currentQuestionIndex < filteredQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else {
      setAllAnswered(true)
      await saveTestResults(correctAnswers + (isCorrect ? 1 : 0)) // Korrekte Antworten in die Funktion mitgeben
    }
    setIsLoading(false)
  }

  const handleFillInTheBlankAnswerSubmit = async answer => {
    setIsLoading(true)
    const currentQuestion = filteredQuestions[currentQuestionIndex]
    const correctAnswer = currentQuestion.fillInTheBlanksQuestion

    let isCorrect = true // Assume the answer is correct initially

    // If any of the evaluations are false, mark the answer as incorrect

    isCorrect = isSimilar(answer, correctAnswer, 1.8)

    if (isCorrect) {
      setCorrectAnswers(prev => prev + 1) // Increment correct answer count
    } else {
      setIncorrectQuestions(prev => [...prev, currentQuestion])
      await saveWrongQuestion(currentQuestion) // Save wrong question with provided answers
    }

    // Move to the next question
    if (currentQuestionIndex < filteredQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else {
      setAllAnswered(true)
      await saveTestResults(correctAnswers + (isCorrect ? 1 : 0)) // Korrekte Antworten in die Funktion mitgeben
    }
    setIsLoading(false)
  }

  const handleMultipleChoiceSubmit = async answer => {
    setIsLoading(true)
    const correctAnswersIndexes =
      filteredQuestions[currentQuestionIndex].correctAnswer
    const currentQuestion = filteredQuestions[currentQuestionIndex]

    let isCorrect = false

    if (
      answer.length === correctAnswersIndexes.length && // Gleiche Anzahl von Elementen
      answer.every(index => correctAnswersIndexes.includes(index)) && // Alle ausgewählten Indizes sind in den korrekten enthalten
      correctAnswersIndexes.every(index => answer.includes(index)) // Alle korrekten Indizes sind ausgewählt
    ) {
      isCorrect = true
    }

    if (isCorrect) {
      setCorrectAnswers(prev => prev + 1) // Increment correct answer count
    } else {
      setIncorrectQuestions(prev => [...prev, currentQuestion])
      await saveWrongQuestion(currentQuestion) // Save wrong question with provided answers
    }

    // Move to the next question
    if (currentQuestionIndex < filteredQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else {
      setAllAnswered(true)
      await saveTestResults(correctAnswers + (isCorrect ? 1 : 0)) // Korrekte Antworten in die Funktion mitgeben
    }
    setIsLoading(false)
  }

  const handleAnswerSubmit = async e => {
    setIsLoading(true)

    const answer = e.target.answer.value.trim().toLowerCase()
    const currentQuestion = filteredQuestions[currentQuestionIndex]

    let isCorrect = false

    if (currentQuestion.type === 'text') {
      const solutionText = currentQuestion?.correctAnswer?.toLowerCase().trim()

      isCorrect = isSimilar(answer, solutionText, 2)
    }

    if (isCorrect) {
      setCorrectAnswers(prev => prev + 1)
    } else {
      setIncorrectQuestions(prev => [...prev, currentQuestion])
      await saveWrongQuestion(currentQuestion)
    }

    if (currentQuestionIndex < filteredQuestions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1)
    } else {
      setAllAnswered(true)
      await saveTestResults(correctAnswers + (isCorrect ? 1 : 0)) // Korrekte Antworten in die Funktion mitgeben
    }
    setIsLoading(false)
  }

  const saveTestResults = async correctAnswerCount => {
    setIsLoading(true)
    const totalQuestionsCount = filteredQuestions.length
    const wrongAnswersCount = totalQuestionsCount - correctAnswerCount

    const testResult = {
      createdAt: Timestamp.now(),
      questionCategorie: selectedChapters.join(', '),
      questions: totalQuestionsCount,
      timesRight: correctAnswerCount, // Anzahl der richtigen Antworten
      timesWrong: wrongAnswersCount, // Anzahl der falschen Antworten
      catalog: selectedCatalog
    }

    try {
      await addDoc(collection(db, `users/${user.uid}/testResults`), testResult)
    } catch (error) {
      console.error('Fehler beim Speichern des Testergebnisses:', error)
    }
    setIsLoading(false)
  }

  // Funktion zum Speichern der falschen Fragen in einer separaten Collection
  const saveWrongQuestion = async question => {
    const wrongQuestionRef = doc(
      db,
      `users/${user.uid}/wrongQuestions`,
      question.id
    )

    // Überprüfen, ob die Frage bereits existiert
    const wrongQuestionSnapshot = await getDoc(wrongQuestionRef)

    if (wrongQuestionSnapshot.exists()) {
      // Wenn die Frage bereits existiert, timesWrong erhöhen
      const existingData = wrongQuestionSnapshot.data()
      const updatedTimesWrong = (existingData.timesWrong || 0) + 1

      try {
        await updateDoc(wrongQuestionRef, {
          timesWrong: updatedTimesWrong // timesWrong um eins erhöhen
        })
      } catch (error) {
        console.error('Fehler beim Aktualisieren der falschen Frage:', error)
      }
    } else {
      // Wenn die Frage noch nicht existiert, als neue Frage hinzufügen
      const wrongQuestionData = {
        questionId: question.id,
        questionText: question.question,
        correctAnswer: question.correctAnswer,
        questionCategory: question.category,
        questionNumber: question.questionNumber || null,
        answers: question.answers || [],
        answeredAt: Timestamp.now(),
        timesWrong: 1, // timesWrong initialisieren
        task: question.task || null,
        type: question.type,
        blanks: question.selectedBlanks || null,
        fillInTheBlanksQuestion: question.fillInTheBlanksQuestion || null,
        catalog: selectedCatalog
      }

      try {
        await setDoc(wrongQuestionRef, wrongQuestionData)
      } catch (error) {
        console.error('Fehler beim Speichern der falschen Frage:', error)
      }
    }
  }

  const calculatePercentage = () => {
    return Math.round((correctAnswers / filteredQuestions.length) * 100)
  }

  return (
    <div className='testDiv'>
      {allAnswered ? (
        <div className='testResultContainer'>
          <h1>Test abgeschlossen!</h1>
          <div className='resultSummary'>
            <p className='resultText'>
              Du hast {correctAnswers} von {filteredQuestions.length} Fragen
              richtig beantwortet.
            </p>
            <p className='percentageText'>Ergebnis: {calculatePercentage()}%</p>
          </div>

          {incorrectQuestions.length > 0 ? (
            <div className='incorrectQuestionsContainer'>
              <h2>Fragen, bei denen du Probleme hattest:</h2>
              <ul className='incorrectQuestionsList'>
                {incorrectQuestions.map((question, index) =>
                  question.type === 'lap' ? (
                    // Add JSX to render for "lap" type questions here
                    <li key={index} className='incorrectQuestionItem'>
                      <strong>Frage {question.questionNumber}:</strong>{' '}
                      {question.question}
                      <strong>{question.heading}</strong>
                      <p
                        style={{
                          marginBottom: '1rem',
                          color: 'black',
                          fontSize: '1rem'
                        }}
                      >
                        {' '}
                        {question.task}
                      </p>
                      <div className='lapQuestionContainer'>
                        {question.themeAnswers.map((answer, themeIndex) => (
                          <div key={themeIndex} className='themeAnswerPair'>
                            <span className='themeText'>
                              <strong>Thema {themeIndex + 1}: </strong>{' '}
                              {question.themes[themeIndex]}
                            </span>
                            <p className='incorrectLapAnswer'>
                              <strong>Korrekte Antwort:</strong> {answer}
                            </p>
                          </div>
                        ))}
                      </div>
                    </li>
                  ) : question.type === 'dnd' ? (
                    <li key={index} className='incorrectQuestionItem'>
                      <strong>Frage {question.questionNumber}:</strong>{' '}
                      {question.question}
                      <p className='correctAnswerText'>Korrekte Reihenfolge:</p>
                      <ol
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '4px',
                          paddingLeft: '6px'
                        }}
                      >
                        {question.block.map((block, index) => (
                          <li key={index} className='incorrectAnswerItem'>
                            {block}
                          </li>
                        ))}
                      </ol>
                    </li>
                  ) : question.type === 'fillInTheBlanks' ? (
                    <>
                      <li
                        key={index}
                        className='incorrectQuestionItem'
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}
                      >
                        <strong>Frage {question.questionNumber}:</strong>{' '}
                        {question.question}
                        <p style={{ marginLeft: '1rem', color: '#155724' }}>
                          {question.fillInTheBlanksQuestion}
                        </p>{' '}
                      </li>
                    </>
                  ) : question.type === 'multipleAnswers' ? (
                    <>
                      <li
                        key={index}
                        className='incorrectQuestionItem'
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}
                      >
                        <div style={{ width: '100%', marginBottom: '10px' }}>
                          <strong>Frage {question.questionNumber}:</strong>{' '}
                          {question.question}{' '}
                        </div>

                        {question.answers.map((answer, index) => (
                          <p
                            key={index}
                            style={{
                              backgroundColor: 'white',
                              width: '100%',
                              padding: '10px',
                              borderRadius: '5px',
                              margin: '3px',
                              color: '#155724'
                            }}
                          >
                            {answer}
                          </p>
                        ))}
                      </li>
                    </>
                  ) : (
                    <li key={index} className='incorrectQuestionItem'>
                      <strong>Frage {question.questionNumber}:</strong>{' '}
                      {question.question}
                      <p className='correctAnswerText'>
                        Korrekte Antwort: {question.correctAnswer}
                      </p>
                    </li>
                  )
                )}
              </ul>
            </div>
          ) : (
            <p className='allCorrectText'>
              Du hast alle Fragen korrekt beantwortet!
            </p>
          )}

          <button
            style={{ marginTop: '1rem', borderRadius: '5px' }}
            className='backButton'
            onClick={() => window.location.reload()}
          >
            Zurück!
          </button>
        </div>
      ) : filteredQuestions.length > 0 ? (
        <div className='questionItem'>
          {filteredQuestions[currentQuestionIndex]?.type === 'text' ? (
            <TextQuestion
              mode={'test'}
              question={filteredQuestions[currentQuestionIndex]}
              onAnswerSubmit={handleAnswerSubmit}
            />
          ) : filteredQuestions[currentQuestionIndex]?.type === 'multiple' ? (
            <MultipleChoiceQuestion
              question={filteredQuestions[currentQuestionIndex]}
              onAnswerSubmit={handleMultipleChoiceSubmit}
            />
          ) : filteredQuestions[currentQuestionIndex]?.type === 'lap' ? (
            <LapQuestion
              mode='test'
              data={filteredQuestions[currentQuestionIndex]}
              onAnswerSubmit={handleLapAnswerSubmit}
              isEvaluated={false}
            />
          ) : filteredQuestions[currentQuestionIndex]?.type === 'dnd' ? (
            <DndProvider
              backend={isTouchDevice() ? TouchBackend : HTML5Backend}
              options={{ enableMouseEvents: true }} // Aktiviert Mouse-Events für TouchBackend
            >
              <DndQuestion
                data={filteredQuestions[currentQuestionIndex]}
                onAnswerSubmit={handleDndAnswerSubmit}
              />
            </DndProvider>
          ) : filteredQuestions[currentQuestionIndex]?.type ===
            'fillInTheBlanks' ? (
            <FillInTheBlankQuestion
              data={filteredQuestions[currentQuestionIndex]}
              onAnswerSubmit={handleFillInTheBlankAnswerSubmit}
            />
          ) : filteredQuestions[currentQuestionIndex]?.type ===
            'multipleAnswers' ? (
            <MultipleAnswers
              question={filteredQuestions[currentQuestionIndex]}
              onAnswerSubmit={handleMultipleAnswersSubmit}
            />
          ) : (
            <div>Unknown question type</div> // Fallback for unknown question types
          )}

          <button
            className='backButtonLearn'
            style={{ marginTop: '1rem', borderRadius: '4px' }}
            onClick={() => window.location.reload()}
          >
            <KeyboardBackspaceIcon />
          </button>
        </div>
      ) : (
        <p>Keine Fragen zu den ausgewählten Kapiteln gefunden.</p>
      )}
      {isLoading && <Loading />}
    </div>
  )
}

export default Test
