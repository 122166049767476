import React, { useState, useEffect } from 'react'
import { useUser } from '../context/UserContext'
import { useData } from '../context/DataContext'

import './style/Stats.css'

const Stats = ({ selectedCatalog }) => {
  const [overallProgress, setOverallProgress] = useState(0)
  const [totalQuestions, setTotalQuestions] = useState(0)
  const [isDemo, setIsDemo] = useState(false) // Boolean to check if it's a demo version

  const { userData } = useUser()
  const { categories, questions, wrongQuestions, progress, testResults } =
    useData()

  const selectedCatalogFR = selectedCatalog.selectedCatalog

  const calculateOverallProgress = (progressList, totalQuestionsCount) => {
    let totalProgress = 0

    const progressDocs = progressList.filter(doc =>
      questions.some(question => question.id === doc.questionId)
    )

    progressDocs.forEach(doc => {
      const timesRight = Math.min(doc.timesRight, 3) // Maximaler Fortschritt bei 3 korrekten Antworten
      totalProgress += (timesRight / 3) * 100
    })

    // Gesamten Fortschritt berechnen
    if (totalQuestionsCount > 0) {
      const averageProgress = totalProgress / totalQuestionsCount
      setOverallProgress(averageProgress.toFixed(2))
    } else {
      setOverallProgress(0) // Kein Fortschritt, wenn keine Fragen vorhanden sind
    }
  }

  useEffect(() => {
    calculateOverallProgress(progress, questions.length)
    setTotalQuestions(questions.length)
  }, [progress, questions])

  useEffect(() => {
    const checkIfDemo = () => {
      if (
        userData.demos?.some(demo => demo.catalogId === selectedCatalogFR) &&
        !userData?.catalogs?.some(catalog => catalog === selectedCatalogFR)
      ) {
        setIsDemo(true)
      } else {
        setIsDemo(false) // Not a demo version if no demo start date is present
      }
    }

    if (selectedCatalog) {
      checkIfDemo()
    }
  }, [selectedCatalog, userData, selectedCatalogFR])

  // Funktion, um die Farbe einer Kategorie zu holen
  const getCategoryColor = categoryName => {
    const category = categories.find(cat => cat.name === categoryName)
    return category ? category.color : '#ccc' // Default: Grau, falls keine Kategorie gefunden
  }

  const formatTimestamp = timestamp => {
    const date = new Date(timestamp.seconds * 1000) // Convert Firestore timestamp to JS Date
    const today = new Date() // Get current date

    const isToday =
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()

    if (isToday) {
      // If the date is today, return the time in HH:MM format
      const hours = String(date.getHours()).padStart(2, '0')
      const minutes = String(date.getMinutes()).padStart(2, '0')
      return `${hours}:${minutes}`
    } else {
      // Otherwise, return the date in DD.MM.YY format
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-indexed
      const year = String(date.getFullYear()).slice(-2)
      return `${day}.${month}.${year}`
    }
  }

  // Inline styles for the progress bar
  const progressBarContainerStyle = {
    height: '24px',
    width: '100%',
    backgroundColor: '#e0e0de',
    borderRadius: '12px',
    marginTop: '20px',
    marginBottom: '0px'
  }

  const progressBarStyle = {
    height: '100%',
    width: `${overallProgress}%`,
    backgroundColor: overallProgress >= 100 ? '#4caf50' : '#2196f3', // Green if 100%, blue otherwise
    borderRadius: '12px',
    textAlign: 'center',
    lineHeight: '24px',
    color: 'white',
    transition: 'width 0.4s ease'
  }

  return (
    <div className='stats'>
      {/* Progress Bar */}
      {!isDemo && (
        <div style={progressBarContainerStyle}>
          <div style={progressBarStyle}>
            <p
              style={{
                marginLeft: '10px',
                marginTop: '0',
                marginBottom: '0',
                marginRight: '0',
                color: 'black'
              }}
            >
              {overallProgress}%
            </p>
          </div>
        </div>
      )}

      <p style={{ marginTop: '5px' }}>
        Gesamtanzahl der Fragen: {totalQuestions}
      </p>

      <h4>Testergebnisse</h4>
      {testResults.length > 0 && !isDemo ? (
        <div className='testResultsList'>
          {testResults
            .sort((a, b) => b.createdAt.seconds - a.createdAt.seconds) // Sort by createdAt descending
            .slice(0, 5) // Limit to the last 5 results
            .map(result => (
              <div key={result.id} className='testResult'>
                <h5>{result.questionCategorie}</h5>
                <div className='testResultInnerDiv'>
                  {/* Prozentergebnis */}
                  <p
                    style={{
                      color:
                        (result.timesRight /
                          (result.timesRight + result.timesWrong)) *
                          100 <
                        50
                          ? 'red'
                          : (result.timesRight /
                              (result.timesRight + result.timesWrong)) *
                              100 <
                            75
                          ? 'yellow'
                          : 'green'
                    }}
                  >
                    {(
                      (result.timesRight /
                        (result.timesRight + result.timesWrong)) *
                      100
                    ).toFixed(2)}
                    %
                  </p>
                </div>

                <p>{formatTimestamp(result.createdAt)}</p>
              </div>
            ))}
        </div>
      ) : isDemo ? (
        <p>Keine Testergebnisse in der Demo-Version</p>
      ) : (
        <p>Keine Testergebnisse verfügbar</p>
      )}

      <h4>Problemfragen:</h4>
      {wrongQuestions.length > 0 && !isDemo ? (
        <div className='wrongQuestionList'>
          {wrongQuestions
            .sort((a, b) => {
              // Zuerst nach dem Datum sortieren (answeredAt)
              const dateA = a.answeredAt ? a.answeredAt.seconds : 0
              const dateB = b.answeredAt ? b.answeredAt.seconds : 0

              if (dateB !== dateA) {
                return dateB - dateA // Sortiere nach Datum absteigend
              }

              // Wenn die Daten gleich sind, nach timesWrong sortieren
              const timesWrongA = a.timesWrong || 0
              const timesWrongB = b.timesWrong || 0
              return timesWrongB - timesWrongA // Sortiere nach timesWrong absteigend
            })
            .slice(0, 5) // Zeige die ersten 5 Fragen
            .map(question => (
              <div key={question.id} className='wrongQuestion'>
                <div className='wrongQuestionInnerDiv'>
                  <div style={{ display: 'flex' }}>
                    {question.type === 'lap' ? (
                      <p style={{ color: 'white' }}>{question.task}</p>
                    ) : question.type === 'fillInTheBlanks' &&
                      question.blanks ? (
                      question.blanks.map((blank, index) => (
                        <p key={index} style={{ color: 'white' }}>
                          {question.fillInTheBlanksQuestion.split(' ')[blank]}
                        </p>
                      ))
                    ) : (
                      <p style={{ color: 'white' }}>{question.questionText}</p>
                    )}
                  </div>

                  <div>
                    <p style={{ color: 'red' }}> {question.timesWrong}</p>
                  </div>
                </div>

                <div
                  className='wrongQuestionIndicator'
                  style={{
                    backgroundColor: getCategoryColor(question.questionCategory) // Farbe basierend auf Kategorie
                  }}
                >
                  <p>
                    {' '}
                    {question.questionCategory.length < 10 &&
                      question.questionCategory}
                    {question.questionNumber || 0}
                  </p>
                </div>
              </div>
            ))}
        </div>
      ) : isDemo ? (
        <p>Keine Problemfragen in der Demo-Version</p>
      ) : (
        <p>Keine Problemfragen verfügbar</p>
      )}
    </div>
  )
}

export default Stats
