import React, { useState, useEffect } from 'react'
import './style/CatalogDetail.css'
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  arrayUnion,
  getDoc,
  Timestamp
} from 'firebase/firestore'
import ReviewModule from './ReviewModule.js'
import { db } from '../firebaseConfig'
import { useUser } from '../context/UserContext'
import ChatIcon from '@mui/icons-material/Chat'
import { Link } from 'react-router-dom'

const CatalogDetail = ({ catalog }) => {
  const [amount, setAmount] = useState(0)
  const { user, userData } = useUser()
  const [hasReviewed, setHasReviewed] = useState(false)
  const [reviews, setReviews] = useState([])
  const [averageRating, setAverageRating] = useState(0)

  const [randomReviews, setRandomReviews] = useState([]) // State for random reviews

  const [creator, setCreator] = useState('')
  const [reviewActive, setReviewActive] = useState(false)

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const reviewsCollectionRef = collection(
          db,
          'questions',
          catalog.id,
          'reviews'
        )
        const reviewsSnapshot = await getDocs(reviewsCollectionRef)

        // Transformiere die Dokumente in ein Array
        const fetchedReviews = reviewsSnapshot.docs.map(doc => doc.data())
        setReviews(fetchedReviews)

        // Berechne den Durchschnitt, falls es Bewertungen gibt
        if (fetchedReviews.length > 0) {
          const totalRating = fetchedReviews.reduce(
            (acc, review) => acc + review.rating,
            0
          )
          setAverageRating((totalRating / fetchedReviews.length).toFixed(1)) // Runde auf eine Nachkommastelle

          const shuffledReviews = [...fetchedReviews].sort(
            () => 0.5 - Math.random()
          )
          setRandomReviews(shuffledReviews.slice(0, 2))
        } else {
          setAverageRating(0) // Kein Durchschnitt, wenn keine Bewertungen vorliegen
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Bewertungen:', error)
      }
    }

    fetchReviews()
  }, [catalog])

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const userDocRef = doc(db, 'users', catalog.creator)
        const userDocSnap = await getDoc(userDocRef)

        if (userDocSnap.exists()) {
          const userName = userDocSnap.data().username
          setCreator(userName)
        } else {
          console.error('Benutzer nicht gefunden')
          return null
        }
      } catch (error) {
        console.error('Fehler beim Abrufen des Benutzernamens:', error)
        return null
      }
    }

    fetchUserName()
  }, [user, catalog.creator])

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const catalogDoc = doc(db, 'questions', catalog.id)
        const questionsCollection = collection(catalogDoc, 'questions')
        const questionsSnapshot = await getDocs(questionsCollection)
        setAmount(questionsSnapshot.docs.length)
      } catch (e) {
        console.error('Error fetching questions:', e)
      }
    }

    fetchQuestions()
  }, [catalog])

  const completeDemoCatalog = async (userId, catalogId) => {
    const userDocRef = doc(db, 'users', userId)

    // Calculate endDate as 24 hours from now
    const endDate = Timestamp.fromDate(
      new Date(Date.now() + 24 * 60 * 60 * 1000)
    )

    try {
      // Add demo catalog information with endDate 24 hours from now
      await updateDoc(userDocRef, {
        demos: arrayUnion({
          catalogId: catalogId,
          endDate: endDate
        })
      })
    } catch (error) {
      console.error('Error adding demo catalog:', error)
    }
  }

  const handleDemo = async () => {
    try {
      // Check if the catalog is already in the user's catalogs array
      if (userData.catalogs.includes(catalog.id)) {
        alert('Du hast den Katalog bereits erworben.')
        return
      }

      if (userData.demos?.some(demo => demo.catalogId === catalog.id)) {
        alert('Du hast die Demo schonmal gestartet.')
        return
      }

      // Add catalog ID to the user's catalogs array if not already present
      await completeDemoCatalog(user.uid, catalog.id)
      alert(
        'Du hast eine Demo Version gestartet! Diese ist nur für 24h gültig.'
      )
      window.location.reload()
      //reload page
    } catch (error) {
      console.error('Error starting demo:', error)
      alert('Fehler beim Starten der Demo.')
    }
  }

  const hasUserReviewed = async (catalogId, userId) => {
    try {
      const reviewsCollectionRef = collection(
        db,
        'questions',
        catalogId,
        'reviews'
      )
      const reviewsSnapshot = await getDocs(reviewsCollectionRef)

      // Prüfe, ob es eine Bewertung mit der User-ID gibt
      const userReviewExists = reviewsSnapshot.docs.some(
        doc => doc.data().userId === userId
      )

      return userReviewExists // true, wenn der Benutzer bereits bewertet hat
    } catch (error) {
      console.error('Fehler beim Überprüfen der Bewertungen:', error)
      return false // Im Fehlerfall annehmen, dass keine Bewertung existiert
    }
  }

  useEffect(() => {
    const checkUserReview = async () => {
      if (!user || !catalog?.id) return

      try {
        const reviewed = await hasUserReviewed(catalog.id, user.uid)
        setHasReviewed(reviewed)
      } catch (error) {
        console.error('Fehler beim Überprüfen der Bewertung:', error)
      }
    }

    checkUserReview()
  }, [user, catalog])

  const handleClose = () => {
    setReviewActive(false)
  }

  return (
    <div className='catalogDetail' style={{ marginBottom: '100px' }}>
      <p className='priceTag' style={{ borderTopRightRadius: '12px' }}>
        {catalog.price || catalog.price > 0
          ? catalog.price + ' €'
          : 'Kostenlos'}
      </p>
      <p style={{ margin: '0' }}>By: {creator}</p>
      <h2>{catalog.name}</h2>
      <p className='description'>{catalog.description}</p>
      <div className='review'>
        <span>⭐</span> {averageRating} / 5{' '}
        <span style={{ color: 'gray' }}>({reviews.length})</span>
      </div>
      <p>Anzahl der Fragen: {amount}</p>
      {/* Display random reviews */}
      {randomReviews.length > 0 && (
        <div className='randomReviews'>
          <h3>Stimmen aus den Bewertungen:</h3>
          <div className='reviewsContainerRandom'>
            {randomReviews.map(
              (review, index) =>
                review.comment && (
                  <div key={index} className='reviewCardRandom'>
                    <div className='reviewHeaderRandom'>
                      <strong>{review.username}</strong>
                    </div>
                    <p className='reviewCommentRandom'>{review.comment}</p>
                    <div className='reviewRatingRandom'>
                      {Array.from({ length: review.rating }, (_, i) => (
                        <span key={i} className='star'>
                          ⭐
                        </span>
                      ))}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      )}

      <div className='button-group'>
        {!userData.demos?.some(demo => demo.catalogId === catalog.id) &&
        !userData.catalogs?.includes(catalog.id) ? (
          <button onClick={handleDemo} className='demo-button'>
            Demo Version starten!
          </button>
        ) : !hasReviewed ? (
          <button
            className='demo-button'
            style={{ backgroundColor: '#ff7e5f' }}
            onClick={() => setReviewActive(true)}
          >
            Bewerten!
          </button>
        ) : (
          <p>Danke, dass du diesen Katalog bewertet hast!</p> // Fallback-Nachricht
        )}

        {/* Link to open chat page */}

        <Link to={`/chat/${user.uid}-${catalog.id}`} className='chatButton'>
          <ChatIcon />
        </Link>
      </div>
      {reviewActive && <ReviewModule catalog={catalog} onClose={handleClose} />}
    </div>
  )
}

export default CatalogDetail
