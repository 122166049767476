import React, { useState, useEffect } from 'react'
import { collection, updateDoc, doc, addDoc } from 'firebase/firestore'
import { db } from '../firebaseConfig'
import './styles/EditCategories.css'
import { useData } from '../context/DataContext'

const EditCategories = ({ selectedCatalog }) => {
  const { categories } = useData()
  const [sortedCategories, setSortedCategories] = useState([])

  const [newCategoryName, setNewCategoryName] = useState('')
  const [newCategoryColor, setNewCategoryColor] = useState('#feecd6')
  const [editingCategory, setEditingCategory] = useState(null)
  const [editCategoryColor, setEditCategoryColor] = useState('#ffffff')

  // Fetch categories from Firestore
  useEffect(() => {
    // Filter and sort categories for the selected catalog
    const catalogCategories = categories.sort((a, b) =>
      a.name.localeCompare(b.name)
    )

    setSortedCategories(catalogCategories)
  }, [categories, selectedCatalog])

  // Add a new category to Firestore
  const handleAddCategory = async () => {
    if (newCategoryName && newCategoryColor) {
      await addDoc(collection(db, 'questions', selectedCatalog, 'categories'), {
        name: newCategoryName,
        color: newCategoryColor
      })
      setNewCategoryName('')
      setNewCategoryColor('#ffffff')
      window.location.assign('/questions')
    }
  }

  // Update only the color of an existing category in Firestore
  const handleEditCategory = async categoryId => {
    if (editCategoryColor) {
      const categoryDoc = doc(
        db,
        'questions',
        selectedCatalog,
        'categories',
        categoryId
      )
      await updateDoc(categoryDoc, {
        color: editCategoryColor
      })
      setEditingCategory(null)
      setEditCategoryColor('#ffffff')
      window.location.reload() // Reload page to show updated categories
    }
  }

  // Set category for editing (color only)
  const handleEditClick = category => {
    setEditingCategory(category.id)
    setEditCategoryColor(category.color)
  }

  return (
    <div className='editCategories'>
      {/* Add new category form */}
      <div className='addCategory'>
        <h3>Neue Kategorie hinzufügen</h3>
        <input
          type='text'
          placeholder='Kategorie Name'
          value={newCategoryName}
          onChange={e => setNewCategoryName(e.target.value)}
        />
        <input
          type='color'
          value={newCategoryColor}
          onChange={e => setNewCategoryColor(e.target.value)}
        />
        <button onClick={handleAddCategory}>Kategorie hinzufügen</button>
      </div>

      {/* List of categories */}
      <div className='categoryList'>
        <h3>Existierende Kategorien</h3>
        {sortedCategories.map(category => (
          <div
            key={category.id}
            className='categoryItem'
            style={{ backgroundColor: category.color }}
          >
            {editingCategory === category.id ? (
              <>
                <p>Name: {category.name}</p>{' '}
                {/* Display name, but do not allow editing */}
                <input
                  placeholder=''
                  type='color'
                  value={editCategoryColor}
                  onChange={e => setEditCategoryColor(e.target.value)}
                />
                <button onClick={() => handleEditCategory(category.id)}>
                  Speichern
                </button>
              </>
            ) : (
              <>
                <p>Name: {category.name}</p>
                <p>Farbe: {category.color}</p>
                <button onClick={() => handleEditClick(category)}>
                  Bearbeiten
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default EditCategories
