import React from 'react'
import './style/Loading.css'

const Loading = () => {
  return (
    <div className='loading-container'>
      <div className='spinner'></div>
      <p>Loading...</p>
    </div>
  )
}

export default Loading
