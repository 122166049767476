import React, { useEffect, useState } from 'react'
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  arrayUnion
} from 'firebase/firestore'
import { db } from '../firebaseConfig'
import { useUser } from '../context/UserContext'
import './style/CreateCatalog.css'

const CreateCatalog = () => {
  const { user, userData } = useUser()
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [userName, setUserName] = useState('')
  const [price, setPrice] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!userData.username) return
    setUserName(userData.username)
  }, [userData])

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const catalogRef = collection(db, 'questions')
      const userRef = doc(db, 'users', user.uid)

      // Katalog erstellen und die ID abrufen
      const catalogDoc = await addDoc(catalogRef, {
        name,
        description,
        creator: user.uid,
        createdAt: new Date(),
        admin: [user.uid],
        public: false,
        price: Number(price)
      })
      const catalogId = catalogDoc.id

      // Katalog-ID zum Benutzer-Dokument hinzufügen
      await updateDoc(userRef, {
        catalogs: arrayUnion(catalogId) // Katalog-ID zum Array hinzufügen
      })

      alert('Katalog erfolgreich erstellt!')
      setName('')
      setDescription('')

      window.location.reload()
    } catch (error) {
      console.error('Error creating catalog:', error)
      alert('Fehler beim Erstellen des Katalogs.')
    }
    setLoading(false)
  }

  return (
    <div className='createCatalog'>
      <h2>Neuen Katalog erstellen</h2>
      <form onSubmit={handleSubmit} className='createCatalogForm'>
        <div className='form-group'>
          <label htmlFor='name'>Katalogname:</label>
          <input
            type='text'
            id='name'
            value={name}
            onChange={e => setName(e.target.value)}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='description'>Beschreibung:</label>
          <textarea
            id='description'
            value={description}
            onChange={e => setDescription(e.target.value)}
            required
          />
        </div>
        <div className='form-group'>
          <label htmlFor='creator'>Ersteller:</label>
          <input type='text' id='creator' value={userName} required disabled />
        </div>
        <div className='form-group'>
          <label htmlFor='price'>Preis:</label>
          <input
            type='number'
            id='price'
            value={price}
            onChange={e => setPrice(e.target.value)}
          />
        </div>
        <button type='submit' disabled={loading}>
          {loading ? 'Erstellen...' : 'Katalog erstellen'}
        </button>
        <button
          type='button'
          onClick={() => window.location.reload()}
          className='cancelButton'
        >
          Abbrechen
        </button>
      </form>
    </div>
  )
}

export default CreateCatalog
