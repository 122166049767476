import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

const Dnd = ({ question, onSubmit, onDelete }) => {
  const [questionText, setQuestionText] = useState(question.question)
  const [blocks, setBlocks] = useState(question.block)

  const handleQuestionChange = e => {
    setQuestionText(e.target.value)
  }

  const handleBlockChange = (index, value) => {
    const newBlocks = [...blocks]
    newBlocks[index] = value
    setBlocks(newBlocks)
  }

  const handleSubmit = e => {
    e.preventDefault()
    // Das ursprüngliche question-Objekt mit den aktualisierten Werten zusammenbauen
    const updatedQuestion = {
      ...question,
      question: questionText,
      block: blocks // Hier wird das aktualisierte block-Array übergeben
    }

    // Die übergebene onSubmit-Funktion mit dem aktualisierten Objekt aufrufen
    if (onSubmit) {
      onSubmit(updatedQuestion)
    }
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(question.id)
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <input
          type='text'
          value={questionText}
          onChange={handleQuestionChange}
          placeholder='Enter the question'
          style={{
            marginBottom: '20px',
            backgroundColor: '#333',
            color: 'white'
          }}
        />

        {blocks.map((block, index) => (
          <div key={index}>
            <input
              type='text'
              value={block}
              onChange={e => handleBlockChange(index, e.target.value)}
              placeholder={`Block ${index + 1}`}
            />
          </div>
        ))}

        <input
          className='editQuestionKomponentSubmitButton'
          type='submit'
          value='Änderungen speichern'
        />
      </form>
      <button
        style={{ marginTop: '6px', width: '100%' }}
        className='deleteButtonEditQuestions'
        onClick={handleDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  )
}

export default Dnd
