import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

const Multiple = ({ question, onSubmit, onDelete }) => {
  const [questionText, setQuestionText] = useState(question.question)
  const [answers, setAnswers] = useState(question.answers)
  const [correctAnswersIndexes, setCorrectAnswersIndexes] = useState(
    question.correctAnswer || [] // Array mit den korrekten Antwort-Indizes
  )

  const handleQuestionChange = e => {
    setQuestionText(e.target.value)
  }

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers]
    newAnswers[index] = value
    setAnswers(newAnswers)
  }

  const handleCorrectAnswerChange = index => {
    if (correctAnswersIndexes.includes(index)) {
      // Index entfernen, wenn er bereits ausgewählt ist
      setCorrectAnswersIndexes(correctAnswersIndexes.filter(i => i !== index))
    } else {
      // Index hinzufügen, wenn er noch nicht ausgewählt ist
      setCorrectAnswersIndexes([...correctAnswersIndexes, index])
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    // Das ursprüngliche question-Objekt mit den aktualisierten Werten zusammenbauen
    const updatedQuestion = {
      ...question,
      question: questionText,
      answers: answers,
      correctAnswer: correctAnswersIndexes // Aktualisierter Array mit den korrekten Indizes
    }

    // Die übergebene onSubmit-Funktion mit dem aktualisierten Objekt aufrufen
    if (onSubmit) {
      onSubmit(updatedQuestion)
    }
  }

  const handleDelete = () => {
    if (onDelete) {
      onDelete(question.id)
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <form onSubmit={handleSubmit}>
        <input
          style={{
            marginBottom: '20px',
            backgroundColor: '#333',
            color: 'white'
          }}
          type='text'
          value={questionText}
          onChange={handleQuestionChange}
          placeholder='Enter the question'
        />

        {answers.map((answer, index) => (
          <div key={index}>
            <input
              type='text'
              value={answer}
              onChange={e => handleAnswerChange(index, e.target.value)}
              placeholder={`Answer ${index + 1}`}
            />
            <input
              style={{
                margin: '10px'
              }}
              type='checkbox' // Checkbox für Mehrfachauswahl
              name='correctAnswer'
              value={index}
              checked={correctAnswersIndexes.includes(index)} // Überprüfen, ob der Index in den korrekten Antworten enthalten ist
              onChange={() => handleCorrectAnswerChange(index)}
            />{' '}
          </div>
        ))}

        <input
          className='editQuestionKomponentSubmitButton'
          type='submit'
          value='Änderungen speichern'
        />
      </form>
      <button
        style={{ marginTop: '6px', width: '100%' }}
        className='deleteButtonEditQuestions'
        onClick={handleDelete}
      >
        <DeleteIcon />
      </button>
    </div>
  )
}

export default Multiple
